import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const mobileDeviceSimComponentFieldMap = () => ({
  mobile_device_sim_component: {
    canCreate: true,
    title: __mtc('mobile_device_sim_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'imeiNumber',
        name: `${__mtc('imei')} ${__mtc('number')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'iCCID',
        name: __mtc('iccid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'phoneNumber',
        name: `${__mtc('phone_number')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'currentCarrierNetwork',
        name: __mtc('current_carrier_network'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'carrierSettingVersion',
        name: __mtc('carrier_setting_version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'currentMCC',
        name: __mtc('current_mcc'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'currentMNC',
        name: __mtc('current_mnc'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'subscriberMCC',
        name: __mtc('subscriber_mcc'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'subscriberMNC',
        name: __mtc('subscriber_mnc'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'roamingEnabled',
        name: __mtc('roaming_enabled'),
        inputType: 'boolean',
      },
    ],
  },
})
