import api from '@api'
import {
  transformBarcode,
  transformBarcodeForServer,
  transformBarcodeConfigurationForServer,
  transformBarcodeConfiguration,
} from '@data/barcode'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}
export function getBarcodesApi(filter, limit, offset, sortedColumn) {
  return api
    .post(
      `/barcodeprefixhistory/search/byqual`,
      {
        qualDetails: buildQualifications(filter),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformBarcode),
        total: data.totalCount,
      }
    })
}
export function downloadReprintApi(data) {
  return api
    .post(`/reprint/barcode/${data.id}`, transformBarcodeForServer(data), {
      message: __rootT('downloaded_success', {
        resource: __rootTc('reprint_barcode'),
      }),
    })
    .then(transformBarcode)
}

export function getBarcodeConfigurationApi() {
  return api.get('/barcodeformatconfig')
}
export function getBarcodeDetailsByAssetIdsApi(moduleName, assetIdSet) {
  return api.post(`/${moduleName}/barcodeDetailsByAssetIds`, assetIdSet)
}
export function createBarcodeConfigurationApi(data) {
  return api
    .patch(
      `/barcodeformatconfig`,
      transformBarcodeConfigurationForServer(data),
      {
        message: __rootT('added_successfully', {
          resource: `${__rootTc('barcode')} ${__rootTc('format')}`,
        }),
      }
    )
    .then(transformBarcodeConfiguration)
}
