<template>
  <MRow :gutter="0" class="bg-neutral-lightest px-2 py-2 rounded mb-2">
    <MCol :size="6" class="pr-1">
      <FlotoFormItem
        :label="$tc('company')"
        :placeholder="$tc('company')"
        rules="required|nonzero"
        auto-focus
      >
        <CompanyPicker
          v-model="item.companyId"
          as-input
          :options="availableCompanyOptions"
        />
      </FlotoFormItem>
    </MCol>
    <MCol :size="6" class="pl-1">
      <div class="flex items-center">
        <FlotoFormItem
          id="content"
          v-model="prefixValueComputed"
          vid="prefix"
          :label="`${$tc('category_prefix')}`"
          class="category-prefix-style block"
          :disabled="disabled"
          :rules="companyCategoryPrefixValidationRules"
        >
          <template v-slot:before-input>
            <KeywordSelector
              ref="keywordPopup"
              :available-keywords="availableKeywords"
              :module-name="moduleName"
              placement="bottomRight"
              @selected="insertKeywordForCompanyCategoryPrefix"
              @hide="companyPrefixKeywordSelectorHide"
            >
              <a class="keyword-trigger">
                <small> {{ $t('insert') }} {{ $tc('placeholder') }} </small>
              </a>
            </KeywordSelector>
          </template>
          <template v-slot:after-input>
            <span class="my-4">
              <b> {{ `${$tc('preview')}: ` }} </b>
              <span style="text-transform: uppercase">
                {{ resolvePrefixKeywords(prefixValueComputed) }}
              </span>
            </span>
          </template>
        </FlotoFormItem>
        <div class="ml-2 mt-2">
          <a @click.prevent="remove">
            <MTooltip>
              <template v-slot:trigger>
                <MIcon name="trash-alt" class="text-secondary-red" />
              </template>
              {{ $t('delete') }}
            </MTooltip>
          </a>
        </div>
      </div>
    </MCol>
  </MRow>
</template>
<script>
import Moment from 'moment'
import CompanyPicker from '@components/data-picker/company-picker'
import KeywordSelector from '@components/email-keywords/keyword-selector'

export default {
  name: 'CompanyWiseCategoryMappingItem',
  components: { CompanyPicker, KeywordSelector },
  model: {
    event: 'change',
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    availableKeywords: { type: Object, default: undefined },
    moduleName: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    remove: { type: Function, default: undefined },
    update: { type: Function, default: undefined },
    availableCompanyOptions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      companyPrefixCount: 0,
    }
  },
  computed: {
    prefixValueComputed: {
      get() {
        return (this.item || {}).prefixValue
      },
      set(prefixValue) {
        this.update({ ...this.item, prefixValue })
      },
    },
    companyCategoryPrefixValidationRules() {
      const rules = {
        string_without_space: true,
        required: true,
      }
      if (this.companyPrefixCount < 2) {
        rules.min_value = 2
      }
      if (this.companyPrefixCount > 24) {
        rules.max_value = 24
      }
      return rules
    },
  },
  methods: {
    insertKeywordForCompanyCategoryPrefix(keyword) {
      let input = this.$el.querySelector('input#content')
      if (input) {
        const currentSelection = input.selectionStart
        const requestBody = `${(input.value || '').substr(
          0,
          currentSelection
        )}#$#${keyword.keyword}#$#${(input.value || '').substr(
          currentSelection
        )}`
        this.prefixValueComputed = requestBody.split('#$#').join('')
        this.$nextTick(() => {
          input.focus()
          input.selectionStart = currentSelection + keyword.keyword.length + 2
          input = null
        })
      }
    },
    resolvePrefixKeywords(categoryPrefix) {
      categoryPrefix = (categoryPrefix || '').toUpperCase()
      if (categoryPrefix || ''.length > 0) {
        const keywordRegex = /({#).*?(#})/g
        const extractFormat = /(?<={#)(.*?)(?=#})/g
        let addedKeywords = categoryPrefix.match(keywordRegex) || []
        let resolvedKeywords = []
        addedKeywords.forEach((keyword) => {
          let currentKeyword = keyword.match(extractFormat)
          if (currentKeyword[0]) {
            resolvedKeywords.push(Moment().format(currentKeyword[0]))
          } else {
            resolvedKeywords.push('')
          }
        })
        let previewText = categoryPrefix
        var i
        for (i = 0; i < addedKeywords.length; i++) {
          let newRegex = new RegExp(addedKeywords[i], 'g')
          previewText = previewText.replace(newRegex, resolvedKeywords[i])
        }
        let correction = []
        correction = addedKeywords.filter(
          (value) => value === '{#M#}' || value === '{#D#}'
        )
        this.companyPrefixCount = previewText.length + correction.length
        return previewText
      }
    },
    companyPrefixKeywordSelectorHide() {
      let input = this.$el.querySelector('input#content')
      input.focus()
    },
  },
}
</script>
