<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
import { PageMethods } from '@src/state/modules/page'
export default {
  name: 'DashboardModule',
  page() {
    return {
      title: this.$tc('dashboard'),
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        to.name === 'dashboard.edit' &&
        (vm.availableModulesInLicense || []).indexOf('customDashboard') === -1
      ) {
        vm.$router.replace({ name: '404' })
        return
      }
      vm.hasPermission = true
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.dashboardSetPageData) {
      this.dashboardSetPageData({})
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...LicenseComputed,
  },
  methods: {
    ...PageMethods,
  },
}
</script>
