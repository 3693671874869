<template>
  <AdminCardListContainer :is-page="false">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </AdminCardListContainer>
</template>

<script>
import AdminCardListContainer from './admin-card-list-container.vue'
export default {
  name: 'AdminList',
  components: { AdminCardListContainer },
}
</script>
