import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      titleFn(route, translator) {
        if (route.path.indexOf('custom-script') >= 0) {
          return `${translator('automation')}`
        }
        return `${translator(route.params.moduleName)} ${translator(
          'management'
        )}`
      },
      nonLinked: true,
    },
    children: [
      {
        path: 'custom-script',
        name: `${routeNamePrefix}.custom-script`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "form-rules" */ './views/custom-script')
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${pluralTranslator('custom_script')}`
          },
        },
      },
      {
        path: ':moduleName',
        name: `${routeNamePrefix}`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "form-rules" */ './views/list')
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${translator(route.params.moduleName)} ${pluralTranslator(
              'form_rule'
            )}`
          },
        },
      },
    ],
  },
]
