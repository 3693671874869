import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'
const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const databasePropertyGroupFieldMap = () => ({
  database_property_group: {
    title: __mtc('database_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'instanceId',
        name: `${__mtc('instance_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'instanceType',
        name: `${__mtc('instance_type')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'engineName',
        name: `${__mtc('engine_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'engineVersion',
        name: `${__mtc('engine_version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'instanceStatus',
        name: `${__mtc('instance_status')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'allocatedStorage',
        name: `${__mtc('db_storage')}`,
        inputType: 'bytes',
        unit: 'mb',
      },
      {
        key: generateId(),
        paramName: 'storageType',
        name: `${__mtc('storage_type')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'earliestRestoreDate',
        name: `${__mtc('earliest_restore_date')}`,
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'instanceCreatedTime',
        name: `${__mtc('instance_created_time')}`,
        inputType: 'dateTime',
      },
    ],
  },
})
