<template>
  <MRow :gutter="0">
    <MCol :lg="12" :sm="12" auto-size class="px-2">
      <MRow>
        <MCol :size="12">
          <FlotoFormItem
            id="display-name-input"
            v-model="formData.displayName"
            :placeholder="$t('name')"
            :label="$t('name')"
            rules="required|short_text"
          />
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="6">
          <FlotoFormItem
            id="product-typr-picker"
            :label="$tc('product_type')"
            rules="required|nonzero"
          >
            <ProductTypePicker
              v-model="formData.productType"
              :validate-archived-value="true"
              as-input
            />
          </FlotoFormItem>
        </MCol>
        <MCol :size="6">
          <FlotoFormItem id="manufacturer-picker" :label="__tc('manufacturer')">
            <ManufacturerPicker v-model="formData.manufacturer" as-input />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <FlotoFormItem
        id="description-input"
        v-model="formData.description"
        :placeholder="$t('description')"
        :label="$t('description')"
        :rows="5"
        type="textarea"
      />
      <div class="-mx-2">
        <FormConsumer
          v-if="customFields.length"
          v-model="formData.fieldValueDetails"
          :module-name="$constants.PRODUCT"
          :form-fields="customFields"
          :avoid-default-value="Boolean(formData.id)"
          :with-submit="false"
        />
      </div>
    </MCol>
  </MRow>
</template>

<script>
import FormConsumer from '@components/form-consumer.vue'
import ProductTypePicker from '@components/data-picker/product-type-picker'
import ManufacturerPicker from '@components/data-picker/manufacturer-picker'
import { FormComputed } from '@state/modules/form'
export default {
  name: 'ProductCatalogForm',
  components: { ProductTypePicker, ManufacturerPicker, FormConsumer },
  props: {
    formData: { type: Object, required: true },
  },
  computed: {
    ...FormComputed,
    customFields() {
      return (this.productForm || {}).fields || []
    },
  },
}
</script>
