import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.risk-type` },
    meta: { moduleName, breadcrumbKey: 'change_management', nonLinked: true },
    children: [
      {
        path: 'risk-type',
        name: `${routeNamePrefix}.risk-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/risk-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('change_risk')}`
          },
        },
      },
      {
        path: 'change-type',
        name: `${routeNamePrefix}.change-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/change-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('change_type')}`
          },
        },
      },
      {
        path: 'reason-type',
        name: `${routeNamePrefix}.reason-type`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "admin" */ './views/reason-type-list')
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('change_reason')}`
          },
        },
      },
      {
        path: 'target-environment',
        name: `${routeNamePrefix}.target-environment`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "admin" */ './views/target-environment-list'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('target_environment')}`
          },
        },
      },
    ],
  },
]
