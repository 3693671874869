var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoFormItem',{attrs:{"label":_vm.label}},[_c('div',{staticClass:"flex flex-col flex-1"},[_c('MultipleFormItems',{attrs:{"button-size":"small","show-icon":false,"disabled":_vm.disabled,"add-btn-text":("" + _vm.label),"max-items":_vm.allowedCompanyIds.length
          ? _vm.allowedCompanyIds.length
          : _vm.companyOptions.length},scopedSlots:_vm._u([{key:"add-item",fn:function(ref){
          var add = ref.add;
return [_c('div',{staticClass:"mt-2"},[_c('MButton',{attrs:{"variant":"primary","rounded":""},on:{"click":add}},[_c('MIcon',{staticClass:"mx-1",attrs:{"name":"plus-circle"}}),_vm._v(" "+_vm._s(("" + _vm.label))+" ")],1)],1)]}},{key:"default",fn:function(ref){
          var item = ref.item;
          var remove = ref.remove;
          var update = ref.update;
return [_c('CompanyWiseCategoryMappingItem',_vm._b({attrs:{"item":item,"remove":remove,"update":update,"available-keywords":_vm.availableCompanyCategoryKeywords,"module-name":_vm.moduleName,"available-company-options":_vm.getUpdatedCompanyOptions}},'CompanyWiseCategoryMappingItem',_vm.$attrs,false))]}}]),model:{value:(_vm.companyWisePrefixMapping),callback:function ($$v) {_vm.companyWisePrefixMapping=$$v},expression:"companyWisePrefixMapping"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }