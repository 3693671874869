import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const nonitPropertyFieldMap = () => ({
  non_it_property_group: {
    title: __mtc('non_it_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'warrentyExpirationDate',
        name: __mtc('warrenty_expriration_date'),
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'auditDate',
        name: __mtc('audit_date'),
        inputType: 'date',
      },
    ],
  },
})
