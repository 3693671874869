<template>
  <div class="flex flex-col flex-1">
    <AdminPage v-if="isPage" :show-divider="false">
      <template v-slot:breadcrumb>
        <FlotoFormItem
          ref="searchBox"
          v-model="searchTerm"
          class="w-1/2 h-8"
          type="text"
          :auto-focus="false"
          :placeholder="$t('search')"
        >
          <template v-slot:prefix>
            <MIcon name="search" />
          </template>
        </FlotoFormItem>
      </template>
      <MRow :gutter="0" class="flex-1 px-2 pt-2 bg-neutral-lightest">
        <MCol class="overflow-hidden">
          <TransitionGroup tag="MRow" :gutter="0" name="slideBottom">
            <MCol
              v-for="m in filteredModules"
              :key="m.key"
              class="flex flex-col"
              :size="m.colSize || 3"
              style="padding-right: 0; padding-left: 0"
            >
              <ModuleCard :module-item="m" :highlight-term="searchTerm" />
            </MCol>
          </TransitionGroup>
        </MCol>
      </MRow>
    </AdminPage>
    <FlotoScrollDropdown
      v-else
      id="moudle-card-dropdown"
      ref="dropdownRef"
      :options="allAvailableModules"
      avoid-keyboard-navigation
    >
      <template v-slot:trigger="triggerSlotData">
        <div class="flex cursor-pointer">
          <slot name="trigger" v-bind="triggerSlotData"></slot>
        </div>
      </template>
      <template v-slot:before-menu>
        <div class="p-2">
          <MInput
            id="search"
            v-model="searchTerm"
            class="w-full"
            auto-focus
            :placeholder="$t('search')"
          >
            <template v-slot:prefix>
              <MIcon name="search" />
            </template>
          </MInput>
        </div>
      </template>
      <MRow :gutter="0" class="flex-1 px-2 pt-2 bg-neutral-lightest">
        <MCol class="overflow-hidden" style="width: 350px">
          <TransitionGroup tag="MRow" :gutter="0" name="slideBottom">
            <MCol
              v-for="m in filteredModules"
              :key="m.key"
              class="flex flex-col"
              :size="12"
              style="padding-right: 0; padding-left: 0"
            >
              <ModuleCard
                :module-item="m"
                :highlight-term="searchTerm"
                :use-split-item="false"
              />
            </MCol>
          </TransitionGroup>
        </MCol>
      </MRow>
    </FlotoScrollDropdown>
  </div>
</template>

<script>
import { searchList } from '@utils/arr'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
import { OrganizationComputed } from '@state/modules/organization'
import { moduleList } from '../admin-module-map'
import ModuleCard from './module-card'
export default {
  name: 'AdminCardListContainer',
  components: { ModuleCard },
  props: {
    // eslint-disable-next-line
    isPage: { type: Boolean, default: true },
  },
  data() {
    return {
      allAvailableModules: [],
      modules: [],
      searchTerm: '',
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...OrganizationComputed,
    filteredModules() {
      if (this.searchTerm) {
        // @TODO apply filter to modules
        const allItems = this.allAvailableModules.reduce(
          (result, moduleItem) => result.concat(moduleItem.items || []),
          []
        )
        const filteredTitles = searchList(
          allItems,
          this.searchTerm.trim(),
          ['title', 'keywords'] // 'searchableKey'
        ).map(({ title }) => title)
        return this.allAvailableModules.filter((moduleItem) => {
          return (
            (moduleItem.items || []).filter(
              ({ title }) => filteredTitles.indexOf(title) >= 0
            ).length > 0
          )
        })
      }
      return this.allAvailableModules
    },
  },
  created() {
    let allAvailableModules =
      moduleList &&
      moduleList().filter((m) => {
        if (m.isModuleAvailable) {
          return m.isModuleAvailable(this.availableModulesInLicense)
        }
        if (m.multiTenantEnabled) {
          return this.multiTenantEnabled
        }
        if (m.mspEnabled) {
          return this.mspEnabled
        }
        if (m.aiEnabled) {
          return this.availableModulesInLicense.indexOf(
            this.$constants.REQUEST
          ) >= 0
            ? this.aiEnabled
            : false
        }
        const availableItems = m.items.filter((item) => {
          if (item.isModuleAvailable) {
            return item.isModuleAvailable(this.availableModulesInLicense)
          }
          return true
        })
        if (availableItems.length) {
          return true
        }
        return false
      })
    allAvailableModules = allAvailableModules.filter((m) => {
      if (m.isModuleEnabledForUser) {
        return m.isModuleEnabledForUser(this.myAllowedModules)
      }
      return true
    })
    this.allAvailableModules = allAvailableModules
    setTimeout(() => {
      this.$refs.searchBox && this.$refs.searchBox.focus()
    }, 100)
  },
}
</script>

<style lang="scss" scoped></style>
