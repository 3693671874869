import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const motherBoardComponentFieldMap = () => ({
  motherboard_component: {
    canCreate: true,
    maxCreation: 1,
    title: __mtc('motherboard_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'version',
        name: `${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'installDate',
        name: __mtc('install_date'),
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'partNumber',
        name: __mtc('part_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'primaryBusType',
        name: __mtc('primary_bus_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'secondaryBusType',
        name: __mtc('secondary_bus_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
    ],
  },
})
