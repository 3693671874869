<template>
  <BulkActionMenu :actions="actions">
    <template v-slot:installPatch="{ hide }">
      <DeployAction
        :agent-ids="selectedItemIds"
        :title="`${$tc('install')} ${$tc('patch')}`"
        v-bind="$attrs"
        @trigger="hide"
      />
    </template>
    <template v-slot:uninstallPatch="{ hide }">
      <DeployAction
        :title="`${$tc('uninstall')} ${$tc('patch')}`"
        action="uninstall"
        :agent-ids="selectedItemIds"
        v-bind="$attrs"
        @trigger="hide"
      />
    </template>
    <template v-slot:manualPatch="{ hide }">
      <Archive
        :title="`${$tc('manual')} ${$tc('patch')}`"
        action="manual"
        :agent-ids="selectedItemIds"
        v-bind="$attrs"
        :module-name="moduleName"
        @done="handleBulkDelete"
        @trigger="hide"
      />
    </template>
  </BulkActionMenu>
</template>

<script>
import BulkActionMenu from '@components/bulk-action-menu'
import DeployAction from '@modules/patch/components/action-menu-items/deploy-action'
import Archive from '@modules/patch/components/action-menu-items/archive'
export default {
  name: 'BulkActions',
  components: {
    BulkActionMenu,
    DeployAction,
    Archive,
  },
  props: {
    // type: { type: String, required: true },
    mode: { type: String, default: undefined },
    selectedItemIds: { type: Array, required: true },
    toggleSelectAll: { type: Function, required: true },
    refreshList: { type: Function, required: true },
    moduleName: { type: String, required: true },
    allowManualPatchAction: { type: Boolean, default: false },
  },
  data() {
    let actions = []
    if (this.mode === 'missing') {
      actions = ['installPatch']
    }
    if (this.mode === 'installed') {
      actions = ['uninstallPatch']
    }
    if (this.allowManualPatchAction) {
      actions = [...actions, 'manualPatch']
    }
    this.actions = actions
    return {}
  },
  methods: {
    unselectAll() {
      this.toggleSelectAll()
    },
    handleBulkDelete() {
      this.$emit('delete', this.selectedItemIds)
    },
  },
}
</script>
