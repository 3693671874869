<template>
  <div>
    <FieldContainer
      :field="field"
      :section-id="sectionId"
      v-bind="containerAttrs"
      v-on="containerListeners"
    >
      <div>
        abc
      </div>
    </FieldContainer>
  </div>
</template>

<script>
import FormField from '../../mixins/form-field'
export default {
  name: 'Spacer',
  mixins: [FormField],
}
</script>
