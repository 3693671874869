<template>
  <MPopover
    destroy-tooltip-on-hide
    placement="bottomRight"
    :overlay-style="{ width: 'auto' }"
    @show="handleOpen"
  >
    <template v-slot:trigger>
      <MButton
        variant="neutral-lighter"
        class="text-neutral mr-2"
        :shadow="false"
        shape="circle"
      >
        <a>
          <MIcon name="qrcode" size="md" />
        </a>
      </MButton>
    </template>
    <div v-if="asset.barCode">
      <h6>{{ $tc('barcode') }} {{ ': ' }}</h6>
      <BarcodeRenderer
        :key="asset.barCode"
        class="items-center"
        :barcode="asset.barCode"
        hide-updatable-options
        :module-name="moduleName"
        :asset="asset"
      />
    </div>
    <div v-if="tenantPrefrences.qrCodeSupport">
      <h6 class="my-3">{{ $tc('qrcode') }} {{ ': ' }}</h6>
      <QrCode class="mt-3" :asset-id="assetId" :module-name="moduleName" />
    </div>
    <div v-if="!asset.barCode && !tenantPrefrences.qrCodeSupport">
      <FlotoNoData size="small" />
    </div>
  </MPopover>
</template>

<script>
import BarcodeRenderer from './barcode/barcode-renderer'
import QrCode from './qr-code'
import { PreferenceComputed } from '@state/modules/preference'

export default {
  name: 'BarcodeQrcodeRenderer',
  components: {
    BarcodeRenderer,
    QrCode,
  },
  props: {
    asset: { type: Object, required: true },
    moduleName: { type: String, required: true },
  },
  computed: {
    ...PreferenceComputed,
    assetId() {
      const id = Number(this.asset.id.split(':')[0])
      return id
    },
  },
  methods: {
    handleOpen() {},
  },
}
</script>
