var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[_c('FlotoFormItem',{attrs:{"rules":{
      required: _vm.field.required ? true : false,
      nonzero: _vm.field.required ? true : false,
      // eslint-disable-next-line
      max_value: _vm.field.maxValue ? _vm.field.maxValue : false,
      // eslint-disable-next-line
      min_value: _vm.field.minValue ? _vm.field.minValue : false,
    },"label":_vm.label}},[_c('SliderPicker',{attrs:{"disabled":_vm.disabled || _vm.mode === 'builder' || _vm.field.attributes.systemManagedField,"min":_vm.field.minValue,"max":_vm.field.maxValue,"dots":""},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }