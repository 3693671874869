<template>
  <FlotoBorderedActions
    :class="{
      'sortable-chosen': (currentEditingItem || {}).guid === field.guid,
      'field-border-action':
        !(
          (currentHoverActiveItemId === field.guid || isHovering) &&
          !isDragging
        ) && mode === 'builder',
      'cursor-move': mode === 'builder',
    }"
    :active="
      (currentHoverActiveItemId === field.guid || isHovering) && !isDragging
    "
    :disabled="mode !== 'builder' || hideAction"
    :data-field-type="field.isSystemField ? 'system' : field.inputType"
    :data-field-mandatory="field.required"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    @click="mode === 'builder' && $emit('field-edit', field)"
  >
    <div
      v-if="
        mode === 'builder' &&
        [$constants.ASSET, $constants.CMDB].indexOf(field.moduleName) >= 0 &&
        field.assetCiTypeName
      "
      class="field-asset-type-name-info bg-neutral-lightest px-2 font-thin"
    >
      {{ field.assetCiTypeName }}
    </div>
    <template v-if="mode === 'builder'" v-slot:actions>
      <!-- <div class="inline-flex items-center justify-center cursor-pointer mr-2">
        <a href="javascript:;" @click="collapseField">
          <MIcon name="chevron-left" />
        </a>
        <span class="mx-2 relative" style="top: 1px">{{ sizeText }}</span>
        <a href="javascript:;" @click="expandField">
          <MIcon name="chevron-right" />
        </a>
      </div> -->
      <ActionButtons
        :blocked-btns="blockedButtons"
        :field-width-text="fieldWidthText"
        :allow-split-field="allowSplitField"
        @remove="$emit('field-remove', field)"
        @duplicate="$emit('duplicate', field)"
        @remove-trigger="$emit('field-remove-trigger')"
        @edit="$emit('field-edit', field)"
        @clear="$emit('field-hover-clear')"
        @collapse="collapseField"
        @expand="expandField"
      />
    </template>
    <slot />
  </FlotoBorderedActions>
</template>

<script>
import ActionButtons from '../action-buttons/buttons'
import { AssetTypeComputed } from '@src/state/modules/asset-type'
import { CITypeComputed } from '@src/state/modules/ci-type'
// import { customFieldAttributesTypePrefix } from '@src/modules/asset/helpers/asset-custom-fields'
// import { flattenRecursive } from '@data/recursive'

export default {
  name: 'FieldContainer',
  components: { ActionButtons },
  inheritAttrs: false,
  props: {
    mode: { type: String, required: true },
    currentEditingItem: { type: Object, default: undefined },
    currentHoverActiveItemId: { type: String, default: undefined },
    isDragging: { type: Boolean, default: false },
    field: { type: Object, required: true },
    sectionId: { type: String, required: false, default: undefined },
    // eslint-disable-next-line
    allowSplitField: { type: Boolean, default: true },
    hideAction: { type: Boolean, default: false },
  },
  data() {
    return { isHovering: false }
  },
  computed: {
    ...AssetTypeComputed,
    ...CITypeComputed,
    inputWidth() {
      if (this.field.attributes.inputWidth) {
        return `${this.field.attributes.inputWidth}%`
      }
      return '100%'
    },
    labelAttrs() {
      const attrs = {}
      if (this.field.required) {
        attrs.class = 'form-item-required'
      }
      return attrs
    },
    blockedButtons() {
      const btns = []
      if (
        this.field.canDelete !== true ||
        (this.$route.params.moduleName === this.$constants.PURCHASE &&
          this.field.attributes.costField === 'true')
      ) {
        btns.push('delete')
      }
      if (this.field.canUpdate !== true) {
        btns.push('edit')
      }
      if (this.field.isSystemField) {
        btns.push('duplicate')
      }
      return btns
    },
    sizeText() {
      let widthClass = (this.field.attributes || {}).widthClass
      if (widthClass) {
        const size = widthClass.split('-')[1]
        if (size === 'full') {
          return '2/2'
        }
        return '1/2'
      }
      return '1/2'
    },
    fieldWidthText() {
      let widthClass = (this.field.attributes || {}).widthClass
      if (widthClass) {
        const size = widthClass.split('-')[1]
        if (size === 'full') {
          return 'full'
        }
        return 'half'
      }
      return 'half'
    },
    // assetCiTypeName() {
    //   if (
    //     [this.$constants.ASSET, this.$constants.CMDB].indexOf(this.moduleName)
    //   ) {
    //     const typePrefix = customFieldAttributesTypePrefix(this.moduleName)
    //     const flattenedOptions = flattenRecursive(this[`${typePrefix}Types`])
    //     return (
    //       flattenedOptions.find((t) => t.id === +this.field.assetCiTypeId) || {}
    //     ).name
    //   }
    //   return undefined
    // },
  },
  methods: {
    expandField() {
      const field = {
        ...this.field,
        attributes: {
          ...this.field.attributes,
          widthClass: 'w-full',
        },
      }
      delete field.tree
      this.$emit('field-change', field)
    },
    collapseField() {
      const field = {
        ...this.field,
        attributes: {
          ...this.field.attributes,
          widthClass: 'w-1/2',
        },
      }
      delete field.tree
      this.$emit('field-change', field)
    },
  },
}
</script>
<style lang="less" scoped>
.field-border-action {
  outline: 1px dashed var(--border-color);
}
</style>
<style lang="less" scoped>
.field-container {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.field-asset-type-name-info {
  position: absolute;
  right: 8px;
  color: var(--text-color-common-secondary);
}
</style>
