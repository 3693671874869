<template>
  <MTooltip placement="top">
    <template v-slot:trigger>
      <a href="javascript:;" v-on="$listeners">
        <MIcon name="columns" />
      </a>
    </template>
    {{ $t('collapse') }}
  </MTooltip>
</template>

<script>
export default {
  name: 'ControlCollapseButton',
}
</script>
