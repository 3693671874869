import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const networkAdapterComponentFieldMap = () => ({
  network_adapter_component: {
    canCreate: true,
    title: __mtc('network_adapter_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'macAddress',
        name: __mtc('mac_address'),
        inputType: 'text',
        rules: {
          mac_address: true,
        },
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'ipAddresses',
        name: __mtc('ip_address'),
        inputType: 'tags',
        rules: {
          ip: true,
        },
      },
      {
        key: generateId(),
        paramName: 'dnsDomain',
        name: __mtc('dns_domain'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'dnsHostName',
        name: __mtc('dns_host_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'dnsServerSearchOrders',
        name: __mtc('dns_server_search_orders'),
        inputType: 'tags',
        rules: {
          ip: true,
        },
      },
      {
        key: generateId(),
        paramName: 'dhcpEnable',
        name: __mtc('dhcp_enable'),
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'dhcpLeaseObtained',
        name: __mtc('dhcp_lease_obtained'),
        inputType: 'dateTime',
      },
      {
        key: generateId(),
        paramName: 'dhcpLeaseExpires',
        name: __mtc('dhcp_lease_expires'),
        inputType: 'dateTime',
      },
      {
        key: generateId(),
        paramName: 'dhcpServer',
        name: __mtc('dhcp_server'),
        inputType: 'text',
        rules: {
          ip: true,
        },
      },
      {
        key: generateId(),
        paramName: 'defaultIPGateway',
        name: __mtc('default_ip_gateway'),
        inputType: 'tags',
        rules: {
          ip: true,
        },
      },
      {
        key: generateId(),
        paramName: 'ipSubnet',
        name: __mtc('ip_subnet'),
        inputType: 'tags',
        rules: {
          ip: true,
        },
      },
      {
        key: generateId(),
        paramName: 'connectionStatus',
        name: __mtc('connection_status'),
        inputType: 'dropdown',
        contextOptionKey: 'connectionStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
