import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

import {
  transformDepreciation,
  transformDepreciationForServer,
} from '@data/depreciation'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getProductDepreciationApi(refId) {
  return api
    .get(`/asset/product/${refId}/assetdepreciation/getfirst`, {
      notify: false,
    })
    .then(transformDepreciation)
    .catch(() => ({}))
}
export function createDepreciationApi(moduleName, refId, data) {
  return api
    .post(
      `/asset/${moduleName}/${refId}/assetdepreciation`,
      transformDepreciationForServer(data),
      {
        message: __rootT('configured_successfully', {
          resource: __rootTc('depreciation'),
        }),
      }
    )
    .then(transformDepreciation)
}

export function updateDepreciationApi(data) {
  return api
    .patch(
      `/asset/assetdepreciation/${data.id}`,
      transformDepreciationForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('depreciation'),
        }),
      }
    )
    .then(transformDepreciation)
}

export function getDepreciationByDerivationApi(
  moduleName,
  refId,
  derivationType
) {
  return api
    .get(
      `/asset/${moduleName}/assetdepreciation/byid/${refId}/getByDerivation`,
      {
        params: {
          derivation: derivationType,
        },
        notify: false,
      }
    )
    .then(transformDepreciation)
}
