<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="disabled"
      :label="field.title"
      :rules="validationsRules"
    >
      {{ currentValue || '---' }}
    </FlotoFormItem>
    <FlotoFormItem
      v-else
      v-model="currentValue"
      :class="{
        'w-full': field.attributes.widthClass !== 'w-full',
        'w-1/4': field.attributes.widthClass === 'w-full',
      }"
      type="number"
      :read-only="true"
      :step="field.attributes.allowDecimal ? 0.1 : 1"
      :rules="validationsRules"
      :precision="field.attributes.allowDecimal ? 2 : 0"
      :label="field.title"
      :placeholder="field.attributes.placeholder"
      :disabled="
        disabled ||
        mode === 'builder' ||
        field.attributes.systemManagedField ||
        isDisable
      "
      v-bind="attrs"
      v-on="listeners"
    />
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Datetime',
  mixins: [FormField],
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
        nonzero: this.isRequired || false,
        // eslint-disable-next-line
        max_value: 999999999999999,
        // eslint-disable-next-line
        min_value: -999999999999999,
      }
      if (this.field.maxLength && this.field.maxLength !== 0) {
        rules.max_value = this.field.maxLength
      }
      if (this.field.minLength && this.field.minLength !== 0) {
        rules.min_value = this.field.minLength
      }
      return rules
    },
  },
}
</script>
