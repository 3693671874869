import api from '@api'
import Constants from '@constants'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

import {
  transformSupportData,
  transformWorkflowForList,
  // transformModuleNameForServer,
} from '@data/workflow'
import {
  transformApprovalWorkflowForServer,
  transformApprovalWorkflow,
  transformApprovalSettingsForServer,
  transformApprovalSettings,
} from '@data/approval'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import { isPortalLogin } from '@utils/auth'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(buildNameFilterQuery(qualificationFactors.name))
  }
  if (qualificationFactors.filterType) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        qualificationFactors.filterType !== 'enabled',
        'boolean',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getSupportDataApi(model, id) {
  const moduleName = model === Constants.ASSET_UPDATE ? Constants.ASSET : model
  return api
    .get(`${moduleName}${id ? `/${id}` : ''}/approval/supportedData`)
    .then(transformSupportData)
}

export function createApprovalWorkflowApi(workflow) {
  const moduleName =
    workflow.moduleName === Constants.ASSET_UPDATE
      ? Constants.ASSET
      : workflow.moduleName
  return api.post(
    `${moduleName}${
      workflow.parentId ? `/${workflow.parentId}` : ''
    }/approvalworkflow`,
    transformApprovalWorkflowForServer(workflow),
    {
      message: __rootT('created_successfully', {
        resource: `${__rootTc('approval_workflow')}`,
      }),
    }
  )
}

export function getApprovalWorkflowApi(moduleName, id) {
  return api
    .get(`${moduleName}/approvalworkflow/${id}`)
    .then((data) => transformApprovalWorkflow(data))
}

export function getApprovalWorkflowsApi(
  qualificationFactors,
  limit,
  offset,
  additionalUrlParams = {}
) {
  const moduleName =
    qualificationFactors.moduleName === Constants.ASSET_UPDATE
      ? Constants.ASSET
      : qualificationFactors.moduleName
  return api
    .post(
      `${moduleName}${
        qualificationFactors.parentId ? `/${qualificationFactors.parentId}` : ''
      }/approvalworkflow/search/byqual`,
      { qualDetails: buildQualifications(qualificationFactors) },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalUrlParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformWorkflowForList),
        total: data.totalCount,
      }
    })
}

export function updateApprovalWorkflowApi(model, data) {
  const moduleName = model === Constants.ASSET_UPDATE ? Constants.ASSET : model
  return api.patch(
    `${moduleName}/approvalworkflow/${data.id}`,
    transformApprovalWorkflowForServer(data),
    {
      message: __rootT('saved_successfully', {
        resource: `${__rootTc('approval_workflow')}`,
      }),
    }
  )
}

export function bulkUpdateApprovalApi(moduleName, items) {
  const payload = {}
  items.forEach((i) => {
    payload[i.id] = { objOrder: i.order }
  })
  return api.patch(`${moduleName}/approvalworkflow/bulk/update`, payload, {
    message: __rootT('saved_successfully', {
      resource: `${__rootTc('approval_workflow')} `,
    }),
  })
}

export function toggleEnableApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/approvalworkflow/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('approval_workflow')}`,
      }),
    })
    .then((data) => transformWorkflowForList(data))
}

export function deleteApprovalWorkflowApi(moduleName, id) {
  return api.delete(`${moduleName}/approvalworkflow/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('approval_workflow')}`,
    }),
  })
}

export function getApprovalSettingApi() {
  return api
    .get(`${isPortalLogin() ? '/cportal' : ''}/approvalsetting`)
    .then(transformApprovalSettings)
}

export function updateApprovalSettingApi(data) {
  return api
    .patch('/approvalsetting', transformApprovalSettingsForServer(data), {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('approval')} ${__rootTc('setting')}`,
      }),
    })
    .then(transformApprovalSettings)
}
