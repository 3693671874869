<template>
  <MRow :gutter="0">
    <MCol :lg="12" :sm="12" auto-size class="px-2">
      <MRow>
        <MCol :size="6">
          <FlotoFormItem
            v-if="type === 'vendor'"
            id="vendor-id-picker"
            :label="$tc('vendor')"
            rules="required"
          >
            <VendorPicker
              v-model="formData.vendorId"
              :placeholder="$t('select')"
            />
          </FlotoFormItem>
          <FlotoFormItem
            v-if="type === 'product'"
            id="product-id-picker"
            :label="$tc('product')"
            rules="required"
          >
            <ProductPicker
              v-model="formData.productId"
              :placeholder="$t('select')"
            />
          </FlotoFormItem>
        </MCol>
        <MCol :size="6">
          <FlotoFormItem
            id="price-input"
            :label="$tc('price')"
            rules="min_value:1|max_value"
          >
            <MInputGroup compact class="input-group-container mb-2">
              <MInputNumber
                v-model="formData.price"
                :precision="2"
                :placeholder="$t('price')"
              />
              <CurrencyPicker
                v-model="formData.currencyId"
                :allow-clear="false"
              />
            </MInputGroup>
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow>
        <MCol>
          <FlotoFormItem
            id="tax-rate"
            v-model="formData.taxRate"
            type="number"
            :precision="2"
            :min="0"
            :max="100"
            :validation-label="$tc('tax_rate')"
            :placeholder="$tc('tax_rate')"
            :label="`${$tc('tax_rate')} (%)`"
            rules="min_value:0|max_value:100"
          />
        </MCol>
        <MCol :size="6">
          <FlotoFormItem
            id="maintenance-vendor-id"
            :label="__t('maintenance_vendor')"
          >
            <VendorPicker
              v-model="formData.maintenanceVendorId"
              :placeholder="$t('select')"
            />
          </FlotoFormItem>
        </MCol>
        <MCol :size="12">
          <FlotoFormItemReadOnly :label="$tc('warranty_period')">
            <MRow>
              <MCol :size="6" class="pr-1">
                <FlotoFormItem
                  id="year-input"
                  :validation-label="$tc('years')"
                  rules="min_value:0|max_value:50"
                >
                  <div
                    class="flex flex-1 input-group-container"
                    style="
                      height: 2rem;
                      border-top-left-radius: 0;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      border-bottom-left-radius: 0;
                    "
                  >
                    <MInputNumber
                      v-model="formData.year"
                      class="flex-1"
                      type="number"
                      :precision="0"
                    />
                    <div
                      class="px-2 py-1 bg-neutral-lightest flex items-center justify-center text-neutral border-solid border-neutral-lighter border-l-0 border-t border-b border-r rounded rounded-tl-none rounded-bl-none"
                    >
                      {{ $tc('years') }}
                    </div>
                  </div>
                </FlotoFormItem>
              </MCol>
              <MCol :size="6" class="pr-1">
                <FlotoFormItem
                  id="month-input"
                  :validation-label="$tc('months')"
                  rules="min_value:0|max_value:50"
                >
                  <div
                    class="flex flex-1 input-group-container"
                    style="
                      height: 2rem;
                      border-top-left-radius: 0;
                      border-top-right-radius: 4px;
                      border-bottom-right-radius: 4px;
                      border-bottom-left-radius: 0;
                    "
                  >
                    <MInputNumber
                      v-model="formData.month"
                      class="flex-1"
                      type="number"
                      :precision="0"
                    />
                    <div
                      class="px-2 py-1 bg-neutral-lightest flex items-center justify-center text-neutral border-solid border-neutral-lighter border-l-0 border-t border-b border-r rounded rounded-tl-none rounded-bl-none"
                    >
                      {{ $tc('months') }}
                    </div>
                  </div>
                </FlotoFormItem>
              </MCol>
            </MRow>
          </FlotoFormItemReadOnly>
        </MCol>
      </MRow>
      <FlotoFormItem
        id="description-input"
        v-model="formData.description"
        :placeholder="$t('description')"
        :label="$t('description')"
        :rows="5"
        type="textarea"
      />
    </MCol>
  </MRow>
</template>

<script>
import CurrencyPicker from '@components/data-picker/currency-picker'
import VendorPicker from '@components/data-picker/vendor-picker'
import ProductPicker from '@components/data-picker/product-picker'
import { OrganizationComputed } from '@state/modules/organization'
export default {
  name: 'ProductVendorForm',
  components: { VendorPicker, ProductPicker, CurrencyPicker },
  props: {
    formData: { type: Object, required: true },
    type: { type: String, required: true },
  },
  computed: {
    ...OrganizationComputed,
  },
}
</script>
