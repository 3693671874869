<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      :label="field.title"
      :rules="field.required && !disabled ? 'required' : undefined"
    >
      <MButton
        variant="neutral"
        :disabled="disabled || mode === 'builder'"
        :loading="processing"
        v-bind="attrs"
        v-on="listeners"
        @click="handleClick"
      >
        {{ field.attributes.buttonText }}
      </MButton>
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Api',
  mixins: [FormField],
  props: {
    apiFieldUpdateFn: { type: Function, default: undefined },
  },
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    handleClick() {
      if (this.disabled || this.mode === 'builder') {
        return
      }
      this.processing = true
      this.apiFieldUpdateFn(this.field)
        .then(() => {
          this.processing = false
        })
        .catch(() => {
          this.processing = false
        })
    },
  },
}
</script>
