import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    redirect: { name: `${routeNamePrefix}.contract-types` },
    meta: {
      moduleName,
      nonLinked: true,
      titleFn(route, translator, pluralTranslator) {
        return `${pluralTranslator('contract')} ${translator('management')}`
      },
    },
    children: [
      {
        path: 'contract-types',
        name: `${routeNamePrefix}.contract-types`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "contract-management" */ './views/contract-type-list'
            )
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${pluralTranslator('contract_type', 2)}`
          },
        },
      },
    ],
  },
]
