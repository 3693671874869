import api from '@api'
import { generateId } from '@utils/id'
import { transformSupportData } from '@data/workflow'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
  buildRelationalQualificationStructure,
  transformQualification,
  transformQualificationForServer,
} from '@data/qualification'

import { getRootTranslator } from '@utils/get-module-translator'

const transformChangeModel = (item) => ({
  id: item.id,
  name: item.name,
  moduleName: item.model,
  description: item.description,
  enabled: !item.disabled,
  ...(item.serviceCatalogId ? { parentId: item.serviceCatalogId } : {}),
  states: (item.transitionModelStates || []).map((item) => ({
    guid: generateId(),
    from: item.fromStatusId,
    to: item.toStatusId,
    qualifications: transformQualification(
      item.qualification || { groups: [], operators: [] }
    ),
  })),
})

const transformChangeModelForServer = (item) => ({
  id: item.id,
  name: item.name,
  disabled: !item.enabled,
  model: item.moduleName,
  description: item.description,
  ...(item.parentId ? { serviceCatalogId: item.parentId } : {}),
  transitionModelStates: (item.states || []).map((item) => ({
    fromStatusId: item.from,
    toStatusId: item.to,
    qualification: transformQualificationForServer(item.qualifications),
  })),
})

const __rootT = getRootTranslator()

export function getSupportDataApi(model, id) {
  return api
    .get(`${model}${id ? `/${id}` : ''}/transitionModel/supportedData`)
    .then(transformSupportData)
}

export function createChangeModelApi(changeModel) {
  return api.post(
    `/transitionModel`,
    transformChangeModelForServer(changeModel),
    {
      message: __rootT('created_successfully', {
        resource: __rootT('transition_model'),
      }),
    }
  )
}

export function getChangeModelApi(id, params = {}) {
  return api
    .get(`/transitionModel/${id}`, params)
    .then((data) => transformChangeModel(data))
}

export function getChangeModelsApi(
  { moduleName, filter, parentId, transform = true },
  limit,
  offset
) {
  return api
    .post(
      `/transitionModel/search/byqual`,
      {
        qualDetails: buildFlatQualificationStructure(
          [
            buildRelationalQualificationStructure(
              'transitionModel.model',
              'in',
              moduleName,
              'enum'
            ),
            parentId
              ? buildRelationalQualificationStructure(
                  'transitionModel.serviceCatalogId',
                  'equal',
                  parentId,
                  'long'
                )
              : null,
            filter ? buildNameFilterQuery(filter) : null,
          ].filter(Boolean)
        ),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map((t) =>
          transform ? transformChangeModel(t) : t
        ),
        total: data.totalCount,
      }
    })
}

export function updateChangeModelApi(moduleName, data) {
  return api.patch(
    `/transitionModel/${data.id}`,
    transformChangeModelForServer(data),
    {
      message: __rootT('saved_successfully', {
        resource: __rootT('transition_model'),
      }),
    }
  )
}

export function toggleEnableApi(moduleName, id, data) {
  return api
    .patch(`/transitionModel/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootT('transition_model'),
      }),
    })
    .then((data) => transformChangeModel(data))
}

export function deleteChangeModelApi(moduleName, id) {
  return api.delete(`/transitionModel/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootT('transition_model'),
    }),
  })
}
