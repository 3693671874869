import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const biosComponentFieldMap = () => ({
  bios_component: {
    canCreate: true,
    maxCreation: 1,
    title: __mtc('bios_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'biosName',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'SMBiosVersion',
        name: __mtc('sm_bios_version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'releaseDate',
        name: __mtc('release_date'),
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'version',
        name: `${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
