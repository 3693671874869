import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const sharedFolderComponentFieldMap = () => ({
  shared_folder_component: {
    canCreate: false,
    title: __mtc('shared_folder_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'folderName',
        name: __mtc('name'),
        inputType: 'text',
        rules: {
          max: 75,
        },
      },
      {
        key: generateId(),
        paramName: 'folderPath',
        name: __mtc('path'),
        inputType: 'text',
        rules: {
          subject: true,
        },
      },
      {
        key: generateId(),
        paramName: 'allowMaximum',
        name: __mtc('allow_maximum'),
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'maximumAllowed',
        name: __mtc('maximum_allowed'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'status',
        name: __mtc('status'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
