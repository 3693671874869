<template>
  <div v-if="qrimg && tenantPrefrences.qrCodeSupport" class="p-2 px-0">
    <img :src="qrimg" style="border: 1px solid white" />
  </div>
</template>

<script>
import { generateQrCodeForAssetUsingQrData } from '@utils/qrcode'
import { PreferenceComputed } from '@state/modules/preference'
import { generateQrCodeApi } from '../asset-api'

export default {
  name: 'QrCode',
  props: {
    assetId: { type: Number, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      qrimg: null,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  watch: {
    'tenantPrefrences.qrMap': 'displayQR',
  },
  mounted() {
    this.displayQR()
  },
  methods: {
    displayQR() {
      if (!this.tenantPrefrences.qrCodeSupport) {
        return
      }
      if (this.moduleName !== this.$constants.ASSET_CONSUMABLE) {
        generateQrCodeApi(this.moduleName, [this.assetId]).then((data) => {
          generateQrCodeForAssetUsingQrData(data[0]).then((qrcodeStr) => {
            this.qrimg = qrcodeStr
          })
        })
      }
    },
  },
}
</script>
