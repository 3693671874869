<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
import { PageMethods } from '@state/modules/page'
import { CustomRulesMethods } from '@state/modules/custom-rules'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'CmdbModule',
  page() {
    return {
      title: this.$t('cmdb'),
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name.indexOf('create') >= 0) {
      return next((vm) => {
        if (vm.availableModulesInLicense.indexOf(Constants.CMDB) === -1) {
          return vm.$router.replace({ name: 'upgrade-plan' })
        }
        if (vm.myAllowedModules.indexOf(Constants.CMDB) === -1) {
          return vm.$router.replace({ name: '404' })
        }
        // @TODO check cmdb custom rules here
        if (vm.assetFetchManualUpdateRules) {
          vm.assetFetchManualUpdateRules()
        }
      })
    }
    if ([Constants.CMDB].indexOf(to.params.ciType) === -1) {
      return next({ name: '404' })
    }
    // vm is this
    next((vm) => {
      if (vm.availableModulesInLicense.indexOf(Constants.CMDB) === -1) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (vm.myAllowedModules.indexOf(Constants.CMDB) === -1) {
        return vm.$router.replace({ name: '404' })
      }
      // @TODO check cmdb custom rules here
      if (vm.assetFetchManualUpdateRules) {
        vm.assetFetchManualUpdateRules()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    setTimeout(() => {
      if (this[`${Constants.CMDB}PageReset`]) {
        this[`${Constants.CMDB}PageReset`]()
      }
    }, 400)
    next()
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  watch: {
    '$route.params.ciType': function (newValue, prevValue) {
      if (this[`${prevValue}PageReset`]) {
        this[`${prevValue}PageReset`]()
      }
    },
  },
  methods: {
    ...PageMethods,
    ...CustomRulesMethods,
  },
}
</script>
