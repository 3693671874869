<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="disabled"
      :label="label"
      :type="field.inputType"
      :rules="validationsRules"
      v-bind="attrs"
      style="width: inherit"
      v-on="listeners"
    >
      <span
        v-if="field.type === 'textarea'"
        v-html="currentValue.replace(/\n/g, '<br />')"
      />
      <span v-else>{{ currentValue || '---' }}</span>
    </FlotoFormItem>
    <FlotoFormItem
      v-else
      v-model="currentValue"
      :disabled="
        mode === 'builder' || field.attributes.systemManagedField || isDisable
      "
      :label="label"
      :rules="validationsRules"
      :class="{
        'w-full':
          (field.inputType === 'number' || field.inputType === 'datetime') &&
          field.attributes.widthClass !== 'w-full',
        'w-1/4':
          (field.inputType === 'number' || field.inputType === 'datetime') &&
          field.attributes.widthClass === 'w-full',
      }"
      :type="field.inputType"
      v-bind="attrs"
      v-on="listeners"
    />
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'TextField',
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
        subject: this.field.inputType === 'text',
      }
      if (this.field.maxLength && this.field.maxLength !== 0) {
        rules.max = this.field.maxLength
      }
      if (this.field.minLength && this.field.minLength !== 0) {
        rules.min = this.field.minLength
      }
      return rules
    },
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
    attrs() {
      const { widthClass, allowDecimal, allowTime, ...attrs } =
        this.field.attributes
      if (this.field.type === 'number') {
        attrs.precision = allowDecimal ? 2 : 0
      }
      if (this.field.type === 'datetime') {
        attrs.showTime = allowTime
      }
      return { ...attrs, ...this.$attrs }
    },
  },
}
</script>
