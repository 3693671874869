<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem v-if="disabled" :label="label" :rules="validationsRules">
      {{ currentValue || '---' }}
    </FlotoFormItem>
    <FlotoFormItem v-else :label="label" :rules="validationsRules">
      <MRadioGroup
        v-model="currentValue"
        :name="field.name"
        v-bind="attrs"
        :disabled="
          mode === 'builder' || field.attributes.systemManagedField || isDisable
        "
        :read-only="readOnly"
        :class="{
          flex: field.attributes.displayOptionVertically,
          'flex-col': field.attributes.displayOptionVertically,
          'text-ellipsis min-w-0 flex flex-wrap': true,
        }"
        @change="handleChange"
        v-on="listeners"
      >
        <MRadio
          v-for="option in checkboxOptions"
          :key="option"
          :value="option"
          class="text-ellipsis"
        >
          <span :title="option" class="text-ellipsis">
            {{ option }}
          </span>
        </MRadio>
      </MRadioGroup>
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Radio',
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
      }
      return rules
    },
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
    checkboxOptions() {
      if ((this.hiddenOptionsKeys || []).length) {
        return this.field.options.filter(
          (o) => this.hiddenOptionsKeys.indexOf(o) === -1
        )
      }
      if ((this.visibleOptionsKeys || []).length) {
        return this.field.options.filter(
          (o) => this.visibleOptionsKeys.indexOf(o) >= 0
        )
      }
      return this.field.options
    },
  },
  created() {
    if (this.value) {
      if (this.field.options.indexOf(this.value) === -1) {
        this.$emit('update-value', '')
      }
    }
  },
  methods: {
    handleChange() {
      this.$nextTick(() => {
        this.$emit('field-blur')
      })
    },
  },
}
</script>
