import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const logicalDiskComponentFieldMap = () => ({
  logical_disk_component: {
    canCreate: true,
    title: __mtc('logical_disk_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'logicalDiskName',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'fileSystemType',
        name: __mtc('file_system_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'driveType',
        name: __mtc('drive_type'),
        inputType: 'dropdown',
        contextOptionKey: 'driveTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'size',
        name: __mtc('size'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'freeSpace',
        name: __mtc('free_space'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
