import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const pointingDeviceComponentFieldMap = () => ({
  pointing_device_component: {
    canCreate: true,
    title: __mtc('pointing_device_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'numberOfButtons',
        name: __mtc('number_of_buttons'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'pointingType',
        name: __mtc('pointing_type'),
        inputType: 'dropdown',
        contextOptionKey: 'pointingTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'pnpDeviceId',
        name: __mtc('pnp_device_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
