var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoBorderedActions',{class:{
    'sortable-chosen': (_vm.currentEditingItem || {}).guid === _vm.field.guid,
    'field-border-action':
      !(
        (_vm.currentHoverActiveItemId === _vm.field.guid || _vm.isHovering) &&
        !_vm.isDragging
      ) && _vm.mode === 'builder',
    'cursor-move': _vm.mode === 'builder',
  },attrs:{"active":(_vm.currentHoverActiveItemId === _vm.field.guid || _vm.isHovering) && !_vm.isDragging,"disabled":_vm.mode !== 'builder' || _vm.hideAction,"data-field-type":_vm.field.isSystemField ? 'system' : _vm.field.inputType,"data-field-mandatory":_vm.field.required},on:{"mouseenter":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false},"click":function($event){_vm.mode === 'builder' && _vm.$emit('field-edit', _vm.field)}},scopedSlots:_vm._u([(_vm.mode === 'builder')?{key:"actions",fn:function(){return [_c('ActionButtons',{attrs:{"blocked-btns":_vm.blockedButtons,"field-width-text":_vm.fieldWidthText,"allow-split-field":_vm.allowSplitField},on:{"remove":function($event){return _vm.$emit('field-remove', _vm.field)},"duplicate":function($event){return _vm.$emit('duplicate', _vm.field)},"remove-trigger":function($event){return _vm.$emit('field-remove-trigger')},"edit":function($event){return _vm.$emit('field-edit', _vm.field)},"clear":function($event){return _vm.$emit('field-hover-clear')},"collapse":_vm.collapseField,"expand":_vm.expandField}})]},proxy:true}:null],null,true)},[(
      _vm.mode === 'builder' &&
      [_vm.$constants.ASSET, _vm.$constants.CMDB].indexOf(_vm.field.moduleName) >= 0 &&
      _vm.field.assetCiTypeName
    )?_c('div',{staticClass:"field-asset-type-name-info bg-neutral-lightest px-2 font-thin"},[_vm._v(" "+_vm._s(_vm.field.assetCiTypeName)+" ")]):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }