import api from '@api'
import SortBy from 'lodash/sortBy'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformRiskType,
  transformRiskTypeForServer,
  transformChangeType,
  transformChangeTypeForServer,
  transformReasonType,
  transformReasonTypeForServer,
  transformTargetEnvironment,
  transformTargetEnvironmentForServer,
} from '@data/change-management'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualification = ({ name, selectedName, ids, quals }) => {
  return {
    ...(name || selectedName || ids || quals
      ? {
          qualDetails: buildFlatQualificationStructure(
            [
              name && buildNameFilterQuery(name),
              selectedName &&
                selectedName.length &&
                buildNameFilterQuery(selectedName, 'in'),
              ids &&
                ids.length &&
                buildRelationalQualificationStructure(
                  'id',
                  'in',
                  ids,
                  'long',
                  'db'
                ),
              ...(quals || []),
            ].filter(Boolean)
          ),
        }
      : {}),
  }
}

export function getRiskTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset,
  params = {}
) {
  return api
    .post(
      '/change/risktype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformRiskType), total: data.totalCount }
    })
}

export function createRiskTypeApi(data) {
  const updateData = transformRiskTypeForServer(data)
  return api
    .post('/change/risktype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('change_risk'),
      }),
    })
    .then(transformRiskType)
}

export function removeRiskTypeApi(data) {
  return api.delete(`/change/risktype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('change_risk'),
    }),
  })
}

export function updateRiskTypeApi(data) {
  const updateData = transformRiskTypeForServer(data)
  return api
    .patch(`/change/risktype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('change_risk'),
      }),
    })
    .then(transformRiskType)
}

// change type apis

export function getChangeTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset,
  params = {}
) {
  return api
    .post(
      '/change/changetype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformChangeType), total: data.totalCount }
    })
}

export function createChangeTypeApi(data) {
  const updateData = transformChangeTypeForServer(data)
  return api
    .post('/change/changetype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('change_type'),
      }),
    })
    .then(transformChangeType)
}

export function removeChangeTypeApi(data) {
  return api.delete(`/change/changetype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('change_type'),
    }),
  })
}

export function updateChangeTypeApi(data) {
  const updateData = transformChangeTypeForServer(data)
  return api
    .patch(`/change/changetype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('change_type'),
      }),
    })
    .then(transformChangeType)
}
// reason type apis

export function getReasonTypesApi(
  { name, selectedName, quals },
  pageSize,
  offset,
  params = {}
) {
  return api
    .post(
      '/change/reasontype/search/byqual',
      buildQualification({ name, selectedName, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return { items: list.map(transformReasonType), total: data.totalCount }
    })
}

export function createReasonTypeApi(data) {
  const updateData = transformReasonTypeForServer(data)
  return api
    .post('/change/reasontype', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('change_reason'),
      }),
    })
    .then(transformReasonType)
}

export function removeReasonTypeApi(data) {
  return api.delete(`/change/reasontype/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('change_reason'),
    }),
  })
}

export function updateReasonTypeApi(data) {
  const updateData = transformReasonTypeForServer(data)
  return api
    .patch(`/change/reasontype/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('change_reason'),
      }),
    })
    .then(transformReasonType)
}

// targetenvironment apis

export function getTargetEnvironmentsApi(
  { name, selectedName, ids, quals },
  pageSize,
  offset,
  params = {}
) {
  return api
    .post(
      '/change/targetenvironment/search/byqual',
      buildQualification({ name, selectedName, ids, quals }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return {
        items: list.map(transformTargetEnvironment),
        total: data.totalCount,
      }
    })
}

export function createTargetEnvironmentApi(data) {
  const updateData = transformTargetEnvironmentForServer(data)
  return api
    .post('/change/targetenvironment', updateData, {
      message: __rootT('added_successfully', {
        resource: __rootTc('target_environment'),
      }),
    })
    .then(transformTargetEnvironment)
}

export function removeTargetEnvironmentApi(data) {
  return api.delete(`/change/targetenvironment/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('target_environment'),
    }),
  })
}

export function updateTargetEnvironmentApi(data) {
  const updateData = transformTargetEnvironmentForServer(data)
  return api
    .patch(`/change/targetenvironment/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('target_environment'),
      }),
    })
    .then(transformTargetEnvironment)
}
