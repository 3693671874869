import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const mobileDeviceOsComponentFieldMap = () => ({
  mobile_device_os_component: {
    canCreate: true,
    maxCreation: 1,
    title: __mtc('mobile_device_os_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'osName',
        name: __mtc('os_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'osVersion',
        name: __mtc('os_version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'buildversion',
        name: `${__mtc('build_version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
    ],
  },
})
