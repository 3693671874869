<template>
  <div class="flex flex-1 flex-col p-2">
    <MRow>
      <MCol :size="12">
        <MRow>
          <MCol :size="6">
            <FlotoFormItem
              id="depreciation-method-picker"
              :label="`${$tc('depreciation')} ${$tc('method')}`"
              rules="required|none_value"
            >
              <FlotoDropdownPicker
                v-model="value.depreciationMethod"
                :disabled="
                  disabled ||
                  Boolean(
                    moduleName !== $constants.PRODUCT &&
                      value.depreciationDerivation === 'product_level' &&
                      value.id
                  )
                "
                searchable
                as-input
                :options="depreciationMethodOptions"
              />
            </FlotoFormItem>
          </MCol>
        </MRow>
        <template
          v-if="value.depreciationMethod !== 'none' && value.depreciationMethod"
        >
          <MRow
            v-if="
              ['straight_line', 'declining_balance'].indexOf(
                value.depreciationMethod
              ) >= 0
            "
          >
            <MCol>
              <FlotoFormItem
                id="depreciation-type-radio"
                :label="`${$tc('depreciation')} ${$tc('type')}`"
                rules="none_value"
              >
                <MRadioGroup
                  v-model="value.depreciationType"
                  :disabled="disabled"
                  searchable
                  as-input
                  :options="depreciationTypeOptions"
                />
              </FlotoFormItem>
            </MCol>
          </MRow>
          <MRow>
            <MCol :size="6">
              <FlotoFormItem
                id="usefull-life-input"
                :label="useFullLifeLabel"
                rules="numeric|min_value:1|max_value:9999"
              >
                <div class="flex w-full">
                  <MInputGroup compact class="input-group-container mb-2">
                    <div class="flex-1">
                      <MInputNumber
                        v-model="value.usefulLife"
                        :precision="0"
                        :disabled="disabled"
                      />
                    </div>
                    <div
                      class="px-2 bg-neutral-lightest flex items-center justify-center text-neutral border-solid border-neutral-lighter border-l-0 border-t border-b border-r rounded rounded-tl-none rounded-bl-none"
                      style="display: inline-flex"
                    >
                      <template
                        v-if="
                          value.depreciationMethod === 'sum_of_the_years_digit'
                        "
                        >{{ $tc('years') }}</template
                      >
                      <template v-else>
                        {{
                          value.depreciationType === 'useful_life' ||
                          value.depreciationMethod ===
                            'double_declining_balance'
                            ? $tc('months')
                            : '%'
                        }}
                      </template>
                      <!-- {{
                        value.depreciationType === 'useful_life'
                          ? value.depreciationMethod ===
                            'sum_of_the_years_digit'
                            ? $tc('years')
                            : $tc('months')
                          : '%'
                      }} -->
                    </div>
                  </MInputGroup>
                </div>
              </FlotoFormItem>
            </MCol>
            <MCol :size="6">
              <FlotoFormItem
                id="salvage-amount-input"
                :label="`${$tc('salvage')} ${$tc('value')}`"
                rules="min_value:1|max_value"
              >
                <MInputGroup compact class="input-group-container mb-2">
                  <MInputNumber
                    v-model="value.salvageAmount"
                    :disabled="disabled"
                    :precision="2"
                  />
                  <CurrencyPicker
                    v-model="value.currencyId"
                    :allow-clear="false"
                  />
                </MInputGroup>
              </FlotoFormItem>
            </MCol>
          </MRow>
        </template>
      </MCol>
    </MRow>
  </div>
</template>

<script>
import CurrencyPicker from '@components/data-picker/currency-picker'
import { OrganizationComputed } from '@state/modules/organization'
import {
  depreciationMethodOptions,
  depreciationTypeOptions,
} from '../../helpers/depreciation'
export default {
  name: 'DepreciationForm',
  components: { CurrencyPicker },
  props: {
    value: { type: Object, required: true },
    resetForm: { type: Function, default: undefined },
    moduleName: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    this.depreciationMethodOptions =
      this.moduleName !== this.$constants.PRODUCT
        ? depreciationMethodOptions().filter((o) => o.key !== 'none')
        : depreciationMethodOptions()
    this.depreciationTypeOptions = depreciationTypeOptions()
    return {}
  },
  computed: {
    ...OrganizationComputed,
    useFullLifeLabel() {
      if (
        ['sum_of_the_years_digit', 'double_declining_balance'].indexOf(
          this.value.depreciationMethod
        ) >= 0
      ) {
        return this.$tc('useful_life')
      }
      if (
        ['straight_line', 'declining_balance'].indexOf(
          this.value.depreciationMethod
        ) >= 0
      ) {
        if (this.value.depreciationType === 'useful_life') {
          return this.$tc('useful_life')
        }
        return `${this.$tc('depreciation')} ${this.$tc('percent')}/${this.$tc(
          'years'
        )}`
      }
      return this.$tc('useful_life')
    },
  },
  watch: {
    'value.depreciationMethod': {
      immediate: false,
      handler(newValue) {
        if (!this.value.id) {
          this.resetForm({
            ...this.value,
            depreciationType: 'useful_life',
          })
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.input-group-container {
  top: 3px !important;
  display: flex !important;
  .@{ant-prefix}-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

<style lang="less">
.input-group-container {
  .duration-type {
    .@{ant-prefix}-select-selection {
      @apply bg-neutral-lightest text-neutral;
    }
  }
}
</style>
