import {
  getModuleTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)
const __tc = getRootPluaralTranslator()

export const snmpProperyFieldMap = () => ({
  snmp_property_group: {
    title: __mtc('snmp_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'systemOID',
        name: `${__mtc('system_oid')}`,
        inputType: 'text',
        rules: {
          system_oid: true,
        },
      },
      {
        key: generateId(),
        paramName: 'systemUpTime',
        name: `${__mtc('system_up_time')}`,
        inputType: 'timePicker',
      },
      {
        key: generateId(),
        paramName: 'firmware',
        name: `${__mtc('firmware')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'firmwareVersion',
        name: `${__mtc('firmware')} ${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'port',
        name: `${__mtc('port')}`,
        inputType: 'number',
        rules: {
          port: true,
          numeric: true,
        },
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: `${__tc('manufacturer')}`,
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'contactName',
        name: `${__mtc('contact_name')}`,
        inputType: 'text',
      },
    ],
  },
})
