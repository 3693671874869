<template>
  <MPopover
    :key="rendorCount"
    placement="bottomRight"
    :overlay-style="{ width: '300px' }"
  >
    <template v-slot:trigger>
      <MButton> {{ $tc('start') }} {{ $tc('channel') }} </MButton>
    </template>
    <h6> {{ $tc('start') }} {{ $tc('channel') }} </h6>
    <FlotoForm @submit="handleFormSubmit">
      <FlotoFormItem
        :label="`${$tc('extension')} ${$tc('type')}`"
        rules="required"
      >
        <MRadioGroup
          v-model="formData.extensionType"
          as-button
          :options="extensionTypeOptions"
        />
      </FlotoFormItem>
      <FlotoFormItem
        v-model="formData.extensionNumber"
        type="text"
        rules="required"
        :placeholder="`${$tc('extension')} ${$tc('number')}`"
        :label="`${$tc('extension')} ${$tc('number')}`"
      />
      <template v-slot:submit>
        <div class="text-right">
          <MButton type="submit" :loading="porcessing">
            {{ $t('submit') }}
          </MButton>
        </div>
      </template>
    </FlotoForm>
  </MPopover>
</template>

<script>
import { extensionTypeOptions } from '@modules/contactops/helpers/utils'
import { startCoOpsChannelApi } from '../contactOps-manager-api'
import { authComputed, authMethods } from '@state/modules/auth'

export default {
  name: 'StartChannelBtn',
  inject: { contactOpsConfigContext: { config: {} } },
  data() {
    this.extensionTypeOptions = extensionTypeOptions()
    return {
      porcessing: false,
      rendorCount: 1,
      defaultData: {
        extensionType: (this.user || {}).coOpsExtensionType,
        extensionNumber: (this.user || {}).coOpsExtensionNumber,
      },
    }
  },
  computed: {
    ...authComputed,
    formData: {
      get() {
        return this.defaultData
      },
      set(value) {
        this.defaultData = value
      },
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        this.defaultData = {
          extensionType: (newValue || {}).coOpsExtensionType,
          extensionNumber: (newValue || {}).coOpsExtensionNumber,
        }
      },
    },
  },
  methods: {
    ...authMethods,
    handleFormSubmit() {
      this.porcessing = true
      startCoOpsChannelApi(this.formData)
        .then((response) => {
          this.porcessing = false
          this.$emit('updated-status', response)
          this.rendorCount = this.rendorCount + 1
          this.refreshUser()
        })
        .catch(() => {
          this.porcessing = false
        })
    },
  },
}
</script>
