import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const storageBucketComponentFeildMap = () => ({
  storage_bucket_component: {
    canCreate: true,
    title: __mtc('storage_bucket_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'bucketName',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'bucketCreatedDate',
        name: __mtc('bucket_created_date'),
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'bucketStorage',
        name: __mtc('bucket_storage'),
        inputType: 'bytes',
        unit: 'mb',
      },
      {
        key: generateId(),
        paramName: 'region',
        name: __mtc('region'),
        inputType: 'text',
      },
    ],
  },
})
