import Constants from '@constants'
import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      breadcrumbKey(route, translator, pluralTranslator) {
        if (route.name.indexOf('business') >= 0) {
          return pluralTranslator('organization')
        }
        return `${pluralTranslator(Constants.PROBLEM)} ${translator(
          'management'
        )}`
      },
      // titleFn(route, translator, pluralTranslator) {
      //   return `${pluralTranslator(Constants.PROBLEM)} ${translator(
      //     'management'
      //   )}`
      // },
    },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "admin" */ './views/list')),
        meta: {
          titleFn(route, translator, pluralTranslator) {
            return `${pluralTranslator('business')} ${pluralTranslator(
              'service'
            )}`
          },
        },
      },
    ],
  },
]
