<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      :rules="{
        required: field.required ? true : false,
        nonzero: field.required ? true : false,
        // eslint-disable-next-line
        max_value: field.maxValue ? field.maxValue : false,
        // eslint-disable-next-line
        min_value: field.minValue ? field.minValue : false,
      }"
      :label="label"
    >
      <SliderPicker
        v-model="currentValue"
        :disabled="
          disabled || mode === 'builder' || field.attributes.systemManagedField
        "
        :min="field.minValue"
        :max="field.maxValue"
        dots
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import SliderPicker from '@components/numeric-slider/slider-picker'
import FormField from '../../mixins/form-field'

export default {
  name: 'Slider',
  components: { SliderPicker },
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
  },
}
</script>
