import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: { moduleName, breadcrumbKey: 'automation' },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "automation" */ './views/auto-assignment'
            )
          ),
        meta: {
          breadcrumbKey: 'auto_assignment',
        },
      },
    ],
  },
]
