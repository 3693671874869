import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const storageProperyFieldMap = () => ({
  storage_property_group: {
    title: __mtc('storage_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'driveType',
        name: `${__mtc('drive_type')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'diskSpace',
        name: `${__mtc('disk_space')}`,
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'freeSpace',
        name: `${__mtc('free_space')}`,
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'freeSpaceCheckedOn',
        name: `${__mtc('free_space_checked_on')}`,
        inputType: 'dateTime',
      },
    ],
  },
})
