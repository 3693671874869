import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const firewallProperyFieldMap = () => ({
  firewall_property_group: {
    title: __mtc('firewall_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'firmware',
        name: `${__mtc('firmware')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'firmwareVersion',
        name: `${__mtc('firmware')} ${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'port',
        name: `${__mtc('port')}`,
        inputType: 'number',
        rules: {
          port: true,
          numeric: true,
        },
      },
      {
        key: generateId(),
        paramName: 'subnetMask',
        name: `${__mtc('subnet_mask')}`,
        inputType: 'text',
      },
    ],
  },
})
