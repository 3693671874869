import SortBy from 'lodash/sortBy'
import api from '@api'

import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'
import { transformSoftwareLicenseType } from '@data/software-license-type'

const buildQualification = ({ name, selectedName }) => {
  return {
    ...(name || selectedName
      ? {
          qualDetails: buildFlatQualificationStructure(
            [
              name && buildNameFilterQuery(name),
              selectedName && buildNameFilterQuery(selectedName),
            ].filter(Boolean)
          ),
        }
      : {}),
  }
}
export function getSoftwareLicenseTypesApi(
  { name, selectedName },
  pageSize,
  offset
) {
  return api
    .post(
      '/asset/softwarelicencetype/search/byqual',
      buildQualification({ name, selectedName }),
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      const list = SortBy(data.objectList || [], (d) => d.objOrder)
      return {
        items: list.map(transformSoftwareLicenseType),
        total: data.totalCount,
      }
    })
}

export function getSoftwareLicenseTypeApi(id) {
  return api
    .get(`/asset/softwarelicencetype/${id}`)
    .then(transformSoftwareLicenseType)
}
