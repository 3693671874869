<template>
  <MPopover
    ref="changeBarcodePopoverRef"
    placement="bottomLeft"
    :overlay-style="{ width: '300px', zIndex: 9990 }"
    overlay-class-name="form-overlay"
  >
    <template v-slot:trigger>
      <slot name="trigger">
        <MIcon name="barcode" class="action-menu-icon" />
        <span class="ml-1 action-menu-text">
          {{ $tc('setting') }}
        </span>
      </slot>
    </template>
    <h6>{{ $tc('add') }} {{ $tc('barcode') }}</h6>
    <MButton
      id="generate-new-barcode-btn"
      block
      variant="default"
      class="mt-3"
      @click="generateNewBarcode"
    >
      {{ $tc('generate') }} {{ $tc('new') }} {{ $tc('barcode') }}
    </MButton>
    <MDivider>{{ $tc('or') }}</MDivider>
    <FlotoForm @submit="handleFormSubmit">
      <FlotoFormItem
        id="associate-a-barcode-input"
        v-model="barCode"
        :placeholder="`${$tc('barcode')}`"
        :label="`${__tc('associate_a_barcode')}`"
        rules="required"
      >
      </FlotoFormItem>
      <template v-slot:submit>
        <div class="text-right">
          <MButton id="update-btn" type="submit">
            {{ $t('update') }}
          </MButton>
        </div>
      </template>
    </FlotoForm>
  </MPopover>
</template>

<script>
import { changeAssociateBracodeApi } from '@modules/asset/asset-api'
export default {
  name: 'ChangeBarcode',
  props: {
    asset: { type: Object, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      barCode: undefined,
    }
  },
  methods: {
    handleFormSubmit() {
      this.$emit('submit', { barCode: this.barCode })
      this.handleHide()
      this.$emit('trigger')
    },
    generateNewBarcode() {
      changeAssociateBracodeApi(this.moduleName, this.asset.id).then(() => {
        this.$emit('refresh')
        this.$emit('trigger')
      })
    },
    handleHide() {
      this.$refs.changeBarcodePopoverRef.hide()
    },
  },
}
</script>
