import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const processorComponentFieldMap = () => ({
  processor_component: {
    canCreate: true,
    title: __mtc('processor_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'processorName',
        name: __mtc('processor_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'width',
        name: __mtc('width'),
        inputType: 'number',
        unit: 'bit',
      },
      {
        key: generateId(),
        paramName: 'cpuSpeed',
        name: `${__mtc('cpu_speed')}`,
        inputType: 'hertz',
      },
      {
        key: generateId(),
        paramName: 'coreCount',
        name: `${__mtc('core_count')}`,
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'extClock',
        name: `${__mtc('ext_clock')}`,
        inputType: 'hertz',
        unit: 'mhz',
      },
      {
        key: generateId(),
        paramName: 'l1CacheSize',
        name: `${__mtc('l1_cache_size')}`,
        inputType: 'bytes',
        unit: 'mb',
      },
      {
        key: generateId(),
        paramName: 'l2CacheSize',
        name: `${__mtc('l2_cache_size')}`,
        inputType: 'bytes',
        unit: 'mb',
      },
      {
        key: generateId(),
        paramName: 'l3CacheSize',
        name: `${__mtc('l3_cache_size')}`,
        inputType: 'bytes',
        unit: 'mb',
      },
      {
        key: generateId(),
        paramName: 'family',
        name: `${__mtc('family')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: `${__mtc('description')}`,
        inputType: 'textarea',
      },
      {
        key: generateId(),
        paramName: 'deviceId',
        name: `${__mtc('device_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'socketDesignation',
        name: `${__mtc('socket_designation')}`,
        inputType: 'text',
      },
    ],
  },
})
