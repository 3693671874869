import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const keyboardComponentFieldMap = () => ({
  keyboard_component: {
    canCreate: true,
    title: __mtc('keyboard_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'keyboardName',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'installDate',
        name: __mtc('install_date'),
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'pnpDeviceId',
        name: __mtc('pnp_device_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
