<template>
  <div class="actions cursor-pointer">
    <a href="javascript:;" class="cursor-move">
      <MIcon name="arrows" />
    </a>
    <CollapseButton
      v-if="fieldWidthText === 'full' && allowSplitField"
      @click.stop="$emit('collapse')"
    />
    <ExpandButton
      v-if="fieldWidthText !== 'full' && allowSplitField"
      @click.stop="$emit('expand')"
    />
    <ControlDuplicateBtn
      v-if="blockedBtns.indexOf('duplicate') === -1"
      @click.stop="$emit('duplicate')"
    />
    <ControlEditBtn
      v-if="blockedBtns.indexOf('edit') === -1"
      @click.stop="$emit('edit')"
    />
    <ControlDeleteBtn
      v-if="blockedBtns.indexOf('delete') === -1"
      @show="$emit('remove-trigger')"
      @cancel="$emit('clear')"
      @click="$emit('remove')"
    />
  </div>
</template>

<script>
import ControlDeleteBtn from './delete'
import ControlDuplicateBtn from './duplicate'
import ControlEditBtn from './edit'
import CollapseButton from './collapse'
import ExpandButton from './expand'

export default {
  name: 'ActionButtons',
  components: {
    ControlDeleteBtn,
    ControlDuplicateBtn,
    ControlEditBtn,
    CollapseButton,
    ExpandButton,
  },
  props: {
    blockedBtns: { type: Array, default: () => [] },
    fieldWidthText: { type: String, default: 'half' },
    // eslint-disable-next-line
    allowSplitField: { type: Boolean, default: true },
  },
}
</script>

<style lang="less" scoped>
.actions {
  a {
    color: inherit;

    @apply mx-2;
  }
}
</style>
