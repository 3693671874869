import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}/:moduleName`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "import-csv-batch" */ './views/list')
          ),
      },
      // {
      //   path: ':batchId',
      //   name: `${routeNamePrefix}.detail`,
      //   component: () =>
      //     lazyLoadView(
      //       import(/* webpackChunkName: "import-csv-batch" */ './views/detail')
      //     ),
      // },
    ],
  },
]
