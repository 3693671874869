import Bus from '@utils/emitter'
import IsEqual from 'lodash/isEqual'
import FieldContainer from '../components/fields/field-container.vue'
import { authComputed } from '@state/modules/auth'
// import { useOnPortalEnabledModules } from '@data/form'
import scriptEvaluator from '@utils/script-evaluator'

export default {
  components: { FieldContainer },
  inject: {
    formScriptContext: {
      default: () => ({
        form: null, // form: () => new FormHelper(),
      }),
    },
  },
  props: {
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    useDefaultValue: { type: Boolean, default: false },
    mode: { type: String, required: true },
    field: { type: Object, required: true },
    sectionId: { type: String, required: false, default: undefined },
    value: {
      type: [Object, String, Array, Number, Boolean],
      default: undefined,
    },
    hideAction: { type: Boolean, default: false },
    applyFormRules: { type: Boolean, default: false },
    fieldRulesState: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      fieldOptions: [],
      disabledOverride: null,
    }
  },
  model: {
    event: 'update-value',
  },
  created() {
    const handler = ({ field, value }) => {
      if (field === (this.field.title || '').toLowerCase()) {
        this.currentValue = value
        const fieldKey = this.field.isSystemField
          ? this.field.paramName
          : this.field.id
        setTimeout(() => {
          this.$emit('custom-sript-set-value-executed', { [fieldKey]: value })
        }, 1000)
      }
    }
    const getHandler = ({ field }) => {
      if (field === (this.field.title || '').toLowerCase()) {
        Bus.$emit('form:helper:get:value:response', this.currentValue)
      }
    }
    const fieldOptionsHandler = ({ field, options }) => {
      if (field === (this.field.title || '').toLowerCase()) {
        this.fieldOptions = options.map((o) => ({ key: o, text: o, value: o }))
      }
    }
    const enableToogleHandler = ({ field, value }) => {
      if (field === (this.field.title || '').toLowerCase()) {
        this.disabledOverride = value
      }
    }
    Bus.$on('form:helper:get:value', getHandler)
    Bus.$on('form:helper:set:value', handler)
    Bus.$on('form:helper:set:options', fieldOptionsHandler)
    Bus.$on('form:helper:set:enable', enableToogleHandler)

    this.$once('hook:beforeDestroy', () => {
      Bus.$off('form:helper:set:value', handler)
      Bus.$off('form:helper:get:value', getHandler)
      Bus.$off('form:helper:set:options', fieldOptionsHandler)
      Bus.$off('form:helper:set:enable', enableToogleHandler)
    })
  },
  computed: {
    ...authComputed,
    currentValue: {
      get() {
        return this.value
      },
      set(newValue) {
        if (this.mode === 'builder') {
          return false
        }
        this.$emit('update-value', newValue)
      },
    },
    containerComponent() {
      if (this.mode === 'builder') {
        return FieldContainer
      }
      return 'FlotoFormItem'
    },
    listeners() {
      const { change, input, ...listeners } = this.$listeners
      if (this.mode === 'builder') {
        return listeners
      }
      return {
        ...this.$listeners,
        blur: (...args) => {
          this.$emit(`${this.field.inputType}-blur`, ...args)
          this.$nextTick(() => {
            this.$emit('field-blur')
          })
        },
        requesterIdSelected: (...args) => {
          this.formScriptContext.form.addRequester(...args)
          this.$emit('requesterIdSelected', ...args)
        },
        customAsSystemRequesterIdSelected: (...args) => {
          this.formScriptContext.form.addRequester(...args)
          this.$emit('customAsSystemRequesterIdSelected', ...args)
        },
        technicianSelected: (...args) => {
          this.formScriptContext.form.addRequester(...args)
          this.$emit('technicianSelected', ...args)
        },
      }
    },
    attrs() {
      return this.$attrs
    },
    containerListeners() {
      const f = {}
      if (this.mode === 'builder') {
        f['field-change'] = this.$listeners['field-change']
        f['field-remove'] = this.$listeners['field-remove']
        f['field-remove-trigger'] = this.$listeners['field-remove-trigger']
        f['field-edit'] = this.$listeners['field-edit']
        f['field-hover-clear'] = this.$listeners['field-hover-clear']
        f['duplicate'] = this.$listeners['duplicate']
      }
      return f
    },
    containerAttrs() {
      const a = {}
      a['current-hover-active-item-id'] =
        this.$attrs['current-hover-active-item-id']
      a['allow-split-field'] = this.$attrs['allow-split-field']
      a['is-dragging'] = this.$attrs['is-dragging']
      a['current-editing-item'] = this.$attrs['current-editing-item']
      a['mode'] = this.mode
      a['hide-action'] = this.hideAction
      return a
    },
    singleFieldRulesState() {
      const fieldKey = this.field.isSystemField
        ? this.field.paramName
        : this.field.id
      return this.fieldRulesState[fieldKey] || {}
    },
    customScriptSingleRulesState() {
      return this.fieldRulesState.executableCustomScripts || []
    },
    // isRuleDirty() {
    //   if (this.mode === 'builder' || !this.applyFormRules) {
    //     return false
    //   }
    //   return this.singleFieldRulesState.isDirty || false
    // },
    // isHide() {
    //   if (this.mode === 'builder' || !this.applyFormRules) {
    //     return false
    //   }
    //   return this.singleFieldRulesState.hide
    // },
    isMandatory() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return false
      }
      return this.singleFieldRulesState.mandatory
    },
    isDisable() {
      if (this.disabledOverride !== null) {
        return this.disabledOverride
      }
      if (this.mode === 'builder' || !this.applyFormRules) {
        return false
      }
      return this.singleFieldRulesState.disable
    },
    hiddenOptionsKeys() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return []
      }
      return this.singleFieldRulesState.hiddenOptionsKeys
    },
    visibleOptionsKeys() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return []
      }
      return this.singleFieldRulesState.visibleOptionsKeys
    },
    filterData() {
      if (this.mode === 'builder' || !this.applyFormRules) {
        return []
      }
      return this.singleFieldRulesState.filterData
    },
  },
  watch: {
    singleFieldRulesState: {
      immediate: true,
      handler(newValue) {
        if ('setValue' in newValue && newValue.setValue) {
          // send second arg "true" for delay set state
          this.$emit('update-value', newValue.setValue, true)
        }
        if ('clearValue' in newValue) {
          // send second arg "true" for delay set state
          this.$emit('update-value', newValue.clearValue, true)
        }
      },
    },
    customScriptSingleRulesState: {
      immediate: true,
      handler(newValue, prevValue) {
        if (
          newValue &&
          newValue.length &&
          !IsEqual(newValue, prevValue) &&
          this.formScriptContext.form
        ) {
          newValue.forEach((script) => {
            this.$emit('remove-executed-script', script)
            return scriptEvaluator(script, {
              MS: this.formScriptContext.form,
            })
          })
        }
      },
    },
  },
}
