import { getRootPluaralTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'

const __rootTc = getRootPluaralTranslator()

export const usbComponentFieldMap = () => ({
  usb_controller_component: {
    canCreate: true,
    title: __rootTc('usb_controller_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'usbControllerName',
        name: __rootTc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __rootTc('manufacturer_id'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'usbControllerDeviceStatus',
        name: __rootTc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'usbComponentStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'usbControllerDeviceStatusInfo',
        name: __rootTc('device_status_info'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'usbControllerDeviceId',
        name: __rootTc('device_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __rootTc('description'),
        inputType: 'text',
      },
    ],
  },
})
