import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const physicalDiskComponentFieldMap = () => ({
  physical_disk_component: {
    canCreate: true,
    title: __mtc('physical_disk_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'physicalDiskName',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'size',
        name: __mtc('size'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'installDate',
        name: __mtc('install_date'),
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'partitions',
        name: __mtc('partition'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'mediaType',
        name: __mtc('media_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'diskModel',
        name: __mtc('model'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'interfaceType',
        name: __mtc('interface_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'pnpDeviceId',
        name: __mtc('pnp_device_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
      {
        key: generateId(),
        paramName: 'storageDeviceType',
        name: __mtc('storage_device_type'),
        inputType: 'text',
      },
    ],
  },
})
