<script>
import Mark from 'mark.js'

export default {
  name: 'FlotoMarkedContent',
  props: {
    text: { type: String, default: '' },
  },
  watch: {
    text(newValue) {
      this.markInstance.unmark({
        done: () => {
          this.markInstance.mark(newValue)
        },
      })
    },
  },
  beforeDestroy() {
    this.markInstance.unmark()
  },
  mounted() {
    this.markInstance = new Mark(this.$el)
  },
  methods: {
    markText() {
      this.markInstance.unmark({
        done: () => {
          this.markInstance.mark(this.text)
        },
      })
    },
  },
  render() {
    return this.$scopedSlots.default()
  },
}
</script>
