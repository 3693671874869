import api from '@api'

import {
  // getModuleTranslator,
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
// import moduleConfig from './config'

import {
  transformCallHistoryForList,
  transformKpi,
  transformActiveAgentForList,
  transformWaitingCallForList,
  transformOnHoldCallForList,
} from '@data/contactops'
import { transformDashboard } from '@data/dashboard'
import { transformCallReminder, transformAbandonedCall } from '@data/call.js'
// const __t = getModuleTranslator(moduleConfig.translationKey)
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getCoOpsChannelStatusApi() {
  return api.get(`/telephony/channel/status`).then((data) => {
    return data
  })
}

export function startCoOpsChannelApi(data) {
  return api.post(`/telephony/channel/start`, data, {
    message: __rootT('started_successfully', {
      resource: __rootT('channel'),
    }),
  })
}

export function updateCoOpsChannelStatusApi(status, data) {
  return api.post(
    `/telephony/channel/changestatus`,
    data ? { breakReason: data.breakReason } : {},
    {
      params: {
        status,
      },
      message: __rootT('updated_successfully', {
        resource: `${__rootT('telephony')} ${__rootTc('status')}`,
      }),
    }
  )
}

export function getCallHistoryApi(qualifications, limit, offset, sortedColumn) {
  return api
    .post(
      '/contactOps/callhistory/search/byqual',
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformCallHistoryForList),
        total: data.totalCount,
      }
    })
}

export function getDisposalReasonsApi() {
  return api.get(`/telephony/disposalReasons`)
}

export function getBreakReasonsApi() {
  return api.get(`/telephony/breakreasons`)
}

export function getDashboardApi() {
  return api.get('/analytics/contactOps/dashboard').then(transformDashboard)
}

export function getKpiPreviewApi(filters) {
  return api
    .post(
      `/analytics/contactOps/kpi/preview`,
      {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      {
        notify: false,
      }
    )
    .then((data) => {
      let kpis = []
      data.objectList.map((data) => {
        return kpis.push(transformKpi(data.kpi, data.value))
      })
      return { kpis }
    })
}

const getTranferedDataList = (list, type) => {
  const d = (list || []).map((t) => {
    if (type === 'coops_recent_calls') {
      return transformCallHistoryForList(t)
    }
    if (type === 'coops_waiting_calls') {
      return transformWaitingCallForList(t)
    }
    if (type === 'coops_callback_reminder') {
      return transformCallReminder(t)
    }
    if (type === 'coops_onhold_calls') {
      return transformOnHoldCallForList(t)
    }
    if (type === 'coops_active_agents') {
      return transformActiveAgentForList(t)
    }
    if (type === 'coops_abandoned_calls') {
      return transformAbandonedCall(t)
    }
    throw new Error(`${type} is not supported`)
  })
  return {
    items: d,
    // to disable pagination
    total: 1000,
  }
}

export function getShortcutPreviewApi(type, limit, offset, updatedData = {}) {
  if (updatedData.type === type && (updatedData.items || []).length > 0) {
    return Promise.resolve({
      objectList: updatedData.items || [],
    }).then((data) => {
      return getTranferedDataList(data.objectList || [], type)
    })
  }
  return api
    .get('analytics/dashboard/shortcut/preview', {
      params: {
        offset: offset || 0,
        type,
        size: limit === undefined ? 1000 : limit,
      },
      notify: false,
    })
    .then((data) => {
      return getTranferedDataList(data.objectList || [], type)
    })
}

export function dialOutgoingCallApi(data) {
  return api.post(`/contactOps/dialOutgoingCall`, data)
}
export function addCallLogApi(data, reqId) {
  return api.post(`/contactOps/request/${reqId}/logCall`, data)
}

export function getRecordedFile(callId) {
  return api.get(`/contactOps/recordingFile`, {
    responseType: 'blob',
    params: {
      callId,
    },
  })
}

export function pickUpWaitingCallApi(data) {
  return api.post('/contactOps/pickUpWaitingCall', data, {
    message: __rootT('added_successfully', {
      resource: __rootTc('remark'),
    }),
  })
}
