import api from '@api'
import SortBy from 'lodash/sortBy'
import Constants from '@constants'
import {
  transformControl,
  transformForm,
  transformSystemFieldSupportedData,
} from '@data/form'
import {
  transformDependentFieldOptionsRecursive,
  transformDependentFieldOptions,
} from '@data/dependent-field-options'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getFormApi(
  moduleName,
  id,
  additionalParams = {},
  isPublic = false
) {
  return api
    .get(
      `${isPublic ? '/public' : ''}/module/${moduleName}${
        id ? `/${id}` : ''
      }/form`,
      {
        params: {
          ...additionalParams,
        },
      }
    )
    .then((response) => {
      const f = transformForm(response)
      let fields = f.fields
      if (moduleName === Constants.FEEDBACK) {
        fields = fields.map((f) => ({ ...f, canEditName: true }))
      }
      return { ...f, fields }
    })
}

export function getAvailableControlsApi(moduleName) {
  return api
    .get(`${moduleName}/field/supportedData`)
    .then((data) =>
      (data.objectList || []).map((control) => transformControl(control))
    )
}

export function updateFormFieldsApi(data, showMessage = true) {
  return api.patch(`/field/bulk/update`, data, {
    notify: showMessage,
    message: __rootT('updated_successfully_multiple', {
      resource: __rootTc('field', 3),
    }),
  })
}

export function updateFormFieldApi(field) {
  return api.patch(`/field/${field.id}`, field, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('field', 1),
    }),
  })
}

export function addFormFieldApi(formId, field, notify = true) {
  return api.post(`${formId}/field`, field, {
    message: __rootT('added_successfully', {
      resource: __rootTc('field', 1),
    }),
    notify,
  })
}

export function removeFormFieldApi(field, notify = true) {
  return api.delete(`/field/${field.id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('field', 1),
    }),
    notify,
  })
}

export function apiFieldTestConnectionApi(url, moduleName = 'request') {
  return api.post(
    `/apifield/${moduleName}/testconnection`,
    {
      url,
    },
    {
      notify: false,
    }
  )
}

export function getDependentValuesApi(fieldId) {
  return api
    .get(`${fieldId}/dependentvalue`, {
      loader: false,
      notify: false,
    })
    .then((data) => {
      return transformDependentFieldOptionsRecursive(
        SortBy(data, (d) => d.node.objOrder)
      )
    })
}

export function createDependentValuesApi(fieldId, data) {
  return api
    .post(`${fieldId}/dependentvalue/bulk/create`, data, {
      message: __rootT('added_successfully', {
        resource: __rootTc('option', 2),
      }),
    })
    .then((data) => (data.objectList || []).map(transformDependentFieldOptions))
}

export function removeDependentValueApi(fieldId, data) {
  return api.delete(`${fieldId}/dependentvalue/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc('option'),
    }),
  })
}

export function updateDependentValueApi(fieldId, data) {
  const updateData = {
    name: data.name,
  }
  return api
    .patch(`${fieldId}/dependentvalue/${data.id}`, updateData, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('option'),
      }),
    })
    .then(transformDependentFieldOptions)
}

export function updateDependentValuesApi(fieldId, data) {
  const patchData = {}
  data.map((c, index) => {
    patchData[c.id] = { name: c.name, objOrder: index + 1 }
  })
  return api.patch(`${fieldId}/dependentvalue/bulk/update`, patchData, {
    message: __rootT('updated_successfully', {
      resource: __rootTc('option', 2),
    }),
  })
}

export function getDependentValueApi(fieldId, id) {
  return api
    .get(`${fieldId}/dependentvalue/${id}`)
    .then(transformDependentFieldOptions)
}

export function getSystemFieldSupportedDataApi(moduleName) {
  return api.get(`/supporteddata/systemfield/${moduleName}`).then((data) => {
    return (data || []).map((supportedData) =>
      transformSystemFieldSupportedData(supportedData)
    )
  })
}
export function getCustomFieldSupportedDataApi(moduleName, fieldType) {
  return api
    .get(`${moduleName}/supporteddata/customfield/${fieldType}`)
    .then((data) => {
      return data.map((i) => ({
        text: i.displayName,
        key: i.fieldReferenceType,
      }))
    })
}
