<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'AutoAssignmentModule',
  page() {
    return {
      title: this.$t('auto_assignment'),
    }
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  created() {
    // if user doesn't have permission to handle admin automation section
    if (this.myAllowedModules.indexOf('admin.automation') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
}
</script>
