<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem :label="field.title" :rules="validationsRules">
      <FlotoAttachment
        v-model="currentValue"
        :button-text="field.attributes.buttonText"
        v-bind="attrs"
        :preview="disabled || isDisable || mode === 'builder'"
        :disabled="disabled || isDisable || mode === 'builder'"
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Attachment',
  mixins: [FormField],
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
      }
      return rules
    },
  },
}
</script>
