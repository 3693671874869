<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="field.inputType === 'description'"
      :label="field.title"
      :rules="validationsRules"
    >
      <FlotoRichEditor
        v-model="currentValue"
        v-bind="attrs"
        :rows="8"
        :affix-toolbar="false"
        :control-preview="disabled || mode === 'builder' || isDisable"
        :read-only="isDisable"
        :disabled="isDisable"
        v-on="listeners"
      />
    </FlotoFormItem>
    <FlotoFormItem
      v-else-if="inputComponent !== 'MInput'"
      :label="field.title"
      :rules="validationsRules"
    >
      <component
        :is="inputComponent"
        v-model="currentValue"
        :class="{
          block: isDropdownField,
          'w-full': !isDropdownField || field.attributes.widthClass === 'w-1/2',
          'w-1/2': isDropdownField && field.attributes.widthClass === 'w-full',
          'pr-2': isDropdownField && field.attributes.widthClass === 'w-full',
        }"
        as-input
        :allow-clear="allowClear"
        :mandatory="!allowClear && mode !== 'builder'"
        :preview="disabled || isDisable || mode === 'builder'"
        v-bind="attrs"
        :disabled="disabled || mode === 'builder' || isDisable"
        mandatory-selection
        :hidden-options-keys="hiddenOptionsKeys"
        :visible-options-keys="visibleOptionsKeys"
        :filter-data="filterData"
        :time-unit="
          field.inputType === 'notify_before'
            ? formValue.notifyBeforeHoursTimeUnit || 'hours'
            : field.inputType === 'estimated_time'
            ? formValue.estimatedTimeUnit || 'hours'
            : undefined
        "
        @update:timeUnit="handleTimeUnitSync"
        v-on="listeners"
      />
      <a
        v-if="field.inputType === 'template' && currentValue"
        @click="handleResetTemplate"
      >
        <MIcon name="undo" /> {{ $t('reset_to_default') }}
      </a>
    </FlotoFormItem>
    <FlotoFormItem
      v-else
      v-model="currentValue"
      :type="field.inputType === 'description' ? 'textarea' : undefined"
      :label="field.title"
      :rules="validationsRules"
      v-bind="attrs"
      :disabled="disabled || mode === 'builder' || isDisable"
      v-on="listeners"
    />
  </FieldContainer>
</template>

<script>
import Moment from 'moment'
import { authComputed } from '@state/modules/auth'
import ReleaseRiskTypePicker from '@components/data-picker/release-risk-type-picker'
import ReleaseTypePicker from '@components/data-picker/release-type-picker'
import ReleaseReasonTypePicker from '@components/data-picker/release-reason-type-picker'
import FormField from '../../mixins/form-field'
import ProjectRiskTypePicker from '@components/data-picker/project-risk-type-picker'
import CompanyPicker from '@components/data-picker/company-picker'
import VendorPicker from '@components/data-picker/vendor-picker'
import SliderPicker from '@components/numeric-slider/slider-picker'
import { getValidationRulesForEstimatedTime } from '@components/task/utils'

export default {
  name: 'System',
  components: {
    ReleaseRiskTypePicker,
    ReleaseTypePicker,
    ReleaseReasonTypePicker,
    ProjectRiskTypePicker,
    CompanyPicker,
    VendorPicker,
    SliderPicker,
  },
  mixins: [FormField],
  props: {
    formValue: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    ...authComputed,
    componentMap() {
      return {
        technician_group: 'FlotoTechnicianGroupPicker',
        department: 'FlotoDepartmentPicker',
        template: 'FlotoTemplatePicker',
        priority: 'FlotoPriorityPicker',
        impact: 'FlotoImpactPicker',
        urgency: 'FlotoUrgencyPicker',
        location: 'FlotoLocationPicker',
        tags: 'FlotoTagsPicker',
        ccemail: 'FlotoTagsPicker',
        category: 'FlotoCategoryPicker',
        description: 'FlotoRichEditor',
        display_name: 'MInput',
        subject: 'MInput',
        // refactor requester_email to requester
        requester: 'FlotoRequesterEmail',
        requesterid: 'FlotoRequesterPicker',
        name: 'MInput',
        status: 'FlotoStatusPicker',
        attachment: 'FlotoAttachment',
        technician: 'FlotoTechnicianPicker',
        technicianid: 'FlotoTechnicianPicker',
        due_by: 'FlotoDatePicker',
        rating: 'MRate',
        risk_type:
          this.attrs['module-name'] === this.$constants.RELEASE
            ? 'ReleaseRiskTypePicker'
            : this.attrs['module-name'] === this.$constants.PROJECT
            ? 'ProjectRiskTypePicker'
            : 'FlotoRiskTypePicker',
        change_type: 'FlotoChangeTypePicker',
        project_type: 'FlotoProjectTypePicker',
        release_type: 'ReleaseTypePicker',
        reason_type:
          this.attrs['module-name'] === this.$constants.RELEASE
            ? 'ReleaseReasonTypePicker'
            : 'FlotoReasonTypePicker',
        target_environment: 'FlotoTargetEnvironmentPicker',
        start_date: 'FlotoDatePicker',
        start_time: 'FlotoDatePicker',
        end_date: 'FlotoDatePicker',
        end_time: 'FlotoDatePicker',
        known_error: 'FlotoDropdownPicker',
        nature_of_problem: 'FlotoDropdownPicker',
        affected_services: 'FlotoBusinessServicePicker',
        company: 'CompanyPicker',
        transition_model: 'FlotoTransitionModelPicker',
        vendor: 'VendorPicker',
        task_type: 'FlotoTaskTypePicker',
        notify_before: 'FlotoEstimatedTimePicker',
        estimated_time: 'FlotoEstimatedTimePicker',
        task_completion: 'SliderPicker',
        task_duration: 'MInput',
      }
    },
    allowClear() {
      return (
        [
          'template',
          'priority',
          'impact',
          'urgency',
          'status',
          'known_error',
          'risk_type',
          'task_type',
        ].indexOf(this.field.inputType) === -1
      )
    },
    isDropdownField() {
      return (this.inputComponent || '').indexOf('Picker') >= 0
    },
    inputComponent() {
      return this.componentMap[this.field.inputType] || ''
    },
    inputType() {
      if (this.field.inputType === 'description') {
        return 'textarea'
      }
      if (this.field.inputType === 'subject') {
        return 'text'
      }
      if (this.field.inputType === 'email') {
        return 'text'
      }
      if (this.field.inputType === 'display_name') {
        return 'text'
      }
      if (this.field.inputType === 'task_duration') {
        return 'text'
      }
      return undefined
    },
    attrs() {
      const { type, ...attrs } = this.$attrs
      if (this.field.inputType === 'attachment') {
        attrs.buttonText = this.field.attributes.buttonText
      }
      if (this.field.inputType === 'technician') {
        attrs.changeTechnicianOnGroupChange = true
      }
      if (this.field.inputType === 'technicianid') {
        attrs['group-id'] = 0
      }
      if (
        ['template', 'vendor', 'transition_model'].indexOf(
          this.field.inputType
        ) >= 0
      ) {
        attrs['validate-archived-value'] = false
      }
      // if (this.field.inputType === 'subject' && this.isPortalLogin) {
      //   attrs.autoFocus = true
      // }
      if (this.field.inputType === 'rating') {
        attrs.allowHalf = !!this.field.attributes.allowHalf
      }
      if (this.field.inputType === 'due_by') {
        attrs.minDate = Moment().valueOf()
      }
      if (
        [this.$constants.CHANGE, this.$constants.RELEASE].indexOf(
          attrs['module-name']
        ) >= 0 &&
        this.field.inputType === 'start_date'
      ) {
        attrs.maxDate = this.formValue.endDate
      }
      if (
        [this.$constants.CHANGE, this.$constants.RELEASE].indexOf(
          attrs['module-name']
        ) >= 0 &&
        this.field.inputType === 'end_date'
      ) {
        attrs.minDate = this.formValue.startDate
      }
      if (this.field.inputType === 'ccemail') {
        attrs.type = 'email'
      }
      // refactor requester_email to requester
      if (this.field.inputType === 'requester') {
        attrs.showHint =
          this.field.attributes.showHint === undefined
            ? true
            : this.field.attributes.showHint
        // @TODO remove autofocus for value mapping rendering in create request
        // attrs.autoFocus = !this.isPortalLogin
      }
      if (this.field.inputType === 'nature_of_problem') {
        attrs.options = [
          { text: this.$t('proactive'), key: 'proactive' },
          { text: this.$t('reactive'), key: 'reactive' },
        ]
        attrs.asInput = true
      }
      if (this.field.inputType === 'known_error') {
        attrs.options = [
          { text: this.$t('yes'), key: 'true' },
          { text: this.$t('no'), key: 'false' },
        ]
        attrs.asInput = true
      }
      if (this.field.inputType === 'affected_services') {
        attrs.multiple = true
      }
      if (
        ['category', 'department', 'location'].indexOf(this.field.inputType) >=
        0
      ) {
        attrs.fullPath = true
      }
      if (this.applyFormRules && this.field.inputType === 'requesterid') {
        attrs.fullObject = true
        attrs['validate-archived-value'] = false
      }
      if (this.field.inputType === 'task_completion') {
        attrs.min = 0
        attrs.max = 100
      }
      if (
        ['notify_before', 'estimated_time'].indexOf(this.field.inputType) >= 0
      ) {
        attrs['allowed-units'] = ['hours', 'minutes', 'days']
      }
      return attrs
    },
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      let rules = {
        required: this.isRequired || false,
      }
      if (this.field.inputType === 'requester') {
        rules.short_text = true
      }
      if (this.field.inputType === 'description') {
        rules.description = true
      }
      if (this.field.inputType === 'subject') {
        rules.subject = true
      }
      if (this.field.inputType === 'task_duration') {
        rules.duration_days = true
        rules.min_value = 0
        rules.max_value = true
      }
      if (this.field.inputType === 'task_completion') {
        rules.min_value = 0
        rules.max_value = 100
      }
      if (this.field.inputType === 'notify_before') {
        rules = {
          ...rules,
          ...getValidationRulesForEstimatedTime(
            this.formValue.notifyBeforeHoursTimeUnit
          ),
        }
      }
      if (this.field.inputType === 'estimated_time') {
        rules = {
          ...rules,
          ...getValidationRulesForEstimatedTime(
            this.formValue.estimatedTimeUnit
          ),
        }
      }
      if (
        this.field.inputType === 'rating' ||
        (this.field.inputType === 'risk_type' && this.isRequired) ||
        (this.field.inputType === 'change_type' && this.isRequired) ||
        (this.field.inputType === 'project_type' && this.isRequired) ||
        (this.field.inputType === 'reason_type' && this.isRequired) ||
        (this.field.inputType === 'release_type' && this.isRequired) ||
        (this.field.inputType === 'category' && this.isRequired) ||
        (this.field.inputType === 'department' && this.isRequired) ||
        (this.field.inputType === 'location' && this.isRequired) ||
        (this.field.inputType === 'technician' && this.isRequired) ||
        (this.field.inputType === 'technician_group' && this.isRequired) ||
        (this.field.inputType === 'target_environment' && this.isRequired) ||
        (this.field.inputType === 'company' && this.isRequired) ||
        (this.field.inputType === 'requesterid' && this.isRequired) ||
        (this.field.inputType === 'vendor' && this.isRequired) ||
        (this.field.inputType === 'notify_before' && this.isRequired) ||
        (this.field.inputType === 'task_completion' && this.isRequired) ||
        (this.field.inputType === 'start_time' && this.isRequired) ||
        (this.field.inputType === 'end_time' && this.isRequired)
      ) {
        rules.nonzero = true
      }
      if (this.field.inputType === 'task_duration' && this.isRequired) {
        rules.nonzero_string = true
      }
      if (
        this.field.inputType === 'display_name' &&
        this.field.moduleName === this.$constants.PROJECT
      ) {
        rules.short_text = true
      }
      return rules
    },
  },
  watch: {
    // only for task module
    currentValue() {
      if (
        ['notify_before', 'estimated_time'].indexOf(this.field.inputType) >= 0
      ) {
        if (
          !this.formValue.notifyBeforeHoursTimeUnit &&
          this.field.inputType === 'notify_before'
        ) {
          this.handleTimeUnitSync('hours')
        }
        if (
          !this.formValue.estimatedTimeUnit &&
          this.field.inputType === 'estimated_time'
        ) {
          this.handleTimeUnitSync('hours')
        }
      }
    },
  },
  methods: {
    handleResetTemplate() {
      this.$emit('reset-form')
    },
    handleTimeUnitSync($event) {
      if (this.field.inputType === 'notify_before') {
        return (this.formValue.notifyBeforeHoursTimeUnit = $event)
      }
      if (this.field.inputType === 'estimated_time') {
        return (this.formValue.estimatedTimeUnit = $event)
      }
    },
  },
}
</script>
