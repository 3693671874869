import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const upsProperyFieldMap = () => ({
  ups_property_group: {
    title: __mtc('ups_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'modelName',
        name: `${__mtc('model_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'batteryVoltage',
        name: `${__mtc('battery')} ${__mtc('voltage')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'batteryCurrent',
        name: `${__mtc('battery')} ${__mtc('current')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'batteryCapacity',
        name: `${__mtc('battery')} ${__mtc('capacity')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'capacity',
        name: `${__mtc('capacity')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'voltage',
        name: `${__mtc('voltage')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'power',
        name: `${__mtc('power')}`,
        inputType: 'text',
      },
    ],
  },
})
