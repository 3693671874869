import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: ':moduleName',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "change-model" */ './views/list')
          ),
        meta: {
          breadcrumbKey: 'transition_model',
        },
      },
      {
        path: ':moduleName/create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "change-model" */ './views/create')
          ),
        meta: {
          breadcrumbKey: 'transition_model',
        },
      },
      {
        path: ':moduleName/edit/:id',
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "change-model" */ './views/edit')
          ),
        meta: {
          breadcrumbKey: 'transition_model',
        },
      },
    ],
  },
]
