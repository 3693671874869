<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'AdminModule',
  page() {
    return {
      title: this.$t('admin'),
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (vm.myAllowedModules.indexOf('admin') === -1) {
        return vm.$router.replace({ name: '404' })
      }
      return true
    })
  },
  computed: {
    ...PreferenceComputed,
  },
}
</script>
