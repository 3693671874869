import api from '@api'
import {
  transformSupportData,
  transformFormRuleForServer,
  transformFormRuleForList,
} from '@data/form-rules'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getSupportDataApi(model, id) {
  return api
    .get(`${model}${id ? `/${id}` : ''}/formrule/supportedData`)
    .then((data) => transformSupportData(model, data))
}

export function createFormRuleApi(moduleName, formrule) {
  return api
    .post(
      `${moduleName}${
        formrule.parentId ? `/${formrule.parentId}` : ''
      }/formrule`,
      transformFormRuleForServer(formrule),
      {
        message: __rootT('created_successfully', {
          resource: __rootTc('form_rule'),
        }),
      }
    )
    .then(transformFormRuleForList)
}

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getFormRulesApi(qualificationFactors, limit, offset, isPublic) {
  return api
    .post(
      `${isPublic ? '/public' : ''}/${qualificationFactors.moduleName}${
        qualificationFactors.parentId ? `/${qualificationFactors.parentId}` : ''
      }/formrule/search/byqual`,
      { qualDetails: buildQualifications(qualificationFactors) },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformFormRuleForList),
        total: data.totalCount,
      }
    })
}

export function updateFormRuleApi(data) {
  return api
    .patch(`formrule/${data.id}`, transformFormRuleForServer(data), {
      message: __rootT('saved_successfully', {
        resource: __rootTc('form_rule'),
      }),
    })
    .then(transformFormRuleForList)
}

export function bulkUpdateFormRuleApi(moduleName, items) {
  const payload = {}
  items.forEach((i) => {
    payload[i.id] = { objOrder: i.order }
  })
  return api.patch(`${moduleName}/formrule/bulk/update`, payload, {
    message: __rootT('saved_successfully', {
      resource: __rootTc('form_rule', 2),
    }),
  })
}

export function toggleEnableApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/formrule/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('form_rule'),
      }),
    })
    .then((data) => transformFormRuleForList(data))
}

export function deleteFormRuleApi(id) {
  return api.delete(`formrule/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('form_rule'),
    }),
  })
}
