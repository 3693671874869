<template>
  <FlotoCrudContainer
    as-table
    prepend-new-item
    :fetch-fn="getProductVendor"
    :delete-fn="deleteProductVendor"
    :create-fn="addProductVendor"
    :update-fn="updateProductVendor"
    :columns="tableColumns"
    :default-item="defaultItem"
    form-width="40%"
  >
    <template v-if="!disabled" v-slot:add-controls="{ create }">
      <MRow class="mb-4" :gutter="0">
        <MCol :size="12" class="text-right">
          <MButton variant="primary" @click="create">
            {{ $t('link') }} {{ $tc(type) }}
          </MButton>
        </MCol>
      </MRow>
    </template>
    <template v-slot:form-header="{ item }">
      {{ $t(item.id ? 'edit' : 'link') }} {{ $tc(type) }}
    </template>
    <template v-slot:form-items="{ item: vendorCatalog }">
      <ProductVendorForm :form-data="vendorCatalog" :type="type" />
    </template>
    <template v-slot:form-actions="{ submit, cancel, item, processing }">
      <MButton
        id="update-add-btn"
        class="mx-1"
        :loading="processing"
        @click="submit"
      >
        {{ $t(item.id ? 'update' : 'add') }}
      </MButton>
      <MButton id="cancel-btn" variant="default" @click="cancel">
        {{ $t('cancel') }}
      </MButton>
    </template>
    <template v-slot:productId="{ item, edit }">
      <td class="resource-link">
        <a :disabled="disabled" @click="edit">
          <ProductPicker
            disabled
            :value="item.productId"
            text-only
            placeholder="---"
          />
        </a>
      </td>
    </template>
    <template v-slot:vendorId="{ item, edit }">
      <td class="resource-link">
        <a :disabled="disabled" @click="edit">
          <VendorPicker
            disabled
            :value="item.vendorId"
            text-only
            placeholder="---"
          />
        </a>
      </td>
    </template>
    <template v-slot:price="{ item }">
      <td v-if="item.price">
        {{ item.price }}
      </td>
    </template>
    <template v-slot:taxRate="{ item }">
      <td v-if="item.taxRate">
        {{ item.taxRate }}
      </td>
    </template>
    <template v-slot:maintenanceVendorId="{ item, edit }">
      <td class="resource-link">
        <a :disabled="disabled" @click="edit">
          <VendorPicker
            disabled
            :value="item.maintenanceVendorId"
            text-only
            placeholder="---"
          />
        </a>
      </td>
    </template>
    <template v-slot:email="{ item, edit }">
      <td class="resource-link">
        <a :disabled="disabled" @click="edit"> {{ item.email }}</a>
      </td>
    </template>
    <template v-slot:description="{ item }">
      <td class="text-ellipsis">
        {{ item.description }}
      </td>
    </template>
    <template v-slot:actions="{ item, remove, edit }">
      <td class="text-ellipsis">
        <MTooltip>
          <template v-slot:trigger>
            <MIcon
              :class="{ invisible: !item.canEdit }"
              name="pencil"
              class="cursor-pointer text-primary"
              @click="edit"
            />
          </template>
          {{ $tc('edit') }}
        </MTooltip>
        <FlotoDeleteBtn
          class="mx-2"
          :class="{ invisible: !item.canDelete }"
          :message="$t('confirm_delete_item', { item: $tc(type) })"
          @confirm="remove"
        />
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import VendorPicker from '@components/data-picker/vendor-picker'
import ProductPicker from '@components/data-picker/product-picker'
import ProductVendorForm from './product-vendor-form'
import {
  getProductVendorsApi,
  deleteProductVendorApi,
  updateProductVendorApi,
  createProductVendorApi,
} from '../../api/product-vendor-api'
export default {
  name: 'ProductVendorList',
  components: { ProductVendorForm, VendorPicker, ProductPicker },
  props: {
    type: { type: String, required: true },
    productId: { type: Number, default: undefined },
    vendorId: { type: Number, default: undefined },
    defaultItem: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      tableColumns: [
        { name: `${this.$tc(this.type)}`, key: `${this.type}Id` },
        { name: `${this.$t('price')}`, key: 'price' },
        { name: `${this.$t('tax_rate')} (%)`, key: 'taxRate' },
        {
          name: `${this.$tc('maintenance_vendor')}`,
          key: 'maintenanceVendorId',
        },
        {
          name: `${this.$t('description')}`,
          key: 'description',
        },
        ...(this.disabled
          ? []
          : [
              {
                name: `${this.$tc('action', 2)}`,
                key: 'actions',
                width: '120px',
              },
            ]),
      ],
    }
  },
  methods: {
    getProductVendor(limit, offset) {
      return getProductVendorsApi(
        { productId: this.productId, vendorId: this.vendorId },
        limit,
        offset
      )
    },
    deleteProductVendor({ id }) {
      return deleteProductVendorApi(id, this.type)
    },
    updateProductVendor(data) {
      return updateProductVendorApi(data, this.type)
    },
    addProductVendor(data) {
      const requestData = { ...data, enabled: true }
      return createProductVendorApi(requestData, this.type)
    },
  },
}
</script>
