import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const ramComponentFieldMap = () => ({
  ram_component: {
    canCreate: true,
    title: __mtc('ram_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'size',
        name: __mtc('size'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'memoryType',
        name: __mtc('memory_type'),
        inputType: 'dropdown',
        contextOptionKey: 'memoryTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'width',
        name: __mtc('width'),
        inputType: 'number',
        unit: 'bit',
      },
      {
        key: generateId(),
        paramName: 'clockSpeed',
        name: __mtc('clock_speed'),
        inputType: 'hertz',
        unit: 'mhz',
      },
      {
        key: generateId(),
        paramName: 'bankLocater',
        name: __mtc('banklocater'),
        inputType: 'text',
      },
    ],
  },
})
