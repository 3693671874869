<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="disabled"
      :label="field.title"
      :rules="validationsRules"
      v-bind="attrs"
      v-on="listeners"
    >
      {{
        currentValue && currentValue.length > 0
          ? currentValue.join(', ')
          : '---'
      }}
    </FlotoFormItem>
    <FlotoFormItem v-else :label="field.title" :rules="validationsRules">
      <MCheckboxGroup
        v-model="currentValue"
        v-bind="attrs"
        :name="field.name"
        :disabled="disabled || mode === 'builder' || isDisable"
        :read-only="readOnly"
        :class="{
          flex: field.attributes.displayOptionVertically,
          'flex-col': field.attributes.displayOptionVertically,
          'text-ellipsis min-w-0 flex': true,
          'flex-wrap': !field.attributes.displayOptionVertically,
        }"
        @change="handleChange"
        v-on="listeners"
      >
        <MCheckbox
          v-for="option in checkboxOptions"
          :key="option"
          :value="option"
          :class="{
            'vertical-checkbox': Boolean(
              field.attributes.displayOptionVertically
            ),
            'text-ellipsis flex': true,
          }"
          class="ml-4"
        >
          <MRow :size="6">
            <span :title="option" class="text-ellipsis mx-4">
              {{ option }}
            </span>
          </MRow>
        </MCheckbox>
      </MCheckboxGroup>
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Checkbox',
  mixins: [FormField],
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(newValue) {
        // remove archived option value from checkbox custom field
        const changes = newValue.filter(
          (v) => this.field.options.indexOf(v) >= 0
        )
        if (this.mode === 'builder') {
          return false
        }
        this.$emit('update-value', changes)
      },
    },
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
      }
      return rules
    },
    checkboxOptions() {
      let options = this.field.options
      if ((this.hiddenOptionsKeys || []).length) {
        options = this.field.options.filter(
          (o) => this.hiddenOptionsKeys.indexOf(o) === -1
        )
      }
      if ((this.visibleOptionsKeys || []).length) {
        options = this.field.options.filter(
          (o) => this.visibleOptionsKeys.indexOf(o) >= 0
        )
      }
      return options
    },
  },
  created() {
    if (this.value) {
      const changes = this.value.filter(
        (v) => this.field.options.indexOf(v) >= 0
      )
      this.$emit('update-value', changes)
    }
  },
  methods: {
    handleChange() {
      this.$nextTick(() => {
        this.$emit('field-blur')
      })
    },
  },
}
</script>
