<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
export default {
  name: 'CalendarModule',
  page() {
    return {
      title: this.$tc('calendar'),
    }
  },
}
</script>
