import api from '@api'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
  transformAvailableQualification,
} from '@data/qualification'
import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@utils/get-module-translator'
import {
  transformBaseline,
  transformBaselineForServer,
  transformScheduleForServer,
  transformSchedule,
  transformAvailableColumn,
} from '@data/baseline'
import { transformAssetForList } from '@data/asset'

import moduleConfig from './config'
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()
const __moduleT = getModuleTranslator(moduleConfig.translationKey)

const getQualifications = (filters) => {
  const qualDetails = []

  if ('status' in filters && filters.status) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'status',
        'equal',
        filters.status,
        'enum',
        'db'
      )
    )
  }

  return qualDetails
}

export function getManageBaselineApi(
  filters,
  searchCriteria,
  limit,
  offset,
  sortedColumn
) {
  const qualDetails = [
    ...((searchCriteria || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/baseline/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformBaseline),
        total: data.totalCount,
      }
    })
}
export function createManageBaselineApi(data) {
  return api
    .post(`/baseline`, transformBaselineForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('baseline'),
      }),
    })
    .then(transformBaseline)
}

export function getBaselineApi(id) {
  return api.get(`/baseline/${id}`).then(transformBaseline)
}

export function updateManageBaselineApi(data) {
  return api
    .patch(`/baseline/${data.id}`, transformBaselineForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('baseline'),
      }),
    })
    .then(transformBaseline)
}
export function deleteManageBaselineApi({ id }) {
  return api.delete(`/baseline/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('baseline'),
    }),
  })
}

export function runAutoAssignmentProcessApi(id) {
  return api.get(`/baseline/autoassignment/${id}`, {
    message: __rootT('initiated_successfully'),
  })
}

export function getScheduleApi(id) {
  return api.get(`/varinaceConfig`).then(transformSchedule)
}

export function createScheduleApi(data) {
  return api
    .patch(`/varinaceConfig`, transformScheduleForServer(data), {
      message: __rootT('updated_successfully', {
        resource: `${__moduleT('variance_detection')} ${__rootTc('setting')}`,
      }),
    })
    .then(transformSchedule)
}

export function getSupportedDataApi() {
  return api.get('/baseline/supportedData').then((data) => {
    return {
      items: (data.objectList || []).map(transformAvailableColumn),
      total: data.totalCount,
    }
  })
}

export function getSupportedQualApi() {
  return api.get('/baseline/supportedqual').then((data) => {
    return {
      qualifications: (data.objectList || []).map(
        transformAvailableQualification
      ),
    }
  })
}

export function bulkUpdateBaselineApi(items) {
  const payload = {}
  items.forEach((i) => {
    payload[i.id] = { objOrder: i.order }
  })
  return api.patch(`/baseline/bulk/update`, payload, {
    message: __rootT('saved_successfully', {
      resource: __rootTc('baseline'),
    }),
  })
}
// excluded assets search bar for baseline create

export function searchExcludedAssetsForAutomationApi(
  filters,
  searchCriteria,
  assetTypeId,
  excludedSearchCriteria,
  limit,
  offset
) {
  const qualDetails = [
    ...((searchCriteria || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/baseline/excludeasset/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
        excludeQualDetails: excludedSearchCriteria || undefined,
        assetTypeId,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformAssetForList),
        total: data.totalCount,
      }
    })
}
