import { BrandingComputed } from '@state/modules/branding/index'
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'

export default {
  computed: {
    ...BrandingComputed,
    ...authComputed,
    ...PreferenceComputed,
    homeRoute() {
      if (this.isPortalLogin) {
        return this.$modules.getModuleRoute('support-portal')
      }
      return this.$modules.getModuleRoute('dashboard')
    },
    companyLogo() {
      if (this.theme === 'dark_theme') {
        return this.darkThemeLogo
      }
      return this.motadataCompanyLogo
    },
    linkbackRoute() {
      if (this.linkbackUrl) {
        return this.linkbackUrl
      }
      return undefined
    },
    guestUserLandingPageRoute() {
      if (this.loggedIn) {
        return this.$modules.getModuleRoute('support-portal')
      }
      if (this.guestUserLandingPage === 'login_page') {
        return this.$modules.getModuleRoute('auth', 'login')
      }
      return this.$modules.getModuleRoute('support-portal')
    },
  },
}
