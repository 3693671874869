import { getRootPluaralTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'

const __rootTc = getRootPluaralTranslator()

export const usbHubComponentFieldMap = () => ({
  usb_hub_component: {
    canCreate: true,
    title: __rootTc('usb_hub_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'usbHubName',
        name: __rootTc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'usbHubDeviceStatus',
        name: __rootTc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'usbComponentStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'usbHubDeviceStatusInfo',
        name: __rootTc('device_status_info'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'usbHubDeviceId',
        name: __rootTc('device_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __rootTc('description'),
        inputType: 'text',
      },
    ],
  },
})
