var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[(_vm.disabled)?_c('FlotoFormItem',_vm._g(_vm._b({staticStyle:{"width":"inherit"},attrs:{"label":_vm.label,"type":_vm.field.inputType,"rules":_vm.validationsRules}},'FlotoFormItem',_vm.attrs,false),_vm.listeners),[(_vm.field.type === 'textarea')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentValue.replace(/\n/g, '<br />'))}}):_c('span',[_vm._v(_vm._s(_vm.currentValue || '---'))])]):_c('FlotoFormItem',_vm._g(_vm._b({class:{
      'w-full':
        (_vm.field.inputType === 'number' || _vm.field.inputType === 'datetime') &&
        _vm.field.attributes.widthClass !== 'w-full',
      'w-1/4':
        (_vm.field.inputType === 'number' || _vm.field.inputType === 'datetime') &&
        _vm.field.attributes.widthClass === 'w-full',
    },attrs:{"disabled":_vm.mode === 'builder' || _vm.field.attributes.systemManagedField || _vm.isDisable,"label":_vm.label,"rules":_vm.validationsRules,"type":_vm.field.inputType},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'FlotoFormItem',_vm.attrs,false),_vm.listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }