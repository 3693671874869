import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'
const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const cloudPropertyGroupFieldMap = () => ({
  cloud_property_group: {
    title: __mtc('cloud_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'instanceId',
        name: `${__mtc('instance_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'region',
        name: `${__mtc('region')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'resourceGroupName',
        name: `${__mtc('resource_group_name')}`,
        inputType: 'text',
      },
    ],
  },
})
