import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const mobileDeviceNetworkComponentFieldMap = () => ({
  mobile_device_network_component: {
    canCreate: true,
    maxCreation: 1,
    title: __mtc('mobile_device_network_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'ipAddresse',
        name: __mtc('ip_address'),
        inputType: 'text',
        rules: {
          ip: true,
        },
      },
      {
        key: generateId(),
        paramName: 'bluetoothMac',
        name: __mtc('bluetooth_mac'),
        inputType: 'text',
        rules: {
          mac_address: true,
        },
      },
      {
        key: generateId(),
        paramName: 'wifiMac',
        name: `${__mtc('wifi_mac')}`,
        inputType: 'text',
        rules: {
          mac_address: true,
        },
      },
      {
        key: generateId(),
        paramName: 'dataRoamingEnabled',
        name: __mtc('data_roaming_enabled'),
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'voiceRoamingEnabled',
        name: __mtc('voice_roaming_enabled'),
        inputType: 'boolean',
      },
    ],
  },
})
