import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const rackProperyFieldMap = () => ({
  rack_property_group: {
    title: __mtc('rack_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'footPrint',
        name: `${__mtc('foot_print')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'powerConsumption',
        name: `${__mtc('power_consumption')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'rackUnits',
        name: `${__mtc('rack_units')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'rackUnitsInUse',
        name: `${__mtc('rack_units_in_use')}`,
        inputType: 'text',
      },
    ],
  },
})
