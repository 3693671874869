import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const monitorComponentFieldMap = () => ({
  monitor_component: {
    canCreate: true,
    title: __mtc('monitor_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'monitorType',
        name: __mtc('monitor_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'size',
        name: __mtc('size'),
        inputType: 'number',
        precision: 2,
      },
      {
        key: generateId(),
        paramName: 'deviceStatus',
        name: __mtc('device_status'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'serialNumber',
        name: __mtc('serial_number'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'installDate',
        name: __mtc('install_date'),
        inputType: 'date',
      },
      {
        key: generateId(),
        paramName: 'pnpDeviceId',
        name: __mtc('pnp_device_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'screenHeight',
        name: __mtc('screen_height'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'screenWidth',
        name: __mtc('screen_width'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'weekOfManufacture',
        name: __mtc('week_of_manufacture'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'yearOfManufacture',
        name: __mtc('year_of_manufacture'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
