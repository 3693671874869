import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { transformContract, transformContractForServer } from '@data/contract'

import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import { successToast } from '@motadata/ui'

const __rootT = getRootTranslator()

const __rootTc = getRootPluaralTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.displayName) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'contains',
        qualificationFactors.displayName,
        'string',
        'db'
      )
    )
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'in',
        qualificationFactors.selectedName,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.exludedIds) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        qualificationFactors.exludedIds,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.exludedParentIds) {
    quals.push(
      buildRelationalQualificationStructure(
        'parentId',
        'not_in',
        qualificationFactors.exludedParentIds,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.exludedRenewalParentIds) {
    quals.push(
      buildRelationalQualificationStructure(
        'renewalParentId',
        'not_in',
        qualificationFactors.exludedRenewalParentIds,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.parentId || qualificationFactors.parentId === 0) {
    quals.push(
      buildRelationalQualificationStructure(
        'parentId',
        'equal',
        qualificationFactors.parentId,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.contractStatus) {
    quals.push(
      buildRelationalQualificationStructure(
        'contractStatus',
        'in',
        qualificationFactors.contractStatus,
        'enum',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function searchContractsApi(
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/contract/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformContract),
        total: data.totalCount,
      }
    })
}

export function getContractsApi(filter, limit, offset, additionParmas = {}) {
  return api
    .post(
      `/contract/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.selectedName ||
          filter.ids ||
          filter.parentId ||
          filter.parentId === 0 ||
          filter.exludedIds ||
          filter.exludedParentIds ||
          filter.exludedRenewalParentIds ||
          filter.contractStatus
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionParmas,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformContract),
        total: data.totalCount,
      }
    })
}

export function getApi(id) {
  return api.get(`/contract/${id}`).then(transformContract)
}

export function createApi(data) {
  return api
    .post(`/contract`, transformContractForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('contract'),
      }),
    })
    .then(transformContract)
}

export function updateApi(data) {
  return api
    .patch(`/contract/${data.id}`, transformContractForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('contract'),
      }),
    })
    .then(transformContract)
}

export function getRenewalContractsApi(id, limit, offset) {
  return api
    .get(`/contract/renewcontract/${id}`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformContract),
        total: data.totalCount,
      }
    })
}

export function getChildContractsApi(id, limit, offset) {
  return api
    .get(`/contract/childcontract/${id}`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformContract),
        total: data.totalCount,
      }
    })
}

export function renewContractApi(data, id) {
  return api
    .post(`/contract/renewcontract/${id}`, transformContractForServer(data), {
      message: __rootT('renewd_successfully', {
        resource: __rootTc('contract'),
      }),
    })
    .then(transformContract)
}

export function deleteApi(id) {
  return api.delete(`/contract/${id}`, {
    message: __rootT('archived_successfully', {
      resource: __rootTc('contract'),
    }),
  })
}

export function bulkDeleteApi(ids) {
  return api
    .delete(`/contract/bulk/delete`, {
      data: { objectIds: ids },
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          __rootT('archived_successfully', {
            resource: __rootTc('contract', ids.length),
          })
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function bulkUnarchiveApi(ids) {
  return api.patch(
    `/contract/bulk/unarchive`,
    { objectIds: ids },
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('contract', ids.length),
      }),
    }
  )
}

export function bulkPermanentDeleteApi(ids) {
  return api.delete(`/contract/bulk/permanentdelete`, {
    data: { objectIds: ids },
    message: __rootT('deleted_successfully', {
      resource: __rootTc('contract', ids.length),
    }),
  })
}
