import {
  getRootPluaralTranslator,
  // getModuleTranslator,
} from '@utils/get-module-translator'
// import moduleConfig from '../config'

// property fieds
import { hardwareProperyFieldMap } from './property-fields/hardware-property-field-map'
import { nonitPropertyFieldMap } from './property-fields/nonit-property-field-map'
import { ComputerProperyFieldMap } from './property-fields/computer-property-field-map'
import { firewallProperyFieldMap } from './property-fields/firewall-property-field-map'
import { snmpProperyFieldMap } from './property-fields/snmp-property-field-map'
import { storageProperyFieldMap } from './property-fields/storage-property-field-map'
import { upsProperyFieldMap } from './property-fields/ups-property-field-map'
import { mobileDeviceProperyFieldMap } from './property-fields/mobile-device-property-field-map'
import { rackProperyFieldMap } from './property-fields/rack-property-field-map'
import { softwareProperyFieldMap } from './property-fields/software-property-field-map'
import { cloudPropertyGroupFieldMap } from './property-fields/cloud-property-group-field-map'
import { vmInstancePropertyGroupFieldMap } from './property-fields/vm-instance-property-group-field-map'
import { databasePropertyGroupFieldMap } from './property-fields/database-property-group-field-map'

// component fields
import { keyboardComponentFieldMap } from './component-fields/keyboard-component-field-map'
import { monitorComponentFieldMap } from './component-fields/monitor-component-field-map'
import { pointingDeviceComponentFieldMap } from './component-fields/pointing-device-component-field-map'
import { sharedFolderComponentFieldMap } from './component-fields/shared-folder-component-field-map'
import { usbHubComponentFieldMap } from './component-fields/usb-hub-component-field-map'
import { usbComponentFieldMap } from './component-fields/usb-component-field-map'
import { motherBoardComponentFieldMap } from './component-fields/motherboard-component-field-map'
import { physicalDiskComponentFieldMap } from './component-fields/physical-disk-component-field-map'
import { ramComponentFieldMap } from './component-fields/ram-component-field-map'
import { snmpInterfaceComponentFieldMap } from './component-fields/snmp-interface-component-field-map'
import { osComponentFieldMap } from './component-fields/os-component-field-map'
import { userComponentFieldMap } from './component-fields/user-component-field-map'
import { logicalDiskComponentFieldMap } from './component-fields/logical-disk-component-field-map'
import { networkAdapterComponentFieldMap } from './component-fields/network-adapter-component-field-map'
import { processorComponentFieldMap } from './component-fields/processor-component-field-map'
import { computerSystemComponentFieldMap } from './component-fields/computer-system-component-field-map'
import { softwareComponentFieldMap } from './component-fields/software-component-field-map'
import { biosComponentFieldMap } from './component-fields/bios-component-field-map'
import { mobileDeviceSystemComponentFieldMap } from './component-fields/mobile-device-system-component-field-map'
import { mobileDeviceOsComponentFieldMap } from './component-fields/mobile-device-os-component-field-map'
import { mobileDeviceNetworkComponentFieldMap } from './component-fields/mobile-device-network-component-field-map'
import { mobileDeviceSimComponentFieldMap } from './component-fields/mobile-device-sim-component-field-map'
import { mobileDeviceRamComponentFieldMap } from './component-fields/mobile-device-ram-component-field-map'
import { mobileDeviceExternalMemoryComponentFieldMap } from './component-fields/mobile-device-external-memory-component-field-map'
import { mobileApplicationComponentFieldMap } from './component-fields/mobile-application-component-field-map'
import { cloudInstanceDetailsComponentFieldMap } from './component-fields/cloud-instance-details-component-field-map'
import { cloudSecurityDetailsComponentFieldMap } from './component-fields/cloud-security-details-component-field-map'
import { cloudDatabseComponentFieldMap } from './component-fields/cloud-database-component-field-map'
import { storageBucketComponentFeildMap } from './component-fields/storage-bucket-component-field-map'

// const __mtc = getModuleTranslator(moduleConfig.translationKey)
const __rootTc = getRootPluaralTranslator()

export const SOFTWARE_COMPONENT = 'software_component'
export const USER_COMPONENT = 'user_component'
export const COMPUTER_PROPERTY_GROUP = 'computer_property_group'
export const MOBILE_DEVICE_PROPERTY_GROUP = 'mobile_device_property_group'
export const MOBILE_APPLICATION_COMPONENT = 'mobile_application_component'
export const STORAGE_BUCKET_COMPONENT = 'storage_bucket_component'
export const CLOUD_DATABASE_COMPONENT = 'cloud_database_component'

export const assetPropertyMap = () => ({
  computer_property_group: __rootTc('computer_property_group'),
  firewall_property_group: __rootTc('firewall_property_group'),
  hardware_property_group: __rootTc('hardware_property_group'),
  mobile_device_property_group: __rootTc('mobile_device_property_group'),
  rack_property_group: __rootTc('rack_property_group'),
  snmp_property_group: __rootTc('snmp_property_group'),
  software_property_group: __rootTc('software_property_group'),
  storage_property_group: __rootTc('storage_property_group'),
  ups_property_group: __rootTc('ups_property_group'),
  vmware_property_group: __rootTc('vmware_property_group'),
  ram_property_group: __rootTc('ram_property_group'),
  non_it_property_group: __rootTc('non_it_property_group'),
  cloud_property_group: __rootTc('cloud_property_group'),
  vm_instance_property_group: __rootTc('vm_instance_property_group'),
  database_property_group: __rootTc('database_property_group'),
})

export const assetComponentMap = () => ({
  os_component: __rootTc('os_component'),
  bios_component: __rootTc('bios_component'),
  computer_system_component: __rootTc('computer_system_component'),
  ram_component: __rootTc('ram_component'),
  processor_component: __rootTc('processor_component'),
  network_adapter_component: __rootTc('network_adapter_component'),
  motherboard_component: __rootTc('motherboard_component'),
  physical_disk_component: __rootTc('physical_disk_component'),
  logical_disk_component: __rootTc('logical_disk_component'),
  monitor_component: __rootTc('monitor_component'),
  keyboard_component: __rootTc('keyboard_component'),
  pointing_device_component: __rootTc('pointing_device_component'),
  snmp_interface_component: __rootTc('snmp_interface_component'),
  software_component: __rootTc('software_component'),
  user_component: __rootTc('user_component'),
  mobile_device_system_component: __rootTc('mobile_device_system_component'),
  mobile_device_os_component: __rootTc('mobile_device_os_component'),
  mobile_device_network_component: __rootTc('mobile_device_network_component'),
  mobile_device_sim_component: __rootTc('mobile_device_sim_component'),
  mobile_device_ram_component: __rootTc('mobile_device_ram_component'),
  mobile_device_external_memory_component: __rootTc(
    'mobile_device_external_memory_component'
  ),
  mobile_application_component: __rootTc('mobile_application_component'),
  shared_folder_component: __rootTc('shared_folder_component'),
  usb_hub_component: __rootTc('usb_hub_component'),
  usb_controller_component: __rootTc('usb_controller_component'),
  application_component: __rootTc('application_component'),
  cloud_database_component: __rootTc('cloud_database_component'),
  storage_bucket_component: __rootTc('storage_bucket_component'),
  cloud_instance_details_component: __rootTc(
    'cloud_instance_details_component'
  ),
})

export const assetComponentTypeMap = () => ({
  bios_component: 'BiosComponentRest',
  computer_system_component: 'ComputerSystemComponentRest',
  keyboard_component: 'KeyboardComponentRest',
  logical_disk_component: 'LogicalDiskComponentRest',
  monitor_component: 'MonitorComponentRest',
  motherboard_component: 'MotherboardComponentRest',
  network_adapter_component: 'NetworkAdapterComponentRest',
  os_component: 'OsComponentRest',
  physical_disk_component: 'PhysicalDiskComponentRest',
  pointing_device_component: 'PointingDeviceComponentRest',
  shared_folder_component: 'SharedFolderComponentRest',
  usb_hub_component: 'UsbHubComponentRest',
  usb_controller_component: 'UsbControllerComponentRest',
  processor_component: 'ProcessorComponentRest',
  ram_component: 'RamComponentRest',
  snmp_interface_component: 'SnmpInterfaceComponentRest',
  software_component: 'SoftwareComponentRest',
  user_component: 'UserComponentRest',
  mobile_device_system_component: 'MobileDeviceSystemComponentRest',
  mobile_device_os_component: 'MobileDeviceOSComponentRest',
  mobile_device_network_component: 'MobileDeviceNetworkComponentRest',
  mobile_device_sim_component: 'MobileDeviceSIMComponentRest',
  mobile_device_ram_component: 'MobileDeviceRamComponentRest',
  mobile_device_external_memory_component:
    'MobileDeviceExternalMemoryComponentRest',
  mobile_application_component: 'MobileApplicationComponentRest',
  application_component: 'ApplicationComponentRest',
  cloud_database_component: 'CloudDatabaseComponentRest',
  storage_bucket_component: 'StorageBucketComponentRest',
  cloud_instance_details_component: 'CloudInstanceDetailsComponentRest',
})

export const propertyComponentFieldMap = () => ({
  // properties
  ...hardwareProperyFieldMap(),
  ...nonitPropertyFieldMap(),
  ...ComputerProperyFieldMap(),
  ...firewallProperyFieldMap(),
  ...snmpProperyFieldMap(),
  ...storageProperyFieldMap(),
  ...upsProperyFieldMap(),
  ...mobileDeviceProperyFieldMap(),
  ...rackProperyFieldMap(),
  ...softwareProperyFieldMap(),
  ...cloudPropertyGroupFieldMap(),
  ...vmInstancePropertyGroupFieldMap(),
  ...databasePropertyGroupFieldMap(),
  // components
  ...keyboardComponentFieldMap(),
  ...monitorComponentFieldMap(),
  ...pointingDeviceComponentFieldMap(),
  ...sharedFolderComponentFieldMap(),
  ...usbHubComponentFieldMap(),
  ...usbComponentFieldMap(),
  ...motherBoardComponentFieldMap(),
  ...physicalDiskComponentFieldMap(),
  ...ramComponentFieldMap(),
  ...snmpInterfaceComponentFieldMap(),
  ...osComponentFieldMap(),
  ...userComponentFieldMap(),
  ...logicalDiskComponentFieldMap(),
  ...networkAdapterComponentFieldMap(),
  ...processorComponentFieldMap(),
  ...computerSystemComponentFieldMap(),
  ...softwareComponentFieldMap(),
  ...biosComponentFieldMap(),
  ...mobileDeviceSystemComponentFieldMap(),
  ...mobileDeviceOsComponentFieldMap(),
  ...mobileDeviceNetworkComponentFieldMap(),
  ...mobileDeviceSimComponentFieldMap(),
  ...mobileDeviceRamComponentFieldMap(),
  ...mobileDeviceExternalMemoryComponentFieldMap(),
  ...mobileApplicationComponentFieldMap(),
  ...cloudInstanceDetailsComponentFieldMap(),
  ...cloudSecurityDetailsComponentFieldMap(),
  ...cloudDatabseComponentFieldMap(),
  ...storageBucketComponentFeildMap(),
})
