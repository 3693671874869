import AuthModule from './auth/auth'
import DashboardModule from './dashboard/dashboard'
import TicketModule from './ticket/ticket'
import AssetModule from './asset/asset'
import CmdbModule from './cmdb/cmdb'
import AdminModule from './admin/admin'
import FormModule from './form/form'
import WorkflowModule from './workflow/workflow'
import CategoryModule from './category/category'
import SlaModule from './sla/sla'
import AutoAssignmentModule from './auto-assignment/auto-assignment'
import ScenarioModule from './scenario/scenario'
import StatusModule from './status/status'
import EmailNotificationModule from './email-notification/email-notification'
import EventNotificationsModule from './event-notifications/event-notifications'
import TemplatesModule from './templates/templates'
import BusinessHoursModule from './business-hours/business-hours'
import UserGroupsModule from './user-groups/user-groups'
import UsersModule from './users/users'
import RolesModule from './roles/roles'
import OrganizatonModule from './organization/organization'
import ITInfrastructureModule from './it-infrastructure/it-infrastructure'
import ResponseTemplateModule from './response-template/response-template'
import ApprovalModule from './approval/approval'
import ServiceCatalogModule from './service-catalog/service-catalog'
import MyApprovalModule from './my-approval/my-approval'
import MyTasksModule from './my-tasks/my-tasks'
import KnowledgeModule from './knowledge/knowledge'
import CustomRulesModule from './custom-rules/custom-rules'
import BusinessServiceModule from './business-service/business-service'
import SupportPortalModule from './support-portal/support-portal'
import SupportConsoleModule from './support-console/support-console'
import ReportsModule from './reports/reports'
import ChangeManagementModule from './change-management/change-management'
import TenantRegistrationModule from './tenant-registration/tenant-registration'
import RequestManagementModule from './request-management/request-management'
import AssetManagementModule from './asset-management/asset-management'
import AutoTicketCreateModule from './auto-ticket-create/auto-ticket-create'
import AutoTaskCreateModule from './auto-task-create/auto-task-create'
import ContractManagementModule from './contract-management/contract-management'
import IntegrationModule from './integration/integration'
import ContractsModule from './contracts/contracts'
import SoftwareLicense from './software-license/software-license'
import ReleaseManagementModule from './release-management/release-management'
import PurchaseManagementModule from './purchase-management/purchase-management'
import ChatManagerModule from './chat-manager/chat-manager'
import ContactOpsManagerModule from './contactOps-manager/contactOps-manager'
import PurchaseModule from './purchase/purchase'
import ImportCsvBatchModule from './import-csv-batch/import-csv-batch'
import UserSurveyModule from './user-survey/user-survey'
import UserSurveyResponseModule from './user-survey-response/user-survey-response'
import ContactopsModule from './contactops/contactops'
import ProjectManagementModule from './project-management/project-management'
import Project from './project/project'
import PatchModule from './patch/patch'
import PatchManagement from './patch-management/patch-management'
import ComputerModule from './computer/computer'
import RemoteDeploymentModule from './remote-deployment/remote-deployment'
import PackagesModule from './packages/packages'
import RegistryTemplateModule from './registry-template/registry-template'
import FormRulesModule from './form-rules/form-rules'
import SmartSuggestionsModule from './smart-suggestions/smart-suggestions'
import ProductSetupModule from './product-setup/product-setup'
import CalendarModule from './calendar/calendar'
import ChangeModelModule from './change-model/change-model'
import MyTeamModule from './my-team/my-team'
import PrintTemplateModule from './print-template/print-template'
import UserSessionsModule from './user-sessions/user-sessions'
import WorkflowDefinationModule from './workflow-definition/workflow-definition'

export default [
  new AuthModule(),
  new TenantRegistrationModule(),
  new DashboardModule(),
  new CustomRulesModule(),
  new AdminModule(),
  new FormModule(),
  new WorkflowModule(),
  new CategoryModule(),
  new SlaModule(),
  new AutoAssignmentModule(),
  new ScenarioModule(),
  new StatusModule(),
  new EmailNotificationModule(),
  new TemplatesModule(),
  new BusinessHoursModule(),
  new UserGroupsModule(),
  new EventNotificationsModule(),
  new UsersModule(),
  new RolesModule(),
  new OrganizatonModule(),
  new ITInfrastructureModule(),
  new ResponseTemplateModule(),
  new ApprovalModule(),
  new ServiceCatalogModule(),
  new MyApprovalModule(),
  new MyTasksModule(),
  new KnowledgeModule(),
  new BusinessServiceModule(),
  new TicketModule(),
  new AssetModule(),
  new CmdbModule(),
  new SupportPortalModule(),
  new SupportConsoleModule(),
  new ReportsModule(),
  new ChangeManagementModule(),
  new RequestManagementModule(),
  new AssetManagementModule(),
  new AutoTicketCreateModule(),
  new AutoTaskCreateModule(),
  new ContractManagementModule(),
  new IntegrationModule(),
  new ContractsModule(),
  new SoftwareLicense(),
  new ReleaseManagementModule(),
  new PurchaseManagementModule(),
  new ChatManagerModule(),
  new ContactOpsManagerModule(),
  new PurchaseModule(),
  new ImportCsvBatchModule(),
  new UserSurveyModule(),
  new UserSurveyResponseModule(),
  new ContactopsModule(),
  new ProjectManagementModule(),
  new Project(),
  new PatchModule(),
  new PatchManagement(),
  new ComputerModule(),
  new RemoteDeploymentModule(),
  new PackagesModule(),
  new RegistryTemplateModule(),
  new FormRulesModule(),
  new SmartSuggestionsModule(),
  new ProductSetupModule(),
  new CalendarModule(),
  new MyTeamModule(),
  new ChangeModelModule(),
  new PrintTemplateModule(),
  new UserSessionsModule(),
  new WorkflowDefinationModule(),
]
