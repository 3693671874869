<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      :label="label"
      :rules="field.required ? 'required' : undefined"
    >
      <BooleanDataPicker
        v-model="currentValue"
        :field="field"
        :disabled="
          mode === 'builder' || disabled || field.attributes.systemManagedField
        "
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'
import BooleanDataPicker from '../data-picker/boolean-data-picker'

export default {
  name: 'Boolean',
  components: { BooleanDataPicker },
  mixins: [FormField],
  model: {
    event: 'update-value',
  },
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
  },
}
</script>
