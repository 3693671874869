var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{attrs:{"as-table":"","prepend-new-item":"","fetch-fn":_vm.getProductCatalog,"delete-fn":_vm.deleteProductCatalog,"create-fn":_vm.addProductCatalog,"update-fn":_vm.updateProductCatalog,"columns":_vm.tableColumns},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var create = ref.create;
var refreshList = ref.refreshList;
return [_c('MRow',{staticClass:"mb-4",attrs:{"gutter":0}},[_c('MCol',{attrs:{"size":6}},[_c('MInput',{staticClass:"search-box",attrs:{"placeholder":_vm.$t('search_by_name')},on:{"enterKey":refreshList},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('MIcon',{attrs:{"name":"search"}})]},proxy:true}],null,true),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),(!_vm.disabled)?_c('MCol',{staticClass:"flex justify-end",attrs:{"size":6}},[_c('FlotoLink',{attrs:{"variant":"default","as-button":"","to":_vm.$modules.getModuleRoute('import-csv-batch', '', {
              params: {
                moduleName: _vm.$constants.PRODUCT,
              },
            })}},[_vm._v(" "+_vm._s(_vm.$tc('import'))+" "+_vm._s(_vm.$tc('product'))+" ")]),_c('FlotoLink',{staticClass:"mx-2",attrs:{"variant":"default","as-button":"","to":_vm.$modules.getModuleRoute('import-csv-batch', '', {
              params: {
                moduleName: _vm.$constants.PRODUCTVENDOR,
              },
            })}},[_vm._v(" "+_vm._s(_vm.$tc('import'))+" "+_vm._s(_vm.$tc('price'))+" "+_vm._s(_vm.$tc('list'))+" ")]),_c('MButton',{attrs:{"variant":"primary"},on:{"click":create}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('product'))+" ")])],1):_vm._e()],1)]}},{key:"form-header",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.id ? 'edit' : 'add'))+" "+_vm._s(_vm.$tc('product'))+" ")]}},{key:"form-items",fn:function(ref){
            var productCatalog = ref.item;
return [_c('ProductCatalogForm',{attrs:{"form-data":productCatalog}})]}},{key:"form-actions",fn:function(ref){
            var submit = ref.submit;
            var cancel = ref.cancel;
            var item = ref.item;
            var processing = ref.processing;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"update-add-btn","loading":processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t(item.id ? 'update' : 'add'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}},{key:"name",fn:function(ref){
            var item = ref.item;
            var edit = ref.edit;
return [_c('td',{staticClass:"resource-link"},[_c('a',{attrs:{"disabled":_vm.disabled},on:{"click":edit}},[_vm._v(" "+_vm._s(item.name))])])]}},{key:"displayName",fn:function(ref){
            var item = ref.item;
            var edit = ref.edit;
return [_c('td',{staticClass:"resource-link"},[_c('a',{attrs:{"disabled":_vm.disabled},on:{"click":edit}},[_vm._v(" "+_vm._s(item.displayName))])])]}},{key:"productType",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('ProductTypePicker',{attrs:{"text-only":"","placeholder":"---","value":item.productType,"disabled":""}})],1)]}},{key:"manufacturer",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_c('ManufacturerPicker',{key:item.manufacturer,attrs:{"text-only":"","placeholder":"---","value":item.manufacturer,"disabled":""}})],1)]}},{key:"description",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"actions",fn:function(ref){
            var item = ref.item;
            var remove = ref.remove;
            var edit = ref.edit;
return [_c('td',[_c('div',{staticClass:"flex justify-left items-center"},[_c('FlotoDrawer',{staticClass:"flex",attrs:{"open":_vm.currentSelectedItemId === item.id},on:{"hide":function($event){_vm.currentSelectedItemId = null}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(item.displayName)+": "+_vm._s(_vm.$t(_vm.disabled ? 'view' : 'manage'))+" "+_vm._s(_vm.$tc('vendor', 2))+" ")]},proxy:true},{key:"trigger",fn:function(){return [_c('MButton',{attrs:{"id":"manage-vendor-btn","variant":"default","size":"small"},on:{"click":function($event){_vm.currentSelectedItemId = item.id}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.disabled ? 'view' : 'manage'))+" "+_vm._s(_vm.$tc('vendor', 2))+" ")])]},proxy:true},{key:"actions",fn:function(ref){
            var hide = ref.hide;
return [_c('MButton',{attrs:{"id":"done-btn","variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('done'))+" ")])]}}],null,true)},[_c('ProductVendorList',{attrs:{"type":"vendor","product-id":item.id,"disabled":_vm.disabled,"default-item":{ productId: item.id }}})],1),(!_vm.disabled && _vm.hasAssetModule)?_c('ProductDepreciationDrawer',{attrs:{"product":item},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(item.displayName)+": "+_vm._s(_vm.$tc('configure'))+" "+_vm._s(_vm.$tc('depreciation'))+" ")]},proxy:true}],null,true)},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"ml-2 cursor-pointer text-primary",attrs:{"name":"cog"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$tc('configure'))+" "+_vm._s(_vm.$tc('depreciation'))+" ")])],1):_vm._e(),(!_vm.disabled)?_c('MTooltip',{class:{ invisible: !item.canEdit },scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"ml-2 cursor-pointer text-primary",attrs:{"name":"pencil"},on:{"click":edit}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")]):_vm._e(),(!_vm.disabled)?_c('FlotoDeleteBtn',{staticClass:"mx-2",class:{ invisible: !item.canDelete },attrs:{"message":_vm.$t('confirm_delete_item', { item: _vm.$tc('product') })},on:{"confirm":remove}}):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }