import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import {
  transformProductVendor,
  transformProductVendorForServer,
} from '@data/product-vendor'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

// vendor catalog

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.displayName) {
    quals.push(
      buildRelationalQualificationStructure(
        'displayName',
        'contains',
        qualificationFactors.displayName,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.enabled) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        false,
        'boolean',
        'db'
      )
    )
  }
  if (qualificationFactors.productId) {
    quals.push(
      buildRelationalQualificationStructure(
        'productId',
        'equal',
        qualificationFactors.productId,
        'long',
        'db'
      )
    )
  }

  if (qualificationFactors.vendorId) {
    quals.push(
      buildRelationalQualificationStructure(
        'vendorId',
        'equal',
        qualificationFactors.vendorId,
        'long',
        'db'
      )
    )
  }
  if ((qualificationFactors.excludeItemsByProductId || []).length > 0) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        qualificationFactors.excludeItemsByProductId,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.currencyId) {
    quals.push(
      buildRelationalQualificationStructure(
        'currencyId',
        'equal',
        qualificationFactors.currencyId,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getProductVendorsApi(filter, limit, offset) {
  return api
    .post(
      `/productvendor/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.enabled ||
          filter.productId ||
          filter.vendorId ||
          (filter.excludeItemsByProductId || []).length > 0
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformProductVendor),
        total: data.totalCount,
      }
    })
}

export function createProductVendorApi(data, type) {
  return api
    .post(`/productvendor`, transformProductVendorForServer(data), {
      message: __rootT('linked_successfully', {
        resource: __rootTc(type),
      }),
    })
    .then(transformProductVendor)
}

export function updateProductVendorApi(data, type) {
  return api
    .patch(`/productvendor/${data.id}`, transformProductVendorForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc(type),
      }),
    })
    .then(transformProductVendor)
}

export function deleteProductVendorApi(id, type) {
  return api.delete(`/productvendor/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc(type),
    }),
  })
}

export function getLinkedProductsFromVendorIdApi(
  vendorId,
  currencyId,
  filter,
  limit,
  offset
) {
  return api
    .post(
      `/productvendor/${vendorId}/${currencyId}/search/byqual`,
      {
        qualDetails:
          filter.displayName ||
          filter.enabled ||
          filter.productId ||
          filter.currencyId ||
          filter.vendorId ||
          (filter.excludeItemsByProductId || []).length > 0
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformProductVendor),
        total: data.totalCount,
      }
    })
}
