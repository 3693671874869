import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const cloudDatabseComponentFieldMap = () => ({
  cloud_database_component: {
    title: __mtc('cloud_database_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'state',
        name: __mtc('state'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'allocatedStorage',
        name: __mtc('db_storage'),
        inputType: 'bytes',
        unit: 'mb',
      },
      {
        key: generateId(),
        paramName: 'allocatedStorageType',
        name: __mtc('storage_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'availabilityZone',
        name: __mtc('availability_zone'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'region',
        name: __mtc('region'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'dbInstanceArn',
        name: __mtc('db_instance_arn'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'dbInstanceClass',
        name: __mtc('db_instance_class'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'dbResourceId',
        name: __mtc('db_resource_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'dbEngine',
        name: __mtc('db_engine'),
        inputType: 'text',
      },

      {
        key: generateId(),
        paramName: 'dbEngineVersion',
        name: __mtc('db_engine_version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'dbInstanceCreationTime',
        name: __mtc('db_instance_created_time'),
        inputType: 'dateTime',
      },
      {
        key: generateId(),
        paramName: 'dbLatestRestorableTime',
        name: __mtc('db_latest_restorable_time'),
        inputType: 'dateTime',
      },
      {
        key: generateId(),
        paramName: 'dbLicenseModel',
        name: __mtc('db_license_model'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'preferredBackupWindow',
        name: __mtc('preferred_backup_window'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'preferredMaintenanceWindow',
        name: __mtc('preferred_maintenance_window'),
        inputType: 'text',
      },
    ],
  },
})
