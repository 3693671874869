import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const cloudInstanceDetailsComponentFieldMap = () => ({
  cloud_instance_details_component: {
    title: __mtc('cloud_instance_details_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'amiId',
        name: __mtc('ami_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'amiName',
        name: __mtc('ami_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'amiLocation',
        name: __mtc('ami_location'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'amiLaunchIndex',
        name: __mtc('ami_launch_index'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'stateTransitionReason',
        name: __mtc('state_transition_reason'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'stateTransitionMessage',
        name: __mtc('state_transition_message'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'state',
        name: __mtc('state'),
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'owner',
        name: __mtc('owner'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'platform',
        name: __mtc('platform'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'platformDetails',
        name: __mtc('platform_details'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'resourceName',
        name: __mtc('resource_name'),
        inputType: 'text',
      },
    ],
  },
})
