import Constants from '@constants'

export const customFieldAttributesModuleName = (moduleName = 'asset') => {
  return moduleName === Constants.CMDB ? Constants.CMDB : Constants.ASSET
}

export const customFieldAttributesTypePrefix = (moduleName = 'asset') => {
  return moduleName === Constants.CMDB ? 'ci' : 'asset'
}

export const customFieldsForAllAssetType = (fields, moduleName = 'asset') => {
  const typePrefix = customFieldAttributesTypePrefix(moduleName)
  const modulesFields =
    (fields || []).filter(
      (f) => f.attributes.model === customFieldAttributesModuleName(moduleName)
    ) || []
  return modulesFields.filter(
    (f) =>
      !f.attributes[`${typePrefix}TypeId`] ||
      f.attributes[`${typePrefix}TypeId`] === 'all'
  )
}

export const customFieldForAssetType = (
  moduleName = 'asset',
  fields,
  assetTypeIds = [],
  propertyName,
  componentName
) => {
  const modulesFields =
    (fields || []).filter(
      (f) => f.attributes.model === customFieldAttributesModuleName(moduleName)
    ) || []
  const typePrefix = customFieldAttributesTypePrefix(moduleName)
  let assetTypeFields = []
  assetTypeIds.forEach((id) => {
    const c = modulesFields.filter(
      (f) => f.attributes[`${typePrefix}TypeId`] === String(id)
    )
    assetTypeFields = assetTypeFields.concat(c)
  })
  if (propertyName) {
    assetTypeFields = assetTypeFields.filter(
      (f) => f.attributes[`${typePrefix}PropertyType`] === propertyName
    )
  } else if (componentName) {
    assetTypeFields = assetTypeFields.filter(
      (f) => f.attributes[`${typePrefix}ComponentType`] === componentName
    )
  } else {
    assetTypeFields = assetTypeFields.filter(
      (f) =>
        !f.attributes[`${typePrefix}PropertyType`] &&
        !f.attributes[`${typePrefix}ComponentType`]
    )
  }
  return assetTypeFields
}
