var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[(_vm.disabled)?_c('FlotoFormItem',_vm._g(_vm._b({attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},'FlotoFormItem',_vm.attrs,false),_vm.listeners),[_vm._v(" "+_vm._s(_vm.currentValue && _vm.currentValue.length > 0 ? _vm.currentValue.join(', ') : '---')+" ")]):_c('FlotoFormItem',{attrs:{"label":_vm.field.title,"rules":_vm.validationsRules}},[_c('MCheckboxGroup',_vm._g(_vm._b({class:{
        flex: _vm.field.attributes.displayOptionVertically,
        'flex-col': _vm.field.attributes.displayOptionVertically,
        'text-ellipsis min-w-0 flex': true,
        'flex-wrap': !_vm.field.attributes.displayOptionVertically,
      },attrs:{"name":_vm.field.name,"disabled":_vm.disabled || _vm.mode === 'builder' || _vm.isDisable,"read-only":_vm.readOnly},on:{"change":_vm.handleChange},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'MCheckboxGroup',_vm.attrs,false),_vm.listeners),_vm._l((_vm.checkboxOptions),function(option){return _c('MCheckbox',{key:option,staticClass:"ml-4",class:{
          'vertical-checkbox': Boolean(
            _vm.field.attributes.displayOptionVertically
          ),
          'text-ellipsis flex': true,
        },attrs:{"value":option}},[_c('MRow',{attrs:{"size":6}},[_c('span',{staticClass:"text-ellipsis mx-4",attrs:{"title":option}},[_vm._v(" "+_vm._s(option)+" ")])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }