import modules from '@modules'
import store from '@state/store'
import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import config from './config'

const routePrefix = config.routePrefix
const moduleName = config.name
const routeNamePrefix = config.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      public: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (!store.getters['auth/loggedIn'] && !routeTo.query.mid) {
          // Redirect to the home page instead
          next(modules.getModuleRoute('auth', 'login'))
        } else {
          // Continue to the login page
          next()
        }
      },
    },
    children: [
      {
        path: ``,
        name: `${routeNamePrefix}`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "dashboard" */ './views/dashboard')
          ),
      },
      {
        path: `all`,
        name: `${routeNamePrefix}.list`,
        redirect: '/dashboard',
      },
      {
        path: `edit/:id`,
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "dashboard" */ './views/edit')
          ),
      },
      {
        path: `managedashboard`,
        name: `${routeNamePrefix}.managedashboard`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "dashboard" */ './views/manage-dashboard'
            )
          ),
      },
      {
        path: 'announcements',
        name: `${routeNamePrefix}.announcements`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "dashboard" */ './views/all-announcements'
            )
          ),
      },
      {
        path: 'view/:type/:id',
        name: `${routeNamePrefix}.view-kpi-widget`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "dashboard" */ './views/kpi-widget-viewer'
            )
          ),
      },
      {
        path: 'view-shortcut/:id',
        name: `${routeNamePrefix}.view-shortcut`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "dashboard" */ './views/shortcut-viewer'
            )
          ),
      },
    ],
  },
]
