<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem :label="field.title" :rules="validationsRules">
      <FlotoReadMore v-if="disabled" :height="20 * 3">
        <FlotoRichEditor
          v-model="currentValue"
          v-bind="{
            ...attrs,
            ...(controlPreview ? { key: guid } : { key: renderCount }),
          }"
          :rows="8"
          :disabled="disabled"
          :affix-toolbar="false"
          v-on="listeners"
        />
      </FlotoReadMore>
      <FlotoRichEditor
        v-else
        v-model="currentValue"
        v-bind="{
          ...attrs,
          ...(controlPreview ? { key: guid } : { key: renderCount }),
        }"
        :rows="8"
        :read-only="controlPreview"
        :disabled="controlPreview"
        :affix-toolbar="false"
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import Bus from '@utils/emitter'
import FormField from '../../mixins/form-field'
import { generateId } from '@utils/id'
export default {
  name: 'RichTextAreaField',
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  data() {
    return {
      guid: 1,
      renderCount: 1,
    }
  },
  computed: {
    controlPreview() {
      return (
        this.mode === 'builder' ||
        this.isDisable ||
        this.field.attributes.systemManagedField
      )
    },
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
      }
      return rules
    },
    attrs() {
      const { widthClass, allowDecimal, allowTime, ...attrs } =
        this.field.attributes
      return { ...attrs, ...this.$attrs }
    },
  },
  watch: {
    currentValue() {
      this.guid = generateId()
    },
  },
  created() {
    const reRenderRichTextArea = (fieldId) => {
      if (`${this.field.id}` === `${fieldId}`) {
        this.renderCount++
      }
    }
    Bus.$on('app:form:richtextarea:rerender', reRenderRichTextArea)
  },
}
</script>
