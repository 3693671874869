<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'BusinessHoursModule',
  page() {
    return {
      title: `${this.$t('business')} ${this.$tc('hours', 2)}`,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        vm.myAllowedModules.indexOf('admin.organization.business_hours') === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm.myAllowedModules.indexOf('admin') === -1) {
        return vm.$router.replace({ name: '404' })
      }
      return true
    })
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.organization') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
}
</script>
