<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="disabled"
      :label="field.title"
      :rules="validationsRules"
    >
      <CustomAsSystemPicker
        v-if="field.fieldResolutionType === 'system_field'"
        text-only
        :as-input="false"
        :value="currentValue"
        :module-name="field.moduleName || moduleName"
        placeholder="---"
        :field-reference-type="field.fieldReferenceType"
      />
      <span v-else>
        {{ getDependentFieldValue() || '---' }}
      </span>
    </FlotoFormItem>
    <FlotoFormItem v-else :label="field.title" :rules="validationsRules">
      <component
        :is="
          field.fieldResolutionType === 'system_field' &&
          field.fieldReferenceType
            ? 'CustomAsSystemPicker'
            : 'TreePicker'
        "
        v-model="currentValue"
        :module-name="field.moduleName || moduleName"
        as-input
        searchable
        :disabled="disabled || mode === 'builder' || isDisable"
        :read-only="readOnly"
        :class="{
          block: true,
          'w-full': field.attributes.widthClass !== 'w-full',
          'w-1/2': field.attributes.widthClass === 'w-full',
        }"
        :only-leaf-node-selectable="
          field.attributes.allowOnlyToSelectDependentLeafNode
        "
        :placeholder="field.attributes.placeholder"
        :options="internalOptions"
        :hidden-options-keys="hiddenOptionsKeys"
        :visible-options-keys="visibleOptionsKeys"
        allow-clear
        :full-path="true"
        :field-reference-type="field.fieldReferenceType"
        v-bind="attrs"
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import TreePicker from '@components/tree-picker/tree-picker'
import { flattenRecursive } from '@data/recursive'
import FormField from '../../mixins/form-field'
import CustomAsSystemPicker from './custom-as-system.vue'

export default {
  name: 'Dependent',
  components: { TreePicker, CustomAsSystemPicker },
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
        nonzero: this.isRequired || false,
      }
      return rules
    },
    internalOptions() {
      let options = this.field.tree || []
      if (this.attrs['additional-options']) {
        options = (this.attrs['additional-options'] || []).concat(options)
      }
      return options
    },
  },
  created() {
    if (
      this.currentValue &&
      this.field.fieldResolutionType === 'user_defined'
    ) {
      const flattenedTree = flattenRecursive(this.field.tree)
      const selected = flattenedTree.find((f) => f.id === this.currentValue)
      if (!selected) {
        this.$emit('update-value', 0)
      }
    }
  },
  methods: {
    getDependentFieldValue() {
      const flattenedTree = flattenRecursive(this.field.tree)
      return (flattenedTree.find((o) => o.id === this.currentValue) || {}).name
    },
  },
}
</script>
