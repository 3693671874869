import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const cloudSecurityDetailsComponentFieldMap = () => ({
  cloud_security_details_component: {
    title: __mtc('cloud_security_details_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'ownerId',
        name: __mtc('owner_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'securityGroup',
        name: __mtc('security_group_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'iamRole',
        name: __mtc('iam_role'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'launchTime',
        name: __mtc('launch_time'),
        inputType: 'dateTime',
      },
    ],
  },
})
