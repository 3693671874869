import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const snmpInterfaceComponentFieldMap = () => ({
  snmp_interface_component: {
    canCreate: true,
    title: __mtc('snmp_interface_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'macAddress',
        name: __mtc('mac_address'),
        inputType: 'text',
        rules: {
          mac_address: true,
        },
      },

      {
        key: generateId(),
        paramName: 'speed',
        name: __mtc('speed'),
        inputType: 'hertz',
      },
      {
        key: generateId(),
        paramName: 'adminStatus',
        name: __mtc('admin_status'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'operationalState',
        name: `${__mtc('operational')} ${__mtc('state')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'interfaceType',
        name: `${__mtc('type')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
