<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { BusinessServiceMethods } from '@state/modules/business-service'
export default {
  name: 'BusinessServiceModule',
  page() {
    return {
      title: `${this.$t('business')} ${this.$tc('service')}`,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        vm.myAllowedModules.indexOf('admin.organization.business_service') ===
        -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (vm.myAllowedModules.indexOf('admin') === -1) {
        return vm.$router.replace({ name: '404' })
      }
      return true
    })
  },
  beforeRouteLeave(to, from, next) {
    if (from.name && from.name.indexOf('business-service') >= 0) {
      this.fetchBusinessServices()
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.organization') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  methods: {
    ...BusinessServiceMethods,
  },
}
</script>
