<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem
      v-if="mode === 'builder'"
      :label="field.title"
      type="input"
      v-bind="attrs"
      v-on="listeners"
    >
      <FlotoRichEditor
        :value="field.defaultValue"
        v-bind="{
          ...attrs,
        }"
        :rows="8"
        disabled
        :affix-toolbar="false"
        v-on="listeners"
      />
    </FlotoFormItem>
    <FlotoRichEditor
      v-else
      :value="field.defaultValue"
      v-bind="{
        ...attrs,
      }"
      :rows="8"
      disabled
      :affix-toolbar="false"
      v-on="listeners"
    />
  </FieldContainer>
</template>

<script>
import FormField from '../../mixins/form-field'

export default {
  name: 'Label',
  mixins: [FormField],
}
</script>
