<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { RiskTypeMethods } from '@state/modules/risk-type'
import { ReasonTypeMethods } from '@state/modules/reason-type'
import { ChangeTypeMethods } from '@state/modules/change-type'
// import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'ChangeManagementModule',
  page() {
    return {
      title: `${this.$t('change')} ${this.$t('management')}`,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (from.name && from.name.indexOf('risk-type') >= 0) {
      this.fetchRiskTypes()
    }
    if (from.name && from.name.indexOf('reason-type') >= 0) {
      this.fetchReasonTypes()
    }
    if (from.name && from.name.indexOf('change-type') >= 0) {
      this.fetchChangeTypes()
    }
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    // ...LicenseComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.change_management') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  // TO DO: Will do in pre-7.5
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (vm.availableModulesInLicense.indexOf(vm.$constants.CHANGE) === -1) {
  //       return vm.$router.replace({ name: 'upgrade-plan' })
  //     }
  //     if (vm.myAllowedModules.indexOf(vm.$constants.CHANGE) === -1) {
  //       return vm.$router.replace({ name: '404' })
  //     }
  //   })
  // },
  methods: {
    ...RiskTypeMethods,
    ...ReasonTypeMethods,
    ...ChangeTypeMethods,
  },
}
</script>
