<template>
  <FlotoDrawerForm
    ref="form"
    :open="open"
    :mask-closable="false"
    @submit="handleSubmit"
    @cancel="open = false"
  >
    <template v-slot:header>
      {{
        type === 'break'
          ? `${$tc('add')} ${$tc('break')} ${$tc('reason')}`
          : type === 'call'
          ? `${$tc('call_notes_and_disposal')}`
          : type === 'block'
          ? `${$tc('add')} ${$tc('block')} ${$tc('reason')}`
          : `${$tc('add')} ${$tc('remark')}`
      }}
    </template>
    <template v-if="type !== 'block'">
      <MRow>
        <MCol :size="6">
          <FlotoFormItem
            v-if="type === 'call'"
            rules="required"
            :label="`${$tc('disposal')} ${$tc('reason')}`"
          >
            <FlotoDropdownPicker
              v-model="data.disposalReason"
              as-input
              :options="reasonOptions"
            />
          </FlotoFormItem>
          <FlotoFormItem
            v-if="type === 'break'"
            rules="required"
            :label="`${$tc('break')} ${$tc('reason')}`"
          >
            <FlotoDropdownPicker
              v-model="data.breakReason"
              as-input
              :options="reasonOptions"
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="12">
          <FlotoFormItem
            v-if="type === 'call' || type === 'pickup'"
            v-model="data.remark"
            :rules="type === 'pickup' ? 'required' : ''"
            type="textarea"
            :label="$tc('remark')"
          />
        </MCol>
      </MRow>
    </template>
    <template v-if="type === 'block'">
      <MRow>
        <MCol :size="12">
          <FlotoFormItem
            v-model="data.blockReason"
            type="textarea"
            :label="`${$tc('block')} ${$tc('reason')}`"
          />
        </MCol>
      </MRow>
    </template>
    <template v-slot:actions="{ hide, submit }">
      <MButton class="mr-2" :loading="processing" @click="submit">
        {{ $t('submit') }}
      </MButton>
      <MButton variant="default" @click="hide">
        {{ $t('cancel') }}
      </MButton>
    </template>
  </FlotoDrawerForm>
</template>

<script>
import {
  getBreakReasonsApi,
  getDisposalReasonsApi,
} from '../../contactOps-manager-api'
export default {
  name: 'ReasonTaker',
  props: {
    createFn: { type: Function, required: true },
    type: {
      type: String,
      default() {
        return 'break'
      },
    },
  },
  data() {
    return {
      open: false,
      data: {},
      processing: false,
      reasonOptions: [],
    }
  },
  methods: {
    getReasonOptions() {
      if (this.type === 'block' || this.type === 'pickup') {
        return
      }
      let apiCall = getBreakReasonsApi
      if (this.type === 'call') {
        apiCall = getDisposalReasonsApi
      }
      return apiCall().then((data) => {
        this.reasonOptions = data.objectList.map((i) => ({ text: i, key: i }))
      })
    },
    openForm() {
      this.getReasonOptions()
      setTimeout(() => {
        this.data = {}
        this.processing = false
        this.open = true
      }, 300)
    },
    closeForm() {
      this.open = false
    },
    handleSubmit() {
      if (this.processing) {
        return
      }
      // @TODO remove interanal processing
      this.processing = true
      this.createFn(this.data).finally(() => {
        setTimeout(() => {
          this.processing = false
        }, 400)
      })
      // this.$emit('submit', this.data)
    },
  },
}
</script>
