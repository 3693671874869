import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const mobileApplicationComponentFieldMap = () => ({
  mobile_application_component: {
    canCreate: true,
    title: __mtc('mobile_application_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'appName',
        name: `${__mtc('app_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'version',
        name: __mtc('version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'appidentifier',
        name: __mtc('app_identifier'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'shortversion',
        name: __mtc('short_version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'installedBy',
        name: __mtc('installed_by'),
        inputType: 'text',
      },
    ],
  },
})
