<template>
  <FlotoCrudContainer
    as-table
    prepend-new-item
    :fetch-fn="getProductCatalog"
    :delete-fn="deleteProductCatalog"
    :create-fn="addProductCatalog"
    :update-fn="updateProductCatalog"
    :columns="tableColumns"
  >
    <template v-slot:add-controls="{ create, refreshList }">
      <MRow class="mb-4" :gutter="0">
        <MCol :size="6">
          <MInput
            v-model="searchTerm"
            class="search-box"
            :placeholder="$t('search_by_name')"
            @enterKey="refreshList"
          >
            <template v-slot:prefix>
              <MIcon name="search" />
            </template>
          </MInput>
        </MCol>
        <MCol v-if="!disabled" :size="6" class="flex justify-end">
          <FlotoLink
            variant="default"
            as-button
            :to="
              $modules.getModuleRoute('import-csv-batch', '', {
                params: {
                  moduleName: $constants.PRODUCT,
                },
              })
            "
          >
            {{ $tc('import') }} {{ $tc('product') }}
          </FlotoLink>
          <FlotoLink
            class="mx-2"
            variant="default"
            as-button
            :to="
              $modules.getModuleRoute('import-csv-batch', '', {
                params: {
                  moduleName: $constants.PRODUCTVENDOR,
                },
              })
            "
          >
            {{ $tc('import') }} {{ $tc('price') }} {{ $tc('list') }}
          </FlotoLink>
          <MButton variant="primary" @click="create">
            {{ $t('add') }} {{ $tc('product') }}
          </MButton>
        </MCol>
      </MRow>
    </template>
    <template v-slot:form-header="{ item }">
      {{ $t(item.id ? 'edit' : 'add') }} {{ $tc('product') }}
    </template>
    <template v-slot:form-items="{ item: productCatalog }">
      <ProductCatalogForm :form-data="productCatalog" />
    </template>
    <template v-slot:form-actions="{ submit, cancel, item, processing }">
      <MButton
        id="update-add-btn"
        class="mx-1"
        :loading="processing"
        @click="submit"
      >
        {{ $t(item.id ? 'update' : 'add') }}
      </MButton>
      <MButton id="cancel-btn" variant="default" @click="cancel">
        {{ $t('cancel') }}
      </MButton>
    </template>
    <template v-slot:name="{ item, edit }">
      <td class="resource-link">
        <a :disabled="disabled" @click="edit"> {{ item.name }}</a>
      </td>
    </template>
    <template v-slot:displayName="{ item, edit }">
      <td class="resource-link">
        <a :disabled="disabled" @click="edit"> {{ item.displayName }}</a>
      </td>
    </template>
    <template v-slot:productType="{ item }">
      <td class="text-ellipsis">
        <ProductTypePicker
          text-only
          placeholder="---"
          :value="item.productType"
          disabled
        />
      </td>
    </template>
    <template v-slot:manufacturer="{ item }">
      <td class="text-ellipsis">
        <ManufacturerPicker
          :key="item.manufacturer"
          text-only
          placeholder="---"
          :value="item.manufacturer"
          disabled
        />
      </td>
    </template>
    <template v-slot:description="{ item }">
      <td class="text-ellipsis">
        {{ item.description }}
      </td>
    </template>
    <template v-slot:actions="{ item, remove, edit }">
      <td>
        <div class="flex justify-left items-center">
          <FlotoDrawer
            :open="currentSelectedItemId === item.id"
            class="flex"
            @hide="currentSelectedItemId = null"
          >
            <template v-slot:title>
              {{ item.displayName }}: {{ $t(disabled ? 'view' : 'manage') }}
              {{ $tc('vendor', 2) }}
            </template>
            <template v-slot:trigger>
              <MButton
                id="manage-vendor-btn"
                variant="default"
                size="small"
                @click="currentSelectedItemId = item.id"
              >
                {{ $t(disabled ? 'view' : 'manage') }} {{ $tc('vendor', 2) }}
              </MButton>
            </template>
            <ProductVendorList
              type="vendor"
              :product-id="item.id"
              :disabled="disabled"
              :default-item="{ productId: item.id }"
            />
            <template v-slot:actions="{ hide }">
              <MButton id="done-btn" variant="default" @click="hide">
                {{ $t('done') }}
              </MButton>
            </template>
          </FlotoDrawer>
          <ProductDepreciationDrawer
            v-if="!disabled && hasAssetModule"
            :product="item"
          >
            <MTooltip>
              <template v-slot:trigger>
                <MIcon name="cog" class="ml-2 cursor-pointer text-primary" />
              </template>
              {{ $tc('configure') }} {{ $tc('depreciation') }}
            </MTooltip>
            <template v-slot:header>
              {{ item.displayName }}: {{ $tc('configure') }}
              {{ $tc('depreciation') }}
            </template>
          </ProductDepreciationDrawer>

          <MTooltip v-if="!disabled" :class="{ invisible: !item.canEdit }">
            <template v-slot:trigger>
              <MIcon
                name="pencil"
                class="ml-2 cursor-pointer text-primary"
                @click="edit"
              />
            </template>
            {{ $t('edit') }}
          </MTooltip>

          <FlotoDeleteBtn
            v-if="!disabled"
            class="mx-2"
            :class="{ invisible: !item.canDelete }"
            :message="$t('confirm_delete_item', { item: $tc('product') })"
            @confirm="remove"
          />
        </div>
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import ProductCatalogForm from '../components/product-catalog-form'
import { LicenseComputed } from '@state/modules/license'
import ProductVendorList from '../components/manage-product-vendor/product-vendor-list'
import {
  getProductCatalogsApi,
  deleteProductCatalogApi,
  updateProductCatalogApi,
  createProductCatalogApi,
} from '../api/product-catalog-api'
import ProductTypePicker from '@components/data-picker/product-type-picker'
import ManufacturerPicker from '@components/data-picker/manufacturer-picker'
import ProductDepreciationDrawer from '../components/product-depreciation/product-depreciation-drawer'
export default {
  name: 'ProductCatalogList',
  components: {
    ProductCatalogForm,
    ProductVendorList,
    ProductTypePicker,
    ManufacturerPicker,
    ProductDepreciationDrawer,
  },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      currentSelectedItemId: null,
      searchTerm: '',
      tableColumns: [
        { name: `${this.$t('id')}`, key: 'name' },
        { name: `${this.$t('name')}`, key: 'displayName' },
        { name: `${this.$tc('product_type')}`, key: 'productType' },
        { name: `${this.$tc('manufacturer')}`, key: 'manufacturer' },
        {
          name: `${this.$t('description')}`,
          key: 'description',
        },
        {
          name: `${this.$tc('action', 2)}`,
          key: 'actions',
          width: '280px',
        },
      ],
    }
  },
  computed: {
    ...LicenseComputed,
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
  },
  methods: {
    getProductCatalog(limit, offset) {
      return getProductCatalogsApi(
        { displayName: this.searchTerm },
        limit,
        offset
      )
    },
    deleteProductCatalog({ id }) {
      return deleteProductCatalogApi(id)
    },
    updateProductCatalog(data) {
      return updateProductCatalogApi(data)
    },
    addProductCatalog(data) {
      const requestData = { ...data, enabled: true }
      return createProductCatalogApi(requestData)
    },
  },
}
</script>
