import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}/:assetType?`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: 'create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "request" */ './views/create')
          ),
      },
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "asset" */ './views/list')),
        meta: {},
      },
      {
        path: 'software-meter',
        name: `${routeNamePrefix}.software-meter`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "request" */ './views/software-meter')
          ),
      },
      {
        path: ':id',
        name: `${routeNamePrefix}.view`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "request" */ './views/view')
          ),
      },
    ],
  },
]
