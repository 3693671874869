var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldContainer',_vm._g(_vm._b({attrs:{"field":_vm.field,"section-id":_vm.sectionId}},'FieldContainer',_vm.containerAttrs,false),_vm.containerListeners),[(_vm.disabled)?_c('FlotoFormItem',_vm._g(_vm._b({attrs:{"label":_vm.label,"type":_vm.field.inputType}},'FlotoFormItem',_vm.attrs,false),_vm.listeners),[_vm._v(" "+_vm._s(_vm.currentValue || '---')+" ")]):_c('FlotoFormItem',{attrs:{"label":_vm.label,"rules":_vm.validationsRules}},[_c('ExtendedDropdown',_vm._g(_vm._b({class:{
        block: true,
        'w-full': _vm.field.attributes.widthClass !== 'w-full',
        'w-1/2': _vm.field.attributes.widthClass === 'w-full',
      },attrs:{"searchable":"","disabled":_vm.disabled ||
        _vm.mode === 'builder' ||
        _vm.field.attributes.systemManagedField ||
        _vm.isDisable ||
        !_vm.dynamicFieldOptions,"read-only":_vm.readOnly,"options":_vm.options,"as-input":""},model:{value:(_vm.currentValue),callback:function ($$v) {_vm.currentValue=$$v},expression:"currentValue"}},'ExtendedDropdown',_vm.attrs,false),_vm.listeners))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }