import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: { moduleName, breadcrumbKey: 'automation' },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "event-notifications" */ './views/event-notifications'
            )
          ),
        meta: {
          breadcrumbKey: 'event_notifications',
        },
      },
      {
        path: ':moduleName/:id',
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "automation" */ '../email-notification/views/edit'
            )
          ),
      },
      {
        path: ':moduleName/:id',
        name: `${routeNamePrefix}.sms-edit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "automation" */ './components/sms-notification-edit'
            )
          ),
      },
    ],
  },
]
