var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 flex-col p-2"},[_c('MRow',[_c('MCol',{attrs:{"size":12}},[_c('MRow',[_c('MCol',{attrs:{"size":6}},[_c('FlotoFormItem',{attrs:{"id":"depreciation-method-picker","label":((_vm.$tc('depreciation')) + " " + (_vm.$tc('method'))),"rules":"required|none_value"}},[_c('FlotoDropdownPicker',{attrs:{"disabled":_vm.disabled ||
                Boolean(
                  _vm.moduleName !== _vm.$constants.PRODUCT &&
                    _vm.value.depreciationDerivation === 'product_level' &&
                    _vm.value.id
                ),"searchable":"","as-input":"","options":_vm.depreciationMethodOptions},model:{value:(_vm.value.depreciationMethod),callback:function ($$v) {_vm.$set(_vm.value, "depreciationMethod", $$v)},expression:"value.depreciationMethod"}})],1)],1)],1),(_vm.value.depreciationMethod !== 'none' && _vm.value.depreciationMethod)?[(
            ['straight_line', 'declining_balance'].indexOf(
              _vm.value.depreciationMethod
            ) >= 0
          )?_c('MRow',[_c('MCol',[_c('FlotoFormItem',{attrs:{"id":"depreciation-type-radio","label":((_vm.$tc('depreciation')) + " " + (_vm.$tc('type'))),"rules":"none_value"}},[_c('MRadioGroup',{attrs:{"disabled":_vm.disabled,"searchable":"","as-input":"","options":_vm.depreciationTypeOptions},model:{value:(_vm.value.depreciationType),callback:function ($$v) {_vm.$set(_vm.value, "depreciationType", $$v)},expression:"value.depreciationType"}})],1)],1)],1):_vm._e(),_c('MRow',[_c('MCol',{attrs:{"size":6}},[_c('FlotoFormItem',{attrs:{"id":"usefull-life-input","label":_vm.useFullLifeLabel,"rules":"numeric|min_value:1|max_value:9999"}},[_c('div',{staticClass:"flex w-full"},[_c('MInputGroup',{staticClass:"input-group-container mb-2",attrs:{"compact":""}},[_c('div',{staticClass:"flex-1"},[_c('MInputNumber',{attrs:{"precision":0,"disabled":_vm.disabled},model:{value:(_vm.value.usefulLife),callback:function ($$v) {_vm.$set(_vm.value, "usefulLife", $$v)},expression:"value.usefulLife"}})],1),_c('div',{staticClass:"px-2 bg-neutral-lightest flex items-center justify-center text-neutral border-solid border-neutral-lighter border-l-0 border-t border-b border-r rounded rounded-tl-none rounded-bl-none",staticStyle:{"display":"inline-flex"}},[(
                        _vm.value.depreciationMethod === 'sum_of_the_years_digit'
                      )?[_vm._v(_vm._s(_vm.$tc('years')))]:[_vm._v(" "+_vm._s(_vm.value.depreciationType === 'useful_life' || _vm.value.depreciationMethod === 'double_declining_balance' ? _vm.$tc('months') : '%')+" ")]],2)])],1)])],1),_c('MCol',{attrs:{"size":6}},[_c('FlotoFormItem',{attrs:{"id":"salvage-amount-input","label":((_vm.$tc('salvage')) + " " + (_vm.$tc('value'))),"rules":"min_value:1|max_value"}},[_c('MInputGroup',{staticClass:"input-group-container mb-2",attrs:{"compact":""}},[_c('MInputNumber',{attrs:{"disabled":_vm.disabled,"precision":2},model:{value:(_vm.value.salvageAmount),callback:function ($$v) {_vm.$set(_vm.value, "salvageAmount", $$v)},expression:"value.salvageAmount"}}),_c('CurrencyPicker',{attrs:{"allow-clear":false},model:{value:(_vm.value.currencyId),callback:function ($$v) {_vm.$set(_vm.value, "currencyId", $$v)},expression:"value.currencyId"}})],1)],1)],1)],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }