import api from '@api'
import {
  transformComputerForList,
  transformComputerForServer,
  transformComputerForDetail,
} from '@data/computer'
import { transformPatchCommandForList } from '@data/patch'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@/src/utils/get-module-translator'
import { errorToast } from '@motadata/ui'
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const getQualifications = (filters) => {
  const qualDetails = []
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('excludedIds' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.excludedIds,
        'long',
        'db'
      )
    )
  }
  if ('os_platform' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'os_platform',
        'in',
        filters.os_platform,
        'enum',
        'variable'
      )
    )
  }
  if ('platform' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'platform',
        'in',
        [filters.platform],
        'listEnum',
        'db'
      )
    )
  }
  if ('platform_in' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'platform',
        'in',
        filters.platform_in,
        'listEnum',
        'db'
      )
    )
  }
  if ('autoDeploymentPlatform' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'platform',
        'in',
        [filters.autoDeploymentPlatform],
        'listEnum',
        'db'
      )
    )
  }
  return qualDetails
}

export function searchComputerApi(
  filters,
  searchCriterias,
  limit,
  offset,
  sortedColumn,
  additionalParams = {}
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/computer/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : searchCriterias !== null
          ? searchCriterias
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
          ...additionalParams,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformComputerForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function getApi(id) {
  return api.get(`/computer/${id}`).then(transformComputerForDetail)
}

export function updateApi({ id, ...change }, type, action) {
  return api
    .patch(`/computer/${id}`, transformComputerForServer(change), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('computer', 1),
      }),
    })
    .then(transformComputerForDetail)
}

export function searchComputerBasedOnPatchApi(
  patchId,
  patchType,
  filters,
  searchCriterias,
  limit,
  offset,
  sortedColumn
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/computer/${patchId}/${patchType}/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformComputerForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function searchComputerCommnadApi(
  refId,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/computer/${refId}/installations`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformPatchCommandForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function searchComputerForDeployRequestApi(
  filters,
  searchCriterias,
  limit,
  offset,
  installationtype,
  additionalSearchData = {}
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/computer/${installationtype}/search/byqual`,
      {
        qual: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
        ...additionalSearchData,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformComputerForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function computerTargetPreviewApi(
  searchCriterias,
  limit,
  offset,
  additionalSearchData = {}
) {
  const qualDetails = [...((searchCriterias || {}).quals || [])]
  return api
    .post(
      `/computer/target/agent`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
        ...additionalSearchData,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformComputerForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function scanNowApi(computerId) {
  return api
    .post(
      `/computer/scanpatch/${computerId}`,
      {},
      {
        notify: false,
        message: __rootT('scan_submitted_successfully'),
      }
    )
    .catch((e) => {
      errorToast(((e.response || {}).data || {}).userMessage)
    })
}

export function getScanPatchStatusApi(id) {
  return api.get(`/computer/scanpatchstatus/${id}`)
}

export function searchComputerForComputerGroupApi(
  filters,
  searchCriterias,
  limit,
  offset,
  sortedColumn,
  additionalParams = {}
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/computergroup/computer/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : searchCriterias !== null
          ? searchCriterias
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
          ...additionalParams,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformComputerForList)
          : [],
        total: data.totalCount,
      }
    })
}
export function searchComputerForPackageDeployRequestApi(
  filters,
  searchCriterias,
  limit,
  offset,
  additionalSearchData = {}
) {
  const qualDetails = [
    ...((searchCriterias || {}).quals || []),
    ...getQualifications(filters),
  ]
  return api
    .post(
      `/computer/package/search/byqual`,
      {
        qual: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
        ...additionalSearchData,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformComputerForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function getComputerSystemRebootActionApi(systemaction, id) {
  return api.post(
    `/computer/${systemaction}/${id}`,
    {},
    {
      notify: false,
      message: __rootT('system_action_submitted_successfully'),
    }
  )
}
