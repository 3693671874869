import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      nonLinked: true,
      titleFn(route, translator, pluralTranslator) {
        return `${pluralTranslator('support_channel')}`
      },
    },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "contactops" */ './views/telephony')
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${translator('telephony')}`
          },
        },
      },
      {
        path: 'manage',
        name: `${routeNamePrefix}.manage`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "contactops" */ './views/manage-co-ops')
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${translator('contactops')} ${translator('management')}`
          },
        },
      },
    ],
  },
]
