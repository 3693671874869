var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{attrs:{"as-table":"","prepend-new-item":"","fetch-fn":_vm.getProductVendor,"delete-fn":_vm.deleteProductVendor,"create-fn":_vm.addProductVendor,"update-fn":_vm.updateProductVendor,"columns":_vm.tableColumns,"default-item":_vm.defaultItem,"form-width":"40%"},scopedSlots:_vm._u([(!_vm.disabled)?{key:"add-controls",fn:function(ref){
var create = ref.create;
return [_c('MRow',{staticClass:"mb-4",attrs:{"gutter":0}},[_c('MCol',{staticClass:"text-right",attrs:{"size":12}},[_c('MButton',{attrs:{"variant":"primary"},on:{"click":create}},[_vm._v(" "+_vm._s(_vm.$t('link'))+" "+_vm._s(_vm.$tc(_vm.type))+" ")])],1)],1)]}}:null,{key:"form-header",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.id ? 'edit' : 'link'))+" "+_vm._s(_vm.$tc(_vm.type))+" ")]}},{key:"form-items",fn:function(ref){
var vendorCatalog = ref.item;
return [_c('ProductVendorForm',{attrs:{"form-data":vendorCatalog,"type":_vm.type}})]}},{key:"form-actions",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
var item = ref.item;
var processing = ref.processing;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"update-add-btn","loading":processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t(item.id ? 'update' : 'add'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}},{key:"productId",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
return [_c('td',{staticClass:"resource-link"},[_c('a',{attrs:{"disabled":_vm.disabled},on:{"click":edit}},[_c('ProductPicker',{attrs:{"disabled":"","value":item.productId,"text-only":"","placeholder":"---"}})],1)])]}},{key:"vendorId",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
return [_c('td',{staticClass:"resource-link"},[_c('a',{attrs:{"disabled":_vm.disabled},on:{"click":edit}},[_c('VendorPicker',{attrs:{"disabled":"","value":item.vendorId,"text-only":"","placeholder":"---"}})],1)])]}},{key:"price",fn:function(ref){
var item = ref.item;
return [(item.price)?_c('td',[_vm._v(" "+_vm._s(item.price)+" ")]):_vm._e()]}},{key:"taxRate",fn:function(ref){
var item = ref.item;
return [(item.taxRate)?_c('td',[_vm._v(" "+_vm._s(item.taxRate)+" ")]):_vm._e()]}},{key:"maintenanceVendorId",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
return [_c('td',{staticClass:"resource-link"},[_c('a',{attrs:{"disabled":_vm.disabled},on:{"click":edit}},[_c('VendorPicker',{attrs:{"disabled":"","value":item.maintenanceVendorId,"text-only":"","placeholder":"---"}})],1)])]}},{key:"email",fn:function(ref){
var item = ref.item;
var edit = ref.edit;
return [_c('td',{staticClass:"resource-link"},[_c('a',{attrs:{"disabled":_vm.disabled},on:{"click":edit}},[_vm._v(" "+_vm._s(item.email))])])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-ellipsis"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
var remove = ref.remove;
var edit = ref.edit;
return [_c('td',{staticClass:"text-ellipsis"},[_c('MTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('MIcon',{staticClass:"cursor-pointer text-primary",class:{ invisible: !item.canEdit },attrs:{"name":"pencil"},on:{"click":edit}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$tc('edit'))+" ")]),_c('FlotoDeleteBtn',{staticClass:"mx-2",class:{ invisible: !item.canDelete },attrs:{"message":_vm.$t('confirm_delete_item', { item: _vm.$tc(_vm.type) })},on:{"confirm":remove}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }