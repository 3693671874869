<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
export default {
  name: 'EmailNotificationModule',
  page() {
    return {
      title: `${this.$tc('email')} ${this.$tc('notification', 2)}`,
    }
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
}
</script>
