import Constants from '@constants'
import { findValueObject } from '@data/recursive'
export const assetTypeRest = {
  HARDWARE: 'HardwareAssetRest',
  SOFTWARE: 'SoftwareAssetRest',
  NONIT: 'NonITAssetRest',
  CONSUMABLE: 'ConsumableAssetRest',
  CMDB: 'HardwareAssetRest',
}
// map base on systemName
export const assetTypeModuleMap = {
  Hardware: assetTypeRest.HARDWARE,
  Software: assetTypeRest.SOFTWARE,
  'Non IT Assets': assetTypeRest.NONIT,
  'Consumable Assets': assetTypeRest.CONSUMABLE,
}

export const getAssetTypeRestFromModule = {
  [Constants.ASSET_HARDWARE]: 'HardwareAssetRest',
  [Constants.ASSET_SOFTWARE]: 'SoftwareAssetRest',
  [Constants.ASSET_NON_IT]: 'NonITAssetRest',
  [Constants.ASSET_CONSUMABLE]: 'ConsumableAssetRest',
}

export const getAssetTypeSystemName = {
  // from module name
  [Constants.ASSET_HARDWARE]: 'Hardware',
  [Constants.ASSET_SOFTWARE]: 'Software',
  [Constants.ASSET_NON_IT]: 'Non IT Assets',
  [Constants.ASSET_CONSUMABLE]: 'Consumable Assets',
  // from asset types
  hardware: 'Hardware',
  software: 'Software',
  non_it_asset: 'Non IT Assets',
  consumable_asset: 'Consumable Assets',
  computer: 'Computer',
  snmp_devices: 'SNMP Devices',
  os: 'OS',
  windows_desktop: 'Windows Desktop',
  windows_laptop: 'Windows Laptop',
  windows_server: 'Windows Server',
  linux_dasktop: 'Linux Desktop',
  linux_laptop: 'Linux Laptop',
  mac_dasktop: 'Mac Desktop',
  mac_laptop: 'Mac Laptop',
  server: 'Server',
  desktops: 'Desktops',
  laptops: 'Laptops',
}

export const isAvailableRDP = (assetTypes, assetTypeId) => {
  const item = findValueObject(assetTypes, assetTypeId) || {}
  if (item.systemName) {
    const sMap = getAssetTypeSystemName
    return (
      [
        sMap.windows_desktop,
        sMap.windows_laptop,
        sMap.windows_server,
        sMap.linux_dasktop,
        sMap.linux_laptop,
        sMap.mac_dasktop,
        sMap.mac_laptop,
      ].indexOf(item.systemName) >= 0
    )
  }
  return false
}
