import {
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __tc = getRootPluaralTranslator()
const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const osComponentFieldMap = () => ({
  os_component: {
    canCreate: true,
    maxCreation: 1,
    title: __mtc('os_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: __mtc('manufacturer'),
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'osName',
        name: `${__mtc('os')} ${__tc('name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'osVersion',
        name: `${__mtc('os')} ${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'architecture',
        name: `${__mtc('os')} ${__mtc('architecture')}`,
        inputType: 'dropdown',
        contextOptionKey: 'osArchitectureOptions',
      },
      {
        key: generateId(),
        paramName: 'productKey',
        name: `${__mtc('product_key')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'licenceKey',
        name: `${__mtc('licence_key')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'activationStatus',
        name: `${__mtc('activation_status')}`,
        inputType: 'dropdown',
        contextOptionKey: 'activationStatusOptions',
      },
      {
        key: generateId(),
        paramName: 'installedDate',
        name: `${__mtc('installed')} ${__mtc('date')}`,
        inputType: 'dateTime',
      },
      {
        key: generateId(),
        paramName: 'displayVersion',
        name: `${__mtc('display_version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'buildNo',
        name: `${__mtc('build_no')}`,
        inputType: 'text',
      },
    ],
  },
})
