<template>
  <FlotoFormItem :label="label">
    <div class="flex flex-col flex-1">
      <MultipleFormItems
        v-model="companyWisePrefixMapping"
        button-size="small"
        :show-icon="false"
        :disabled="disabled"
        :add-btn-text="`${label}`"
        :max-items="
          allowedCompanyIds.length
            ? allowedCompanyIds.length
            : companyOptions.length
        "
      >
        <template v-slot:add-item="{ add }">
          <div class="mt-2">
            <MButton variant="primary" rounded @click="add">
              <MIcon name="plus-circle" class="mx-1" />
              {{ `${label}` }}
            </MButton>
          </div>
        </template>
        <template v-slot="{ item, remove, update }">
          <CompanyWiseCategoryMappingItem
            :item="item"
            :remove="remove"
            :update="update"
            :available-keywords="availableCompanyCategoryKeywords"
            :module-name="moduleName"
            :available-company-options="getUpdatedCompanyOptions"
            v-bind="$attrs"
          />
        </template>
      </MultipleFormItems>
    </div>
  </FlotoFormItem>
</template>

<script>
import MultipleFormItems from '@components/multiple-form-item'
import CompanyWiseCategoryMappingItem from './company-wise-category-item'
import { getCompaniesApi } from '@modules/organization/company-api'
export default {
  name: 'CompanyWiseCategoryMapping',
  components: { MultipleFormItems, CompanyWiseCategoryMappingItem },
  model: {
    event: 'change',
  },
  props: {
    moduleName: { type: String, default: undefined },
    value: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: { type: Boolean, default: false },
    availableCompanyCategoryKeywords: { type: Object, default: undefined },
    allowedCompanyIds: {
      type: Array,
      default() {
        return []
      },
    },
    label: {
      type: String,
      default() {
        return this.$tc('add_prefix')
      },
    },
  },
  data() {
    return {
      companyOptions: [],
    }
  },
  computed: {
    companyWisePrefixMapping: {
      get() {
        return this.value || []
      },
      set(companyWisePrefixMapping) {
        return this.$emit('change', companyWisePrefixMapping || [])
      },
    },
    getUpdatedCompanyOptions() {
      let keys = []
      if (this.value.length) {
        this.value.forEach((v) => {
          if (v.companyId) {
            keys.push(v.companyId)
          }
        })
      }
      if (
        (this.allowedCompanyIds || []).length > 0 &&
        this.moduleName === this.$constants.SERVICE_CATALOG
      ) {
        let allowedCompanyIds = []
        allowedCompanyIds = this.companyOptions.filter(
          (f) => this.allowedCompanyIds.indexOf(f.key) >= 0
        )
        const options = allowedCompanyIds.map((f) => ({
          ...f,
          disabled: keys.indexOf(f.key) >= 0,
        }))
        return options
      } else if (this.moduleName === this.$constants.REQUEST) {
        const options = this.companyOptions.map((f) => ({
          ...f,
          disabled: keys.indexOf(f.key) >= 0,
        }))
        return options
      } else {
        return this.companyOptions
      }
    },
  },
  created() {
    this.getCompanyOptions()
  },
  methods: {
    getCompanyOptions() {
      getCompaniesApi({}, undefined, undefined, {
        archived: true,
      }).then(({ items }) => {
        this.companyOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.displayName,
        }))
      })
    },
  },
}
</script>
