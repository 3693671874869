import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const userComponentFieldMap = () => ({
  user_component: {
    canCreate: true,
    title: __mtc('user_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'userName',
        name: __mtc('user_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'accountType',
        name: __mtc('account_type'),
        inputType: 'dropdown',
        contextOptionKey: 'userAccountTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'domain',
        name: __mtc('domain'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'disabled',
        name: __mtc('disabled'),
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'sId',
        name: __mtc('s_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'description',
        name: __mtc('description'),
        inputType: 'textarea',
      },
    ],
  },
})
