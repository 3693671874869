<template>
  <MRow :gutter="0">
    <MCol :lg="12" :sm="12" auto-size>
      <MRow>
        <MCol :size="6">
          <FlotoFormItem
            v-model="formData.requesterName"
            :placeholder="`${$tc('requester')} ${$tc('name')}`"
            :label="`${$tc('requester')} ${$tc('name')}`"
            :disabled="fromCall"
            rules="short_text"
          />
        </MCol>
        <MCol :size="6">
          <FlotoFormItem
            v-model="formData.requesterNumber"
            :placeholder="`${$tc('requester')} ${$tc('number')}`"
            :label="`${$tc('requester')} ${$tc('number')}`"
            rules="required|max:16|numeric"
            :disabled="fromCall"
          />
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="6">
          <FlotoFormItem
            :placeholder="`${$tc('callback')} ${$tc('time')}`"
            :label="`${$tc('callback')} ${$tc('time')}`"
            rules="required|nonzero"
          >
            <FlotoDatePicker
              v-model="formData.callbackTime"
              :disabled="Boolean(formData.id)"
              :show-time="true"
              :min-date="
                Moment()
                  .add(5, 'minitues')
                  .valueOf()
              "
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow>
        <MCol :size="12">
          <FlotoFormItem
            v-model="formData.notes"
            :placeholder="$tc('note', 2)"
            :label="$tc('note', 2)"
            :rows="2"
            type="textarea"
          />
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import Moment from 'moment'
export default {
  name: 'ReminderForm',
  props: {
    formData: { type: Object, required: true },
    fromCall: { type: Boolean, default: false },
  },
  data() {
    this.Moment = Moment
    return {}
  },
}
</script>
