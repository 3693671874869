<template>
  <a @click="trigger">
    <slot>
      <span> {{ $t('configure') }} {{ $tc('depreciation') }} </span>
    </slot>
    <FlotoDrawerForm
      ref="drawer"
      :open="isDrawerOpen"
      @cancel="cancelAction"
      @submit="handleSubmit"
    >
      <template v-slot:header>
        <slot name="header">
          {{ $t('configure') }} {{ $tc('depreciation') }}
        </slot>
      </template>
      <DepreciationForm
        v-model="formData"
        :reset-form="resetForm"
        :module-name="$constants.PRODUCT"
      />
      <template v-slot:actions="{ submit, hide, processing }">
        <MButton
          id="update-btn"
          class="mx-1"
          :loading="processing"
          @click="submit"
        >
          {{ $t('update') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawerForm>
  </a>
</template>
<script>
import CloneDeep from 'lodash/cloneDeep'
import {
  getProductDepreciationApi,
  createDepreciationApi,
  updateDepreciationApi,
} from '../../api/depreciation-api'

import DepreciationForm from './depreciation-form'
export default {
  name: 'ProductDepreciationDrawer',
  components: { DepreciationForm },
  props: { product: { type: Object, required: true } },
  data() {
    this.defaultData = {
      depreciationDerivation: 'product_level',
      depreciationMethod: '',
      depreciationType: 'useful_life',
    }
    return {
      isDrawerOpen: false,
      formData: {},
      testProcessing: false,
    }
  },
  methods: {
    trigger() {
      setTimeout(() => {
        this.isDrawerOpen = true
        this.getProductDepreciation()
      }, 400)
      this.formData = CloneDeep(this.defaultData)
    },
    cancelAction() {
      this.isDrawerOpen = false
    },
    handleSubmit() {
      if (this.formData.id) {
        return updateDepreciationApi(this.formData).then(() => {
          this.isDrawerOpen = false
          this.$emit('refresh')
        })
      }
      return createDepreciationApi('product', this.product.id, {
        ...this.formData,
      }).then(() => {
        this.isDrawerOpen = false
        this.$emit('refresh')
      })
    },
    resetForm(data) {
      this.formData = {
        ...data,
      }
    },
    getProductDepreciation() {
      getProductDepreciationApi(this.product.id).then((data) => {
        this.formData = { ...this.defaultData, ...data }
      })
    },
  },
}
</script>
