<template>
  <MDropdown v-if="imageString" ref="dropdownMenu" :disabled="disabled">
    <template v-slot:trigger>
      <img
        v-if="imageString"
        class="mr-4 cursor-pointer"
        :alt="$tc('barcode')"
        :src="imageString"
      />
    </template>
    <template v-slot:menu>
      <MMenu class="action-menu">
        <MMenuItem>
          <BarcodePrintAction
            :barcodes="[barcode]"
            :module-name="moduleName"
            :resource-id="getAssetId(asset)"
            @trigger="hide"
          />
        </MMenuItem>
        <!-- <MMenuItem @click="handlePrintBarcode">
          <MIcon name="print" class="action-menu-icon" />
          <span class="ml-1 action-menu-text">
            {{ $tc('print') }} {{ $tc('barcode') }}
          </span>
        </MMenuItem> -->
        <MMenuItem @click="copyBarcode">
          <MIcon name="copy" class="action-menu-icon" />
          <span class="ml-1 action-menu-text">
            {{ $tc('copy_upc_code') }}
          </span>
        </MMenuItem>
        <MMenuItem v-if="!hideUpdatableOptions">
          <ChangeBarcode
            :module-name="moduleName"
            :asset="asset"
            @submit="updateApi($event)"
            @refresh="$emit('refresh')"
          />
        </MMenuItem>
        <MMenuItem v-if="!hideUpdatableOptions">
          <DeleteBarcode
            :handle-delete-barcode="handleDeleteBarcode"
            @trigger="hide"
          />
        </MMenuItem>
      </MMenu>
    </template>
  </MDropdown>
</template>

<script>
import { generate, printBarcode } from '@utils/barcode'
import { copyToClipboard } from '@utils/copy-to-clipboard'
import BarcodePrintAction from '@components/barcode/barcode-print-action'
import DeleteBarcode from './delete-barcode'
import ChangeBarcode from '../action-menu-items/change-barcode'

const defaultOptions = {
  height: 25,
  width: 1,
  displayValue: true,
  margin: 0,
  marginBottom: 0,
  fontSize: 12,
}

export default {
  name: 'BarcodeRenderer',
  components: { DeleteBarcode, ChangeBarcode, BarcodePrintAction },
  props: {
    barcode: { type: String, required: true },
    moduleName: { type: String, required: true },
    asset: { type: Object, required: true },
    updateApi: { type: Function, default: undefined },
    hideUpdatableOptions: { type: Boolean, default: false },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      imageString: undefined,
    }
  },
  watch: {
    barcode: {
      immediate: true,
      handler: 'generateImageString',
    },
  },
  methods: {
    generateImageString() {
      this.imageString = generate(this.barcode, {
        ...defaultOptions,
        ...(this.options || {}),
      })
    },
    copyBarcode() {
      copyToClipboard(this.barcode)
      this.hide()
    },
    handleDeleteBarcode() {
      return this.updateApi({ barCode: '' }).then(() => this.$emit('refresh'))
    },
    handlePrintBarcode() {
      printBarcode([this.barcode])
      this.hide()
    },
    hide() {
      this.$refs.dropdownMenu.hide()
    },
    getAssetId(asset) {
      return String(asset.id).indexOf(':') >= 0
        ? +String(asset.id).split(':')[0]
        : asset.id
    },
  },
}
</script>
