<template>
  <FieldContainer
    :field="field"
    :section-id="sectionId"
    v-bind="containerAttrs"
    v-on="containerListeners"
  >
    <FlotoFormItem v-if="disabled" :label="label" :rules="validationsRules">
      <CustomAsSystemPicker
        v-if="field.fieldResolutionType === 'system_field'"
        :disabled="disabled"
        :as-input="false"
        :value="currentValue"
        :module-name="field.moduleName || moduleName"
        placeholder="---"
        multiple
        as-tag
        :field-reference-type="field.fieldReferenceType"
      />
      <span v-else>
        {{ currentValue.length <= 0 ? '---' : (currentValue || []).join(', ') }}
      </span>
    </FlotoFormItem>
    <FlotoFormItem v-else :label="label" :rules="validationsRules">
      <component
        :is="
          field.fieldResolutionType === 'system_field' &&
          field.fieldReferenceType
            ? 'CustomAsSystemPicker'
            : 'ExtendedDropdown'
        "
        v-model="currentValue"
        :module-name="field.moduleName || moduleName"
        :disabled="
          disabled ||
          mode === 'builder' ||
          field.attributes.systemManagedField ||
          isDisable
        "
        searchable
        :read-only="readOnly"
        :class="{
          block: true,
          'w-full': field.attributes.widthClass !== 'w-full',
          'w-1/2': field.attributes.widthClass === 'w-full',
        }"
        :hidden-options-keys="hiddenOptionsKeys"
        :visible-options-keys="visibleOptionsKeys"
        :filter-data="filterData"
        :mandatory-selection="field.fieldResolutionType === 'system_field'"
        v-bind="{
          ...attrs,
          options: currentFieldOptions,
        }"
        as-input
        multiple
        :field-reference-type="field.fieldReferenceType"
        :is-larg-data-set="!field.fieldReferenceType"
        v-on="listeners"
      />
    </FlotoFormItem>
  </FieldContainer>
</template>

<script>
import _baseDropdownPicker from '@components/_base-dropdown-picker.vue'
import FormField from '../../mixins/form-field'
import CustomAsSystemPicker from './custom-as-system.vue'

const ExtendedDropdown = {
  name: 'ExtendedDropdown',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = ''
    return {}
  },
}

export default {
  name: 'MultiSelectDropdown',
  components: { ExtendedDropdown, CustomAsSystemPicker },
  mixins: [FormField],
  props: {
    moduleName: { type: String, default: undefined },
  },
  computed: {
    currentFieldOptions() {
      if ((this.fieldOptions || []).length) {
        return this.fieldOptions
      }
      return this.field.fieldResolutionType === 'user_defined'
        ? this.field.options.map((o) => ({ key: o, value: o, text: o }))
        : undefined
    },
    isRequired() {
      return (
        (!this.isPortalLogin && this.field.required) ||
        (this.isPortalLogin && this.field.requesterRequired) ||
        this.isMandatory
      )
    },
    validationsRules() {
      const rules = {
        required: this.isRequired || false,
        nonzero: this.isRequired || false,
      }
      return rules
    },
    label() {
      return this.moduleName === this.$constants.USER_SURVEY ||
        this.moduleName === this.$constants.USER_SURVEY_SCHEDULE
        ? this.field.question
        : this.field.title
    },
  },
}
</script>
