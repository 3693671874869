import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const mobileDeviceProperyFieldMap = () => ({
  mobile_device_property_group: {
    title: __mtc('mobile_device_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'os',
        name: `${__mtc('os')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'osVersion',
        name: `${__mtc('os')} ${__mtc('version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'memory',
        name: `${__mtc('memory')}`,
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'imeiNumber',
        name: `${__mtc('imei')} ${__mtc('number')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'enrollmentSpecificId',
        name: `${__mtc('enrollment_specific_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'deviceName',
        name: `${__mtc('device_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'modelNo',
        name: `${__mtc('model_no')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'buildVersion',
        name: `${__mtc('build_version')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'productName',
        name: `${__mtc('product_name')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'deviceType',
        name: `${__mtc('device_type')}`,
        inputType: 'dropdown',
        contextOptionKey: 'mobileDeviceTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'deviceCapacity',
        name: `${__mtc('ram_capacity')}`,
        inputType: 'bytes',
      },
    ],
  },
})
