<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'

export default {
  name: 'ContractManagementModule',
  page() {
    return {
      title: `${this.$tc('contract')} ${this.$t('management')}`,
    }
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    ...LicenseComputed,
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.contract_management') === -1) {
      return this.$router.replace({ name: '404' })
    }
    if (
      this.availableModulesInLicense.indexOf(this.$constants.CONTRACT) === -1
    ) {
      return this.$router.replace({ name: 'upgrade-plan' })
    }
    this.hasPermission = true
  },
}
</script>
