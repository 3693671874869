<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
import { CustomRulesMethods } from '@state/modules/custom-rules'
import { PreferenceComputed } from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'CustomRulesModule',
  page() {
    return {
      title: this.$t('custom_rules'),
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const allowedForms = [
        Constants.REQUEST,
        Constants.SERVICE_CATALOG,
        Constants.PROBLEM,
        Constants.CHANGE,
        Constants.RELEASE,
        Constants.ASSET,
        Constants.KNOWLEDGE,
        Constants.PURCHASE,
        Constants.PROJECT,
      ]
      const availableModulesInLicense = [
        ...vm.availableModulesInLicense,
        Constants.KNOWLEDGE,
        ...(vm.availableModulesInLicense.indexOf(Constants.CMDB) >= 0
          ? [Constants.ASSET]
          : []),
      ]
      if (
        !(
          to.query.type &&
          allowedForms.indexOf(to.query.type) !== -1 &&
          availableModulesInLicense.indexOf(to.query.type) !== -1
        )
      ) {
        return vm.$router.replace({ name: '404' })
      }
      next()
    })
  },
  beforeRouteLeave(to, from, next) {
    if (
      from.params.moduleName &&
      this[`${from.params.moduleName}FetchNoteRules`]
    ) {
      this[`${from.params.moduleName}FetchNoteRules`]()
    }
    next()
  },
  computed: {
    ...PreferenceComputed,
    ...LicenseComputed,
  },
  methods: {
    ...CustomRulesMethods,
  },
}
</script>
