import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildFlatQualificationStructure,
  buildNameFilterQuery,
} from '@/src/data/qualification'
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export const transformBusinessServiceForServer = (s) => {
  return {
    id: s.id,
    name: s.name,
    description: s.description,
  }
}

const transformBusinessService = (s) => {
  return {
    id: s.id,
    key: s.id,
    value: s.id,
    name: s.name,
    label: s.name,
    description: s.description,
    archived: s.removed,
  }
}

export function getBusinessServiceApi(
  { filter, selectedName, quals },
  limit,
  offset,
  params = {}
) {
  return api
    .post(
      `/businessservice/search/byqual`,
      filter || selectedName || quals
        ? {
            qualDetails: buildFlatQualificationStructure(
              [
                filter && buildNameFilterQuery(filter),
                selectedName && buildNameFilterQuery(selectedName),
                ...(quals || []),
              ].filter(Boolean)
            ),
          }
        : {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformBusinessService),
        total: data.totalCount,
      }
    })
}

export function createBusinessServiceApi(service) {
  return api.post(
    `/businessservice`,
    transformBusinessServiceForServer(service),
    {
      message: __rootT('created_successfully', {
        resource: `${__rootTc('business')} ${__rootTc('service')}`,
      }),
    }
  )
}

export function updateBusinessServiceApi(data) {
  return api.patch(
    `/businessservice/${data.id}`,
    transformBusinessServiceForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('business')} ${__rootTc('service')}`,
      }),
    }
  )
}

export function deleteBusinessServiceApi(id) {
  return api.delete(`/businessservice/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('business')} ${__rootTc('service')}`,
    }),
  })
}
