import api from '@api'
import {
  transformBusinessHourForServer,
  transformBusinessHourForList,
  transformBusinessHour,
} from '@data/business-hour'

import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModulePluaralTranslator,
} from '@utils/get-module-translator'
import config from './config'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()
const __moduleTc = getModulePluaralTranslator(config.translationKey)

export function getBusinessHoursApi(limit, offset) {
  return api
    .get('/bizHour', {
      notify: false,
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformBusinessHourForList),
        total: data.totalCount,
      }
    })
}

export function getBusinessHourApi(id) {
  return api.get(`/bizHour/${id}`).then(transformBusinessHour)
}

export function createBusinessHourApi(data) {
  return api.post('/bizHour', transformBusinessHourForServer(data), {
    message: __rootT('added_successfully', {
      resource: `${__rootT('business')} ${__rootTc('hours')}`,
    }),
  })
}

export function updateBusinessHourApi(data) {
  return api.patch(
    `/bizHour/${data.id}`,
    transformBusinessHourForServer(data),
    {
      message: __rootT('updated_successfully', {
        resource: `${__rootT('business')} ${__rootTc('hours')}`,
      }),
    }
  )
}

export function deleteBusinessHourApi(id) {
  return api.delete(`/bizHour/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __moduleTc('business_hours'),
    }),
  })
}

export function toggleEnableApi(id, data) {
  return api
    .patch(`/bizHour/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __moduleTc('business_hours'),
      }),
    })
    .then((data) => transformBusinessHourForList(data))
}
