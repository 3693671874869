import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: { moduleName },
    children: [
      {
        path: '',
        name: routeNamePrefix,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "contactops-manager" */ './views/list')
          ),
        meta: {},
      },
      {
        path: 'recent-calls',
        name: `${routeNamePrefix}.recent-calls`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "contactops-manager" */ './components/call-history/call-history-list'
            )
          ),
        meta: {},
      },
      {
        path: 'abandoned-calls',
        name: `${routeNamePrefix}.abandoned-calls`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "contactops-manager" */ './components/abandoned-call/abandoned-call-list'
            )
          ),
        meta: {},
      },
      // {
      //   path: 'callback-reminder',
      //   name: `${routeNamePrefix}.callback-reminder`,
      //   component: () =>
      //     lazyLoadView(
      //       import(
      //         /* webpackChunkName: "contactops-manager" */ './components/call-reminder/call-reminder-list'
      //       )
      //     ),
      //   meta: {},
      // },
    ],
  },
]
