import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'
const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const vmInstancePropertyGroupFieldMap = () => ({
  vm_instance_property_group: {
    title: __mtc('vm_instance_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'instanceId',
        name: `${__mtc('instance_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'instanceType',
        name: `${__mtc('instance_type')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'privateIp',
        name: `${__mtc('private_ip')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'publicIp',
        name: `${__mtc('public_ip')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'privateDns',
        name: `${__mtc('private_dns')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'publicDns',
        name: `${__mtc('public_dns')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'vpcId',
        name: `${__mtc('vpc_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'subnetId',
        name: `${__mtc('subnet_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'hostType',
        name: `${__mtc('host_type')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'instanceState',
        name: `${__mtc('instance_state')}`,
        inputType: 'dropdown',
        contextOptionKey: 'deviceStatusOptions',
      },
    ],
  },
})
