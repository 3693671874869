import { getRootTranslator } from '@utils/get-module-translator'
const __rootT = getRootTranslator()
export const memberRoleOptions = [
  { text: 'Agent', key: 'agent', value: 'agent' },
  { text: 'Supervisor', key: 'supervisor', value: 'supervisor' },
]

export const extensionTypeOptions = () => [
  { text: __rootT('self'), key: 'self', value: 'self' },
  { text: __rootT('external'), key: 'external', value: 'external' },
]
