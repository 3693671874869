import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const mobileDeviceSystemComponentFieldMap = () => ({
  mobile_device_system_component: {
    canCreate: true,
    maxCreation: 1,
    title: __mtc('mobile_device_system_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'deviceName',
        name: __mtc('device_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'deviceModel',
        name: __mtc('device_model'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'imeiNumber',
        name: `${__mtc('imei')} ${__mtc('number')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'enrollmentSpecificId',
        name: `${__mtc('enrollment_specific_id')}`,
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'productId',
        name: __mtc('product'),
        inputType: 'product',
      },
      {
        key: generateId(),
        paramName: 'uuid',
        name: __mtc('uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'manufacturerId',
        name: `${__mtc('manufacturer')}`,
        inputType: 'manufacturer',
      },
      {
        key: generateId(),
        paramName: 'deviceType',
        name: __mtc('device_type'),
        inputType: 'dropdown',
        contextOptionKey: 'mobileDeviceTypeOptions',
      },
      {
        key: generateId(),
        paramName: 'betteryLevel',
        name: `${__mtc('battery_level')}`,
        inputType: 'number',
        rules: {
          max_value: 100,
        },
      },
    ],
  },
})
