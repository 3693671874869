<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import Constants from '@constants'
import { PreferenceComputed } from '@state/modules/preference'
import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'FormRulesModule',
  page() {
    return {
      title: this.$tc('form_rule'),
    }
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    ...LicenseComputed,
  },
  created() {
    if (this.$route.name.indexOf('custom-script') >= 0) {
      this.hasPermission = true
      return
    }
    let allowedForms = [Constants.REQUEST, Constants.CHANGE, Constants.TASK]
    if (
      this.myAllowedModules.indexOf('admin.request_management') === -1 ||
      this.availableModulesInLicense.indexOf(Constants.REQUEST) === -1
    ) {
      allowedForms = allowedForms.filter((i) => i !== Constants.REQUEST)
    }
    if (
      this.myAllowedModules.indexOf('admin.change_management') === -1 ||
      this.availableModulesInLicense.indexOf(Constants.CHANGE) === -1
    ) {
      allowedForms = allowedForms.filter((i) => i !== Constants.CHANGE)
    }
    if (allowedForms.indexOf(this.$route.params.moduleName) === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
}
</script>
