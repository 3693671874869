import SortBy from 'lodash/sortBy'
import api from '@api'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  transformAssetType,
  transformAssetTypeForServer,
} from '@data/asset-type'
import {
  buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function searchAssetTypesApi(
  moduleName,
  type,
  { name, selectedName, isPublic, quals },
  limit,
  offset,
  params = {}
) {
  return api
    .post(
      `/${moduleName}/${type}/search/byqual`,
      {
        ...(name || selectedName || quals
          ? {
              qualDetails: buildFlatQualificationStructure(
                [
                  name && buildNameFilterQuery(name),
                  selectedName && buildNameFilterQuery(selectedName),
                  ...(quals || []),
                ].filter(Boolean)
              ),
            }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: SortBy(data.objectList || [], 'objOrder').map(
          transformAssetType
        ),
        total: data.totalCount,
      }
    })
}

export function createAssetTypeApi(moduleName, type, data) {
  return api
    .post(`/${moduleName}/${type}`, transformAssetTypeForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc(type === 'assettype' ? 'asset_type' : 'ci_type'),
      }),
    })
    .then(transformAssetType)
}

export function createAssetTypesApi(moduleName, type, data) {
  return api
    .post(`/${moduleName}/${type}/bulk/create`, data, {
      message: __rootT('added_successfully', {
        resource: __rootTc(type === 'assettype' ? 'asset_type' : 'ci_type', 2),
      }),
    })
    .then((data) => data.map(transformAssetType))
}

export function removeAssetTypeApi(moduleName, type, data) {
  return api.delete(`/${moduleName}/${type}/${data.id}`, {
    message: __rootT('removed_successfully', {
      resource: __rootTc(type === 'assettype' ? 'asset_type' : 'ci_type'),
    }),
  })
}

export function updateAssetTypeApi(moduleName, type, data) {
  return api
    .patch(
      `/${moduleName}/${type}/${data.id}`,
      transformAssetTypeForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc(type === 'assettype' ? 'asset_type' : 'ci_type'),
        }),
      }
    )
    .then(transformAssetType)
}

export function updateAssetTypesApi(moduleName, type, data) {
  const patchData = {}
  data.map((c, index) => {
    patchData[c.id] = { name: c.name, objOrder: index + 1 }
  })
  return api.patch(`/${moduleName}/${type}/bulk/update`, patchData, {
    message: __rootT('updated_successfully', {
      resource: __rootTc(type === 'assettype' ? 'asset_type' : 'ci_type', 2),
    }),
  })
}

export function getAssetTypeApi(moduleName, type, id) {
  return api.get(`/${moduleName}/${type}/${id}`).then(transformAssetType)
}

export function getAssetTypeConfigInfoApi(moduleName, type, id) {
  return api.get(`/${moduleName}/${type}/${id}/configinfo`)
}
