import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const mobileDeviceExternalMemoryComponentFieldMap = () => ({
  mobile_device_external_memory_component: {
    canCreate: true,
    title: __mtc('mobile_device_external_memory_component'),
    fields: [
      {
        key: generateId(),
        paramName: 'totalCapacity',
        name: `${__mtc('total_capacity')}`,
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'availableCapacity',
        name: __mtc('available_capacity'),
        inputType: 'bytes',
      },
    ],
  },
})
