import api from '@api'

import {
  // getModuleTranslator,
  getRootPluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'

import {
  transformTelephony,
  transformTelephonyForServer,
  transformCoOpsMember,
  transformCoOpsMemberForServer,
  transformRecentCall,
} from '@data/contactops'
// import moduleConfig from './config'

// const __t = getModuleTranslator(moduleConfig.translationKey)
const __rootT = getRootTranslator()

const __rootTc = getRootPluaralTranslator()

export function getTelephonyApi() {
  return api.get(`/telephony/config`).then(transformTelephony)
}

export function updateTelephonyApi(data) {
  return api
    .patch(`/telephony/config`, transformTelephonyForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('telephony'),
      }),
    })
    .then(transformTelephony)
}

export function testConnectionTelephonyApi(data) {
  return api.post(`telephony/config/test`, transformTelephonyForServer(data))
}

export function getSkillsApi() {
  return api.get('/telephony/skills').then((data) => {
    return {
      items: data.objectList || [],
      total: data.totalCount,
    }
  })
}

export function getCoOpsMembersApi() {
  return api.get('/telephony/members').then((data) => {
    return {
      items: (data.objectList || []).map(transformCoOpsMember),
      total: data.totalCount,
    }
  })
}

export function createCoOpsMemberApi(data) {
  return api
    .post(`/telephony/member`, transformCoOpsMemberForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('member'),
      }),
    })
    .then(transformCoOpsMember)
}

export function updateCoOpsMemberApi(data) {
  return api
    .patch(
      `/telephony/member/${data.userId}`,
      transformCoOpsMemberForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('member'),
        }),
      }
    )
    .then(transformCoOpsMember)
}

export function deleteCoOpsMemberApi(id) {
  return api.delete(`/telephony/member/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('member'),
    }),
  })
}
export function getRecentCallApi(userId) {
  return api
    .get(`contactOps/requester/${userId}/recentcalls`, {
      notify: false,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformRecentCall),
        total: data.totalCount,
      }
    })
}
