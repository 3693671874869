<template>
  <div :class="{ visible: status }">
    <FlotoDropdownPicker
      v-if="disabled"
      disabled
      :options="statusOptions"
      :value="value"
    />
    <template v-else>
      <StartChannelBtn
        v-if="status === STATUSES.OFFLINE"
        class="ml-2"
        @updated-status="status = $event"
      />
      <MButton
        v-else-if="
          [STATUSES.BUSY, STATUSES.ON_CALL, STATUSES.WRAP_UP].indexOf(status) >=
          0
        "
        variant="transparent"
        :shadow="false"
      >
        <FlotoDot
          :bg="statusOptions.find((s) => s.key === status).color"
          class="mr-1"
        />
        {{ statusOptions.find((s) => s.key === status).text }}
      </MButton>
      <MDropdown
        v-else
        :value="status"
        :options="availableStatusOptions"
        @change="handleChange"
      >
        <template v-slot:trigger>
          <MButton variant="transparent" :shadow="false">
            <FlotoDot
              :bg="statusOptions.find((s) => s.key === status).color"
              class="mr-1"
            />
            {{ statusOptions.find((s) => s.key === status).text }}
          </MButton>
        </template>
        <template v-slot:menu-item="{ item, selectItem }">
          <a
            v-if="!item.disabled"
            href="javascript:;"
            @click.stop="selectItem(item)"
          >
            <FlotoDot :bg="item.color" class="mr-1" />
            {{ item.text }}
          </a>
          <span v-else />
        </template>
      </MDropdown>
      <ReasonTaker ref="reasonDrawer" :create-fn="handleReasonAdded" />
    </template>
  </div>
</template>

<script>
import { colors } from '@utils/color'
import Bus from '@utils/emitter'
import * as STATUSES from '@components/contactOps/helpers/channel-statuses'
import { authComputed } from '@state/modules/auth'
import ReasonTaker from './reason-taker/reason-taker'
import {
  startCoOpsChannelApi,
  getCoOpsChannelStatusApi,
  updateCoOpsChannelStatusApi,
} from '../contactOps-manager-api'
import StartChannelBtn from './start-channel-btn'
export default {
  name: 'AgentStatusPicker',
  components: { StartChannelBtn, ReasonTaker },
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: String, default: undefined },
  },
  data() {
    this.STATUSES = STATUSES
    return {
      status: null,
      pendingChanges: null,
    }
  },
  computed: {
    ...authComputed,
    automaticStatus() {
      return [STATUSES.BUSY, STATUSES.ON_CALL, STATUSES.WRAP_UP]
    },
    statusOptions() {
      return [
        {
          text: 'Not Available',
          key: STATUSES.NOT_AVAILABLE,
          status: STATUSES.NOT_AVAILABLE,
          color: colors['warning-light'],
          disabled:
            [
              ...this.automaticStatus,
              STATUSES.AVAILABLE,
              STATUSES.ON_BREAK,
            ].indexOf(this.status) === -1,
        },
        {
          text: 'Available',
          key: STATUSES.AVAILABLE,
          status: STATUSES.AVAILABLE,
          color: colors.success,
          disabled:
            [
              ...this.automaticStatus,
              STATUSES.NOT_AVAILABLE,
              STATUSES.ON_BREAK,
            ].indexOf(this.status) === -1,
        },
        {
          text: 'On Break',
          key: STATUSES.ON_BREAK,
          status: STATUSES.ON_BREAK,
          color: colors['warning-light'],
          disabled:
            [
              ...this.automaticStatus,
              STATUSES.NOT_AVAILABLE,
              STATUSES.AVAILABLE,
            ].indexOf(this.status) === -1,
        },
        {
          text: 'Offline',
          key: STATUSES.OFFLINE,
          status: STATUSES.OFFLINE,
          color: colors['neutral-light'],
          disabled:
            [
              ...this.automaticStatus,
              STATUSES.NOT_AVAILABLE,
              STATUSES.AVAILABLE,
              STATUSES.ON_BREAK,
            ].indexOf(this.status) === -1,
        },
        {
          text: 'Busy',
          key: STATUSES.BUSY,
          status: STATUSES.BUSY,
          color: colors.error,
          disabled: true,
        },
        {
          text: 'On Call',
          key: STATUSES.ON_CALL,
          status: STATUSES.ON_CALL,
          color: colors.error,
          disabled: true,
        },
        {
          text: 'Wrap-up',
          key: STATUSES.WRAP_UP,
          status: STATUSES.WRAP_UP,
          color: colors.error,
          disabled: true,
        },
      ]
    },
    availableStatusOptions() {
      return this.statusOptions.filter((s) => !s.disabled)
    },
  },
  watch: {
    status: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          Bus.$emit(
            'contactOps_channel_status_update',
            this.statusOptions.find((s) => s.key === newValue)
          )
        }
      },
    },
  },
  created() {
    this.getStatus()
    const handelContactOpsChannelStausUpdate = (payload) => {
      if (payload && payload.status) {
        this.status = payload.status
      }
    }
    Bus.$on(
      'contactOps_channel_status_update',
      handelContactOpsChannelStausUpdate
    )
    this.$once('hook:beforeDestroy', () => {
      Bus.$off(
        'contactOps_channel_status_update',
        handelContactOpsChannelStausUpdate
      )
    })
  },
  methods: {
    getStatus() {
      getCoOpsChannelStatusApi().then((data) => (this.status = data.status))
    },
    handleChange(event) {
      if (event.key === STATUSES.ON_BREAK) {
        this.pendingChanges = STATUSES.ON_BREAK
        this.$refs.reasonDrawer.openForm()
      } else {
        updateCoOpsChannelStatusApi(event.key).then((data) => {
          this.status = data
        })
      }
    },
    handleStartChannel() {
      const requestedData = this.contactOpsConfigContext.config
      startCoOpsChannelApi({
        extensionType: requestedData.extensionType,
        extensionNumber: requestedData.extensionNumber,
      }).then((data) => {
        this.status = data
      })
    },
    handleReasonAdded(data) {
      return updateCoOpsChannelStatusApi(this.pendingChanges, data).then(
        (status) => {
          this.status = status
          this.$refs.reasonDrawer.closeForm()
        }
      )
    },
  },
}
</script>
