<template>
  <a href="javascript:;" @click.stop="triggerRemoveAction">
    <MTooltip>
      <template v-slot:trigger>
        <MIcon name="times" />
      </template>
      {{ $t('delete') }}
    </MTooltip>

    <FlotoConfirmModal
      :open="showConfirmModal"
      prevent-auto-close-on-confirm
      :processing="processing"
      @hide="showConfirmModal = false"
      @confirm="handleConfirm"
    >
      <template v-slot:icon>
        <MIcon
          name="exclamation-triangle"
          class="w-full text-secondary-red"
          size="2x"
        />
      </template>
      <template v-slot:message>
        <b>
          <span class="text-secondary-red">
            {{ $t('confirm_remove_control_item') }}
          </span>
          <br />
          {{
            $t('confirm_delete_item', {
              resource: $tc('field'),
            })
          }}
        </b>
      </template>
      <template v-slot:actions="{ success, cancel }">
        <MButton id="ok-btn" @click.stop="success">
          {{ $t(okText) }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click.stop="cancel">
          {{ $t(cancelText) }}
        </MButton>
      </template>
    </FlotoConfirmModal>
  </a>
</template>

<script>
export default {
  name: 'ControlDeleteBtn',
  props: {
    okText: {
      type: String,
      default() {
        return this.$tc('yes')
      },
    },
    cancelText: {
      type: String,
      default() {
        return this.$tc('cancel')
      },
    },
  },
  data() {
    return {
      showConfirmModal: false,
      processing: false,
    }
  },
  methods: {
    triggerRemoveAction() {
      this.$emit('show')
      setTimeout(() => {
        this.showConfirmModal = true
        this.processing = true
      }, 400)
    },
    handleConfirm() {
      this.$emit('click')
      this.$nextTick(() => {
        this.showConfirmModal = false
        this.processing = false
      })
    },
    handleCancel() {
      this.showConfirmModal = false
      this.processing = false
      this.$emit('cancel')
    },
  },
}
</script>
