<template>
  <div class="flex">
    <MButton
      :outline="formValue === true ? false : true"
      variant="success"
      class="mr-4 inline-flex items-center"
      :name="field.name"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="$listeners"
      @click="(event) => handleClick(true, event)"
    >
      <MIcon :name="formValue ? 'check' : 'thumbs-up'" />
      {{ field.tLabel }}
    </MButton>
    <MButton
      :outline="
        typeof formValue === 'boolean' && formValue === false ? false : true
      "
      variant="error"
      class="mr-4 inline-flex items-center"
      :name="field.name"
      v-bind="$attrs"
      :disabled="disabled"
      v-on="$listeners"
      @click="(event) => handleClick(false, event)"
    >
      <MIcon
        :name="
          typeof formValue === 'boolean' && !formValue ? 'check' : 'thumbs-down'
        "
      />
      {{ field.fLabel }}
    </MButton>
  </div>
</template>

<script>
export default {
  name: 'BooleanDataPicker',
  model: {
    event: 'update-value',
  },
  props: {
    value: {
      type: Boolean,
      // eslint-disable-next-line
      default() {
        return undefined
      },
    },
    disabled: { type: Boolean, default: false },
    field: { type: Object, required: true },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('update-value', newValue)
      },
    },
  },
  methods: {
    handleClick(value) {
      this.formValue = value
    },
  },
}
</script>
